import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import useDefaultInjections from "./composables/appInjections";
import { createGtm } from '@gtm-support/vue-gtm';
import "bootstrap/dist/css/bootstrap.min.css";
import "@/css/app.scss";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import useHelpers from "./composables/useHelpers";
import { v4 as uuid } from "uuid";
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import { InjectionKeyAppInsights } from "./composables/injectKeys";
import { configureLocalStorage } from "./composables/useLocalStorage";
import { Vue } from "@liveswitch/sdk";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";

const options = {
    confirmButtonColor: 'rgb(140, 212, 245)',
    cancelButtonColor: 'rgb(140, 212, 245)',
};

Vue.reactive = reactive;

const app = createApp(App);
app.use(VueTelInput, {
    preferredCountries: ["US", "AU", "CA", "GB"],
    placeholder: "Enter a phone number",
    mode: "international",
    showDialCodeInSelection: true,
    dropdownOptions: {
        showFlags: true,
        showDialCodeInSelection: true
    },
    defaultCountry: "US",
    disabledFormatting: false,
    wrapperClasses: "country-phone-input",
    validCharactersOnly: true
});

app.use(
    createGtm({
        id: 'GTM-5829D5G',
        nonce: uuid(), // Will add `nonce` to the script tag
        debug: useHelpers().isLocal(), // Whether or not display console logs debugs (optional)
        vueRouter: router // Pass the router instance to automatically sync with router (optional)
    }),
);

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: import.meta.env.VITE_APP_INSIGHTS_KEY
    }
});

appInsights.loadAppInsights();

useDefaultInjections(app, appInsights);
configureLocalStorage(appInsights);

window.onerror = (evt: any, url: any, lineNumber: any, columnNumber: any, ex: Error | undefined) => {
    if (!(ex as any).aiLogged) {
        appInsights.trackException({
            exception: ex,
            severityLevel: SeverityLevel.Error
        }, useHelpers().getLoggingProperties(ex?.name, ex?.message));
    }
};

app.config.errorHandler = (ex: any, vm, info) => {
    if (!ex.aiLogged) {
        appInsights.trackException({
            exception: ex,
            severityLevel: SeverityLevel.Error
        }, useHelpers().getLoggingProperties(ex.name, ex.message));
    }

    ex.aiLogged = true;
    console.error(ex);

    if (ex.throw) {
        ex.throw = false;
        throw ex;
    }
}

app.provide(InjectionKeyAppInsights, appInsights);
app.use(router);
app.use(VueSweetalert2, options);
app.mount("#app");
