import { onBeforeUnmount } from "vue";
import { TinyEmitter } from "tiny-emitter";

const eventEmitter = new TinyEmitter();

export type EventsEnum =
	| "close-popup"
	| "toggle-audio"
	| "toggle-video"
	| "mute-all-audio"
	| "mute-all-video"
	| "toggle-chat"
	| "toggle-users"
	| "toggle-invite"
	| "toggle-invite-sms"
	| "close-panel"
	| "settings-open"
	| "bot-open"
	| "hand-raised"
	| "joined"
	| "loading"
	| "loading-complete"
	| "refresh-ui"
	| "toast-open"
	| "toast-close"
	| "modal-open"
	| "modal-close"
	| "recording-started"
	| "recording-ended"
	| "toggle-local-my-world"
	| "toggle-remote-my-world"
	| "leave-call"
	| "hand-raised"
	| "hand-lowered"
	| "nav-alert"
	| "more-dialog"
	| "close-more-dialog"
	| "custom-logo-updated"
	| "toggle-share-modal"
	| "close-share-modal"
	| "audio-input-changed"
	| "audio-output-changed"
	| "video-input-changed"
	| "meeting-ended"
	| "permissions-enabled"
	| "orientation-change"
	| "desktop-resize"
	| "emoji-reaction"
	| "close-emoji-container"
	| "signed-in-mid-meeting"
	| "host-signed-in-mid-meeting"
	| "toggle-report-issue"
	| "full-auth-validated"
	| "media-removed"
	| "transcript-received"
	| "transcript-loading"
	| "transcript-finished"
	| "transcript-failed"
	| "transcript-failed-end"
	| "listening-start"
	| "listening-stop"
	| "bot-connecting"
	| "bot-ready"
	| "toggle-video-blur"
	| "video-blur-updated"
	| "local-video-ready";

export default function useEventBus() {
	const eventHandlers = [] as { event: EventsEnum; handler: Function }[];

	/*onBeforeUnmount(() =>
		eventHandlers.forEach((eventHandler) =>
			eventEmitter.off(eventHandler.event, eventHandler.handler)
		)
	);*/

	return {
		onEvent(event: EventsEnum, handler: Function) {
			eventHandlers.push({ event, handler });
			eventEmitter.on(event, handler);
		},
		emitEvent(event: EventsEnum, payload?: unknown) {
			eventEmitter.emit(event, payload);
		},
		toast(text: string, timeout?: number, onclose?: () => void) {
			eventEmitter.emit("toast-open" as EventsEnum, {
				text,
				timeout,
				onclose,
			});
		},
		loading(loadingMessage: string) {
			eventEmitter.emit("loading" as EventsEnum, loadingMessage);
		},
		loadingComplete() {
			eventEmitter.emit("loading-complete" as EventsEnum, undefined);
		},
		navAlert(text: string, timeout?: number, onclose?: () => void) {
			eventEmitter.emit("nav-alert" as EventsEnum, {
				text,
				timeout
			});
		}
	};
}
