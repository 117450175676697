﻿<template>
	<div class="d-flex justify-content-between align-items-center">
		<div class="d-flex no-wrap">
			<div class="participant-name no-wrap" :class="{ me: currentUser }">
				<span v-text="attendee?.displayName" :title="attendee?.displayName"></span>
				<span class="participant-role" v-if="userRole != 'ATTENDEE'">({{ attendee?.role }})</span>
			</div>
		</div>
		<div class="participant-actions">
			<!-- PIN -->
			<!-- <div class="d-flex" v-if="!pinned" @click="pinned = !pinned">
				<i class="fas fa-thumbtack rotate-90 participant-action"></i>
			</div>
			<div class="d-flex" v-if="pinned" @click="pinned = !pinned">
				<i class="fas fa-thumbtack participant-action"></i>
			</div> -->

			<!-- TOGGLE AUDIO -->
			<div class="d-flex position-relative audio-toggle-container user-audio-toggle" @click="toggleAudio">
				<div
					class="d-flex position-absolute audio-speaking"
					v-if="!meetingHandler.UserActionHandler.isUserAudioMuted(attendee?.id)">
					<i class="fas fa-microphone participant-action"></i>
				</div>
				<div
					class="d-flex position-absolute audio-background"
					:style="{ height: audioLevel }"
					:class="audioDisabledStyle"
					:disabled="!isModerator"
					v-if="!meetingHandler.UserActionHandler.isUserAudioMuted(attendee?.id)">
					<i class="fas fa-microphone participant-action"></i>
				</div>

				<div
					class="d-flex position-absolute"
					:class="audioDisabledStyle"
					:disabled="!isModerator"
					v-if="meetingHandler.UserActionHandler.isUserAudioMuted(attendee?.id)">
					<i class="fas fa-microphone-slash participant-action"></i>
				</div>
			</div>
			<!-- <div
				class="d-flex"
				@click="toggleAudio"
				:class="audioDisabledStyle"
				:disabled="!isModerator"
				v-if="!meetingHandler.UserActionHandler.isUserAudioMuted(attendee?.id)">
				<i class="fas fa-microphone participant-action"></i>
			</div>
			<div
				class="d-flex"
				:class="audioDisabledStyle"
				@click="toggleAudio"
				:disabled="!isModerator"
				v-if="meetingHandler.UserActionHandler.isUserAudioMuted(attendee?.id)">
				<i class="fas fa-microphone-slash participant-action"></i>
			</div> -->
			<!-- TOGGLE VIDEO -->
			<div class="d-flex user-video-toggle" @click="toggleVideo" v-if="attendee?.type != 'SIPINBOUND' && !isBot">
				<div
					class="d-flex user-video-toggle"
					:class="videoDisabledStyle"
					:disabled="!isModerator"
					v-if="!meetingHandler.UserActionHandler.isUserVideoMuted(attendee?.id)">
					<i class="fas fa-video participant-action"></i>
				</div>
				<div
					class="d-flex"
					:class="videoDisabledStyle"
					:disabled="!isModerator"
					v-if="meetingHandler.UserActionHandler.isUserVideoMuted(attendee?.id)">
					<i class="fas fa-video-slash participant-action"></i>
				</div>
			</div>

			<!-- GEAR -->
			<div
				class="d-flex position-relative"
				@click="configPopup = !configPopup"
				v-if="canManageOthers && !currentUser">
				<i class="fas fa-cog participant-action"></i>
				<div v-if="configPopup" class="menu-popup">
					<strong class="px-2">
						{{ meetingHandler.UserActionHandler.getDisplayName(attendee?.id) }}
					</strong>
					<hr />
					<div class="menu-item px-2" @mousedown="kickUser" v-if="canKick">Kick User</div>
					<div class="menu-item px-2" @mousedown="toggleScreenShare" v-if="canDisableScreenShare && !isBot">
						{{ canUserShare() ? "Disable" : "Enable" }}
						Screen Share
					</div>
					<div class="menu-item px-2" @mousedown="toggleModerator" v-if="canMakeModerator && !isBot">
						{{
							meetingHandler.UserActionHandler.isUserModerator(attendee?.id)
								? "Remove Moderator"
								: "Make Moderator"
						}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import type MeetingHandler from "@/classes/MeetingHandler";
	import type { Attendee, Media } from "@liveswitch/sdk";
	import type { AttendeeRole } from "@liveswitch/sdk";
	import { computed, ref, type PropType } from "vue";
	import useEventBus from "../composables/useEventBus";

	const props = defineProps({
		currentUserRole: String as PropType<AttendeeRole>,
		attendee: {
			type: Object as PropType<Attendee>,
			required: true,
		},
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
		video: {
			type: Object as PropType<Media>,
		},
	});

	const pinned = ref(false);
	const configPopup = ref(false);

	useEventBus().onEvent("close-popup", () => (configPopup.value = false));

	const canManageOthers = computed(() => props.currentUserRole === "HOST" || props.currentUserRole === "MODERATOR");
	const isModerator = computed(() => props.currentUserRole === "HOST" || props.currentUserRole === "MODERATOR");
	const userRole = computed(() => {
		return props.attendee?.role;
	});

	const isBot = computed(() => {
		const isBot = props.attendee?.avatarUrl?.includes("LiveSwitch Bot");
		return isBot;
	});

	const currentUser = computed(() => {
		return props.meetingHandler.localAttendeeId == props.attendee?.id;
	});

	const audioLevel = computed(() => {
		// TODO: Mock props.meetingHandler.visibleUserMedias for unit test

		if (!props.attendee?.isAudioMuted) {
			if (props.video && props.video.audioTrack && props.video.audioTrack.isSpeaking) {
				let audioLevel = Math.round(props.video.audioTrack.level * 300);
				audioLevel = 100 - audioLevel;
				return `${audioLevel}%`;
			}
		}

		return "100%";
	});

	const canKick = computed(() => {
		return props.currentUserRole === "HOST" || (props.currentUserRole === "MODERATOR" && userRole.value != "HOST");
	});

	const canMakeModerator = computed(() => {
		return (
			canManageOthers.value &&
			(userRole.value === "ATTENDEE" || userRole.value === "MODERATOR") &&
			props.attendee?.type != "SIPINBOUND"
		);
	});

	const canDisableScreenShare = computed(() => {
		return !props.attendee?.avatarUrl?.includes("mobile") && canMakeModerator.value;
	});

	const audioDisabledStyle = computed(() => {
		return {
			"participant-action-disabled": props.meetingHandler.UserActionHandler.isUserAudioDisabled(
				props.attendee?.id
			),
		};
	});

	const videoDisabledStyle = computed(() => {
		return {
			"participant-action-disabled": props.meetingHandler.UserActionHandler.isUserVideoDisabled(
				props.attendee?.id
			),
		};
	});

	function canUserShare() {
		return props.meetingHandler.UserActionHandler.canUserShare(props.attendee?.id);
	}

	async function toggleAudio() {
		if (!canManageOthers.value && !currentUser.value) {
			return;
		}

		await props.meetingHandler.UserActionHandler.toggleUserAudio(props.attendee?.id);
	}

	async function toggleVideo() {
		if (!canManageOthers.value && !currentUser.value) {
			return;
		}

		await props.meetingHandler.UserActionHandler.toggleUserVideo(props.attendee?.id);
	}

	function toggleModerator() {
		props.meetingHandler.UserActionHandler.toggleModerator(props.attendee?.id);
	}

	function kickUser() {
		props.meetingHandler.UserActionHandler.kickUser(props.attendee?.id);
	}

	function toggleScreenShare() {
		props.meetingHandler.UserActionHandler.toggleScreenShare(props.attendee?.id);
	}
</script>

<style lang="scss">
	.participant-name-container {
		max-width: 75%;
	}

	.participant-name,
	.participant-role {
		// font-weight: 500;
		font-family: "Inter_Bold";
	}
	.participant-name.me {
		// font-weight: 700;
	}

	.no-wrap {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.participant-role {
		margin-left: 4px;
		margin-top: 2px;
		font-weight: normal;
		font-size: 12px;
		line-height: 24px;
		text-transform: capitalize;
	}

	.participant-actions {
		display: flex;
		margin-right: 6px;
	}

	div[disabled="true"] .participant-action {
		cursor: default;
		&:hover {
			color: black;
		}
	}
	.participant-action {
		margin: 0 6px;
		cursor: pointer;
	}

	.participant-action:hover,
	.participant-action-disabled {
		color: #8a94a6;
	}

	.participant-host-actions {
		justify-self: flex-end;
	}

	.list-group-item {
		border: none !important;
		padding: 0.5em 0 0 0.75em !important;
	}

	.menu-popup {
		position: absolute;
		left: -130px;
		top: 20px;
		background-color: rgba(43, 43, 43, 0.8);
		color: white;
		border-radius: 8px;
		padding: 0.5em 2px;
		z-index: 1000;
		font-size: 12px;
		hr {
			margin: 4px 0;
		}
		.menu-item {
			cursor: pointer;
			&:hover {
				background-color: rgba(0, 0, 0, 0.5);
			}
			transition: 0.2s;
		}
	}

	.audio-toggle-container {
		min-height: 16px;
		width: 24px;
	}

	.user-video-toggle {
		width: 24px;
	}

	.user-audio-toggle,
	.user-video-toggle {
		justify-content: center;
	}

	.audio-speaking {
		color: limegreen;
	}

	.audio-background {
		overflow: hidden;
		transition: 0.25s all;
	}
</style>
