import FindContours from "./FindContours";

export default class VideoBlurHelper {
    private static _instance: VideoBlurHelper;
    private readonly _selfieSegmentation;
    private readonly MUL_TABLE = [1, 171, 205, 293, 57, 373, 79, 137, 241, 27, 391, 357, 41, 19, 283, 265, 497, 469, 443, 421, 25, 191, 365, 349, 335, 161, 155, 149, 9, 278, 269, 261, 505, 245, 475, 231, 449, 437, 213, 415, 405, 395, 193, 377, 369, 361, 353, 345, 169, 331, 325, 319, 313, 307, 301, 37, 145, 285, 281, 69, 271, 267, 263, 259, 509, 501, 493, 243, 479, 118, 465, 459, 113, 446, 55, 435, 429, 423, 209, 413, 51, 403, 199, 393, 97, 3, 379, 375, 371, 367, 363, 359, 355, 351, 347, 43, 85, 337, 333, 165, 327, 323, 5, 317, 157, 311, 77, 305, 303, 75, 297, 294, 73, 289, 287, 71, 141, 279, 277, 275, 68, 135, 67, 133, 33, 262, 260, 129, 511, 507, 503, 499, 495, 491, 61, 121, 481, 477, 237, 235, 467, 232, 115, 457, 227, 451, 7, 445, 221, 439, 218, 433, 215, 427, 425, 211, 419, 417, 207, 411, 409, 203, 202, 401, 399, 396, 197, 49, 389, 387, 385, 383, 95, 189, 47, 187, 93, 185, 23, 183, 91, 181, 45, 179, 89, 177, 11, 175, 87, 173, 345, 343, 341, 339, 337, 21, 167, 83, 331, 329, 327, 163, 81, 323, 321, 319, 159, 79, 315, 313, 39, 155, 309, 307, 153, 305, 303, 151, 75, 299, 149, 37, 295, 147, 73, 291, 145, 289, 287, 143, 285, 71, 141, 281, 35, 279, 139, 69, 275, 137, 273, 17, 271, 135, 269, 267, 133, 265, 33, 263, 131, 261, 130, 259, 129, 257, 1];
    private readonly SHG_TABLE = [0, 9, 10, 11, 9, 12, 10, 11, 12, 9, 13, 13, 10, 9, 13, 13, 14, 14, 14, 14, 10, 13, 14, 14, 14, 13, 13, 13, 9, 14, 14, 14, 15, 14, 15, 14, 15, 15, 14, 15, 15, 15, 14, 15, 15, 15, 15, 15, 14, 15, 15, 15, 15, 15, 15, 12, 14, 15, 15, 13, 15, 15, 15, 15, 16, 16, 16, 15, 16, 14, 16, 16, 14, 16, 13, 16, 16, 16, 15, 16, 13, 16, 15, 16, 14, 9, 16, 16, 16, 16, 16, 16, 16, 16, 16, 13, 14, 16, 16, 15, 16, 16, 10, 16, 15, 16, 14, 16, 16, 14, 16, 16, 14, 16, 16, 14, 15, 16, 16, 16, 14, 15, 14, 15, 13, 16, 16, 15, 17, 17, 17, 17, 17, 17, 14, 15, 17, 17, 16, 16, 17, 16, 15, 17, 16, 17, 11, 17, 16, 17, 16, 17, 16, 17, 17, 16, 17, 17, 16, 17, 17, 16, 16, 17, 17, 17, 16, 14, 17, 17, 17, 17, 15, 16, 14, 16, 15, 16, 13, 16, 15, 16, 14, 16, 15, 16, 12, 16, 15, 16, 17, 17, 17, 17, 17, 13, 16, 15, 17, 17, 17, 16, 15, 17, 17, 17, 16, 15, 17, 17, 14, 16, 17, 17, 16, 17, 17, 16, 15, 17, 16, 14, 17, 16, 15, 17, 16, 17, 17, 16, 17, 15, 16, 17, 14, 17, 16, 15, 17, 16, 17, 13, 17, 16, 17, 17, 16, 17, 14, 17, 16, 17, 16, 17, 16, 17, 9];

    private _resultsCallback: Function = undefined;

    public static preload(){
        // create a dummy instance to ensure we preload
        let x = (async function(){
            let _ = new SelfieSegmentation({
                locateFile: VideoBlurHelper.locateFile
            })
            _.setOptions({
                selfieMode: false,
                modelSelection: 1,
            });
            await _.send(document.createElement('video'))
            _.close()
        });
        x()
    }

    private static locateFile(file:string): string{
        let f = `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`
        console.log(f)
        return f;
    }

    private constructor() {
        this._selfieSegmentation = new SelfieSegmentation({
            locateFile: VideoBlurHelper.locateFile
        });

        this._selfieSegmentation.setOptions({
            selfieMode: false,
            modelSelection: 1,
        });

        if (this._resultsCallback) {
            this.onResultsCallback = this._resultsCallback;
        }
    }

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public static init() {
        this._instance = new this();
    }

    public set onResultsCallback(callback: Function) {
        this._resultsCallback = callback;
        this._selfieSegmentation.onResults(callback);
    }

    public removeOnResultCallback(): void {
        this._selfieSegmentation.onResult(null);
    }

    public async close(): Promise<void> {
        this._selfieSegmentation.onResults(null);
        await this._selfieSegmentation.close();
    }

    public static async restart(): Promise<void> {
        await this.instance.close();
        this.init();
    }

    public reset(): void {
        try {
            this._selfieSegmentation.reset();
        } catch (err) {
            console.error('Error resetting Selfie Segmentation', err);
        }
    }

    public async sendImage(image: HTMLVideoElement): Promise<void> {
        await this._selfieSegmentation.send({ image: image });
    }

    public applyCustomBlur(outCtx: CanvasRenderingContext2D, inSrc: HTMLCanvasElement | HTMLVideoElement, inCanvas: HTMLCanvasElement, inCtx: CanvasRenderingContext2D, width: number, height: number, blur: number): void {
        try {
            const scaledWidth = width / 2;
            const scaledHeight = height / 2;
            // draw the initial source at the target height/width
            inCtx.drawImage(inSrc, 0, 0, scaledWidth, scaledHeight);
            // get a copy of the scaled source and blur it
            var data = inCtx.getImageData(0, 0, scaledWidth, scaledHeight);
            this.applyBlurFilter(data, Math.min(100, Math.max(1, Math.round(blur / 2))), 1); // 1 iteration

            // todo: do we need this extra put, or can we put directly from the data to the
            inCtx.putImageData(data, 0, 0);
            outCtx.drawImage(inCanvas, 0, 0, scaledWidth, scaledHeight, 0, 0, width, height);
        } catch (ex) {

        }
    }


    public applyBlurFilter(imageData: ImageData, blurPx: number, iterations: number) {
        var radiusX = blurPx;
        var radiusY = blurPx;

        if (isNaN(iterations) || iterations < 1) iterations = 1;
        iterations |= 0;
        if (iterations > 3) iterations = 3;
        if (iterations < 1) iterations = 1;

        var px = imageData.data;
        var x = 0, y = 0, i = 0, p = 0, yp = 0, yi = 0, yw = 0, r = 0, g = 0, b = 0, a = 0, pr = 0, pg = 0, pb = 0, pa = 0;

        var divx = (radiusX + radiusX + 1) | 0;
        var divy = (radiusY + radiusY + 1) | 0;
        var w = imageData.width | 0;
        var h = imageData.height | 0;

        var w1 = (w - 1) | 0;
        var h1 = (h - 1) | 0;
        var rxp1 = (radiusX + 1) | 0;
        var ryp1 = (radiusY + 1) | 0;

        var ssx = { r: 0, b: 0, g: 0, a: 0, n: undefined };
        var sx = ssx;
        for (i = 1; i < divx; i++) {
            sx = sx.n = { r: 0, b: 0, g: 0, a: 0, n: undefined };
        }
        sx.n = ssx;

        var ssy = { r: 0, b: 0, g: 0, a: 0, n: undefined };
        var sy = ssy;
        for (i = 1; i < divy; i++) {
            sy = sy.n = { r: 0, b: 0, g: 0, a: 0, n: undefined };
        }
        sy.n = ssy;

        var si = null;


        var mtx = this.MUL_TABLE[radiusX] | 0;
        var stx = this.SHG_TABLE[radiusX] | 0;
        var mty = this.MUL_TABLE[radiusY] | 0;
        var sty = this.SHG_TABLE[radiusY] | 0;

        while (iterations-- > 0) {

            yw = yi = 0;
            var ms = mtx;
            var ss = stx;
            for (y = h; --y > -1;) {
                r = rxp1 * (pr = px[(yi) | 0]);
                g = rxp1 * (pg = px[(yi + 1) | 0]);
                b = rxp1 * (pb = px[(yi + 2) | 0]);
                a = rxp1 * (pa = px[(yi + 3) | 0]);

                sx = ssx;

                for (i = rxp1; --i > -1;) {
                    sx.r = pr;
                    sx.g = pg;
                    sx.b = pb;
                    sx.a = pa;
                    sx = sx.n;
                }

                for (i = 1; i < rxp1; i++) {
                    p = (yi + ((w1 < i ? w1 : i) << 2)) | 0;
                    r += (sx.r = px[p]);
                    g += (sx.g = px[p + 1]);
                    b += (sx.b = px[p + 2]);
                    a += (sx.a = px[p + 3]);

                    sx = sx.n;
                }

                si = ssx;
                for (x = 0; x < w; x++) {
                    px[yi++] = (r * ms) >>> ss;
                    px[yi++] = (g * ms) >>> ss;
                    px[yi++] = (b * ms) >>> ss;
                    px[yi++] = (a * ms) >>> ss;

                    p = ((yw + ((p = x + radiusX + 1) < w1 ? p : w1)) << 2);

                    r -= si.r - (si.r = px[p]);
                    g -= si.g - (si.g = px[p + 1]);
                    b -= si.b - (si.b = px[p + 2]);
                    a -= si.a - (si.a = px[p + 3]);

                    si = si.n;

                }
                yw += w;
            }

            ms = mty;
            ss = sty;
            for (x = 0; x < w; x++) {
                yi = (x << 2) | 0;

                r = (ryp1 * (pr = px[yi])) | 0;
                g = (ryp1 * (pg = px[(yi + 1) | 0])) | 0;
                b = (ryp1 * (pb = px[(yi + 2) | 0])) | 0;
                a = (ryp1 * (pa = px[(yi + 3) | 0])) | 0;

                sy = ssy;
                for (i = 0; i < ryp1; i++) {
                    sy.r = pr;
                    sy.g = pg;
                    sy.b = pb;
                    sy.a = pa;
                    sy = sy.n;
                }

                yp = w;

                for (i = 1; i <= radiusY; i++) {
                    yi = (yp + x) << 2;

                    r += (sy.r = px[yi]);
                    g += (sy.g = px[yi + 1]);
                    b += (sy.b = px[yi + 2]);
                    a += (sy.a = px[yi + 3]);

                    sy = sy.n;

                    if (i < h1) {
                        yp += w;
                    }
                }

                yi = x;
                si = ssy;
                if (iterations > 0) {
                    for (y = 0; y < h; y++) {
                        p = yi << 2;
                        px[p + 3] = pa = (a * ms) >>> ss;
                        if (pa > 0) {
                            px[p] = ((r * ms) >>> ss);
                            px[p + 1] = ((g * ms) >>> ss);
                            px[p + 2] = ((b * ms) >>> ss);
                        } else {
                            px[p] = px[p + 1] = px[p + 2] = 0
                        }

                        p = (x + (((p = y + ryp1) < h1 ? p : h1) * w)) << 2;

                        r -= si.r - (si.r = px[p]);
                        g -= si.g - (si.g = px[p + 1]);
                        b -= si.b - (si.b = px[p + 2]);
                        a -= si.a - (si.a = px[p + 3]);

                        si = si.n;

                        yi += w;
                    }
                } else {
                    for (y = 0; y < h; y++) {
                        p = yi << 2;
                        px[p + 3] = pa = (a * ms) >>> ss;
                        if (pa > 0) {
                            pa = 255 / pa;
                            px[p] = ((r * ms) >>> ss) * pa;
                            px[p + 1] = ((g * ms) >>> ss) * pa;
                            px[p + 2] = ((b * ms) >>> ss) * pa;
                        } else {
                            px[p] = px[p + 1] = px[p + 2] = 0
                        }

                        p = (x + (((p = y + ryp1) < h1 ? p : h1) * w)) << 2;

                        r -= si.r - (si.r = px[p]);
                        g -= si.g - (si.g = px[p + 1]);
                        b -= si.b - (si.b = px[p + 2]);
                        a -= si.a - (si.a = px[p + 3]);

                        si = si.n;

                        yi += w;
                    }
                }
            }

        }
        return true;
    }

    public smoothSegmentationMask(canvasCtx: CanvasRenderingContext2D, canvasElement: HTMLCanvasElement) {
        const segmentationThreshold = 200;
        let epsilon = 1.2
        let contours = null;
        let binaryData = new Uint16Array(canvasElement.width * canvasElement.height);
        let d = canvasCtx.getImageData(0, 0, canvasElement.width, canvasElement.height);
        let n = d.width * d.height * 4;
        let data = d.data;
        let j = 0;
        for (let i = 0; i < n; i += 4) {
            if (data[i + 3] >= segmentationThreshold) {// && data[i+1] > segmentationThreshold && data[i+2] > segmentationThreshold) {
                data[i] = 255; data[i + 1] = 255; data[i + 2] = 255;
                binaryData[j] = 1;
            } else {
                data[i] = 0; data[i + 1] = 0; data[i + 2] = 0;
                binaryData[j] = 0;
            }
            data[i + 3] = 255;
            j++;
        }
        //bcanvasCtx.putImageData(d, 0, 0);

        contours = FindContours.findContours(binaryData, canvasElement.width, canvasElement.height);
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        let list = [0, 0, 0, 0];
        if (contours.length > 0) {
            let contour = contours[0];
            //for (let contour of contours) {
            if (contour.isHole === false) {
                list[3] += 1;
                canvasCtx.strokeStyle = 'red'
                canvasCtx.lineWidth = 3
                let pts = contour.points;
                let len = pts.length
                let pts1 = FindContours.approxPolySimple(pts);
                let len1 = pts1.length;
                let pts2 = FindContours.approxPolyDP(pts, epsilon);
                let len2 = pts2.length;
                let points = [pts, pts1, pts2];
                points = points[2];
                // if (curveType === 0)
                //     drawPolyline(drawPoints, 'red')
                // else
                //     drawCurve(drawPoints, 'blue')

                canvasCtx.fillStyle = 'red';
                canvasCtx.beginPath();
                if (points == undefined || points.length == 0) {
                    return true;
                }
                if (points.length == 1) {
                    canvasCtx.moveTo(points[0][0], points[0][1]);
                    canvasCtx.lineTo(points[0][0], points[0][1]);
                    return true;
                }
                if (points.length == 2) {
                    canvasCtx.moveTo(points[0][0], points[0][1]);
                    canvasCtx.lineTo(points[1][0], points[1][1]);
                    return true;
                }
                canvasCtx.moveTo(points[0][0], points[0][1]);
                for (var i = 1; i < points.length - 2; i++) {
                    const xc = (points[i][0] + points[i + 1][0]) / 2;
                    const yc = (points[i][1] + points[i + 1][1]) / 2;
                    canvasCtx.quadraticCurveTo(points[i][0], points[i][1], xc, yc);
                }
                canvasCtx.quadraticCurveTo(points[i][0], points[i][1], points[i + 1][0], points[i + 1][1]);
                canvasCtx.fill();

                list[0] += len;
                list[1] += len1;
                list[2] += len2;
            }
        }
    }


}