<template></template>
<script lang="ts" setup>
	import { inject, onMounted } from "vue";
	import useEventBus from "@/composables/useEventBus";
	import type { ApiSignInResponse, IAuthenticationService } from "@/classes/AuthenticationService";
	import Swal from "sweetalert2/dist/sweetalert2.js";
	import { getCookie, removeCookie } from "typescript-cookie";
	import { useGtm } from "@gtm-support/vue-gtm";
	import { authenticationServiceKey, InjectionKeyAppInsights } from "@/composables/injectKeys";
	import useHelpers from "@/composables/useHelpers";
	import { SeverityLevel } from "@microsoft/applicationinsights-common";
	import type { ApplicationInsights } from "@microsoft/applicationinsights-analytics-js";

	const LOGIN_METHOD_GOOGLE = "Google";
	const LOGIN_METHOD_FACEBOOK = "Facebook";
	const LOGIN_METHOD_LINKEDIN = "LinkedIn";
	const LOGIN_METHOD_USERNAME = "Username";
	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const authenticationService = inject(authenticationServiceKey) as IAuthenticationService;
	authenticationService.appInsights = appInsights;
	onMounted(async () => {
		try {
			console.debug("Processing SSO login");
			const params = new URLSearchParams(window.location.search.split("#")[0]);

			if (params.get("ssoComplete") === "true") {
				useEventBus().emitEvent("loading", "Logging in...");
				await authenticationService.clearDeadCookies();
				let ssoResponse = authenticationService.signInSSO();
				let response = await authenticationService.signIn(undefined, ssoResponse);
				useEventBus().emitEvent("loading-complete");

				if (response.isValid()) {
					if (!response.Error && response.Message?.Reason) {
						Swal.fire({
							title: "Success",
							html: response.Message?.Reason,
							icon: "success",
							confirmButtonText: "Close",
						}).then(() => {
							onAuthenticated(response, getLoginMethod());
						});
					} else {
						onAuthenticated(response, getLoginMethod());
					}
				}
			} else if (params.get("ssoError") === "true") {
				processSSOError();
			} else {
				return;
			}

			useHelpers().removeQueryParams(["ssoComplete", "ssoError"]);
		} catch (error: any) {
			appInsights.trackException(
				{
					exception: error,
					id: "SSOLobbyFailed",
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties("SSOLobbyFailed", "SSOLobbyFailed", {
					referrer: document.referrer,
				})
			);
		}
	});

	function processSSOError() {
		let message;
		const responseBase64 = getCookie("X-Sign-In-Response");

		if (responseBase64) {
			const responseJson = atob(responseBase64);
			message = JSON.parse(responseJson)?.Message?.Reason;
			removeCookie("X-Sign-In-Request", {
				domain: import.meta.env.VITE_COOKIE_DOMAIN,
			});
			removeCookie("X-Sign-In-Response", {
				domain: import.meta.env.VITE_COOKIE_DOMAIN,
			});
		}

		message = message ?? "An unexpected error occurred. Please try again.";

		Swal.fire({
			title: "Error",
			html: message,
			confirmButtonText: "Close",
		});
	}

	function getLoginMethod(): string {
		if (document.referrer.toLowerCase().indexOf("google") != -1) {
			return LOGIN_METHOD_GOOGLE;
		} else if (document.referrer.toLowerCase().indexOf("facebook") != -1) {
			return LOGIN_METHOD_FACEBOOK;
		} else if (document.referrer.toLowerCase().indexOf("linkedin") != -1) {
			return LOGIN_METHOD_LINKEDIN;
		}

		return LOGIN_METHOD_USERNAME;
	}

	function onAuthenticated(response: ApiSignInResponse, method: string) {
		useGtm()?.trackEvent({
			event: "login",
			category: "auth",
			action: "click",
			method: method,
		});
	}
</script>
