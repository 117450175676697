import type { ScreenOrientation } from "@/classes/EventContracts";
import { useCookies } from "vue3-cookies";
import useLocalStorage from "./useLocalStorage";

// Here must be simple helper functions with no specific logic
export default function useHelpers() {
	const mobileBrowserRegex = /Android|iPhone|iOS|iPad|Blackberry/i;
	const safariRegex = /Safari/i;
	const chromeRegex = /Chrome/i;
	const chromeIosRegex = /CriOS/i;
	const firefoxRegex = /(Firefox|FxiOS)\/\d{2,3}(\.\d+)+/i;
	const braveRegex = /Brave/i;
	const operaRegex = /Opera|OPR/i;
	const urlRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
	const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
	const mobileLinuxRegex = /X11; Linux/
	const inAppBrowserRegex = /LinkedInApp|LightSpeed|FBAN|FBAV|FBVV|FBDV|FBMD|FBSN|FBSV|FBSS|FBCR|FBID|FBLC|FBOP|Slack/i;
	const phoneNumberRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
	const ipadSizes: { [key: string]: Number | Array<Number> } = {
		"1024": [768, 1366],
		"744": 1133,
		"1133": 744,
		"768": 1024,
		"1080": 810,
		"810": 1080,
		"1112": 834,
		"834": [1112, 1194],
		"1180": 820,
		"820": 1180,
		"1194": 834,
		"1366": 1024
	};

	return {
		isMobileBrowser() {
			let agent = navigator.userAgent;
			let mobile = /Android|iPhone|iPad|iPod|Mobile/i.test(agent);

			if (mobileLinuxRegex.test(agent) && this.isTouch()) {
				mobile = true;
			}

			return mobile;
		},
		isIpad() {
			let agent = navigator.userAgent;
			let ipad = /iPad/i.test(agent);

			if (ipad === false) {
				const height = window.screen.height;
				const ipadWidth = ipadSizes[height];
				const isArray = ipadWidth instanceof Array;

				if (ipadWidth && (ipadWidth == window.screen.width || (isArray && ipadWidth.indexOf(window.screen.width) != -1))) {
					ipad = true;
				}
			}

			return ipad;
		},
		isTouch() {
			return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
		},
		isSafari() {
			return safariRegex.test(navigator.userAgent) && !chromeRegex.test(navigator.userAgent);
		},
		isMobileSafari() {
			return this.isMobileBrowser() && this.isSafari();
		},
		isInAppBrowser() {
			return this.isMobileSafari() && inAppBrowserRegex.test(navigator.userAgent);
		},
		isMobileChromeIos() {
			return this.isMobileIos() && chromeIosRegex.test(navigator.userAgent);
		},
		isMobileIos() {
			let agent = navigator.userAgent;
			let mobile = /iPhone|iPad|iPod/i.test(agent);
			return mobile;
		},
		isFirefox() {
			return firefoxRegex.test(navigator.userAgent);
		},
		isBrave() {
			return braveRegex.test(navigator.userAgent);
		},
		isOpera() {
			return operaRegex.test(navigator.userAgent);
		},
		isWindows() {
			return /Windows/i.test(navigator.userAgent);
		},
		isMacOS() {
			return /Mac OS/i.test(navigator.userAgent);
		},
		hasRefreshToken() {
			let refreshCookie: string = useCookies().cookies.get("RefreshToken");
			return refreshCookie && refreshCookie !== '';
		},
		isAuthenticated() {
			let authCookie: string = useCookies().cookies.get('Authorization');
			return !(!authCookie || authCookie == '');
		},
		isAuthenticatedUser() {
			let refreshCookie: string = useCookies().cookies.get("RefreshToken");
			return this.isAuthenticated() && !!(refreshCookie && refreshCookie != '');
		},
		isAuthenticatedGuest() {
			let refreshCookie: string = useCookies().cookies.get("RefreshToken");
			return !(!this.isAuthenticated() || (refreshCookie && refreshCookie !== ''));
		},
		emptyUuid() {
			return '00000000-0000-0000-0000-000000000000';
		},
		isValidUrl(url: string): boolean {
			return urlRegex.test(url);
		},
		isValidEmail(email: string): boolean {
			return emailRegex.test(email);
		},
		isValidPhoneNumber(number: string): boolean {
			return phoneNumberRegex.test(number);
		},
		isVideoBlurSupported(): boolean {
			return !(this.isFirefox() || this.isOpera() || this.isBrave() ||
				(!this.isMobileBrowser() && !this.isIpad() && this.isSafari()))
		},
		isLocal() {
			const url = window.location.toString();

			if (url.indexOf('localhost') != -1 || 
				(window.location.port && window.location.port != '443')) {
				return true;
			} else {
				return false;
			}
		},
		isPortrait(): boolean {
			return useHelpers().getScreenOrientation().type.includes("portrait");
		},
		getScreenOrientation(): ScreenOrientation {
			let orientation = "";

			var mql = window.matchMedia("(orientation: portrait)");

			if (mql.matches) {
				orientation = "portrait";
			} else {
				orientation = "landscape";
			}

			// if (window.innerHeight > window.innerWidth) {
			// 	orientation = "portrait";
			// } else {
			// 	orientation = "landscape";
			// }

			return {
				type: orientation
			} as ScreenOrientation;
		},
		getLoggingProperties(eventName?: string, message?: string, additionalProperties?: object): object {
			let channelKey;
			let username;
			let userAccountId;

			try {
				channelKey = useLocalStorage().getChannelKey();
				username = useLocalStorage().getUsername();
				userAccountId = useLocalStorage().getUserAccountId();
			} catch (e) {
				console.debug("Failed to retrieve logging properties from local storage, possibly due to anonymous browsing.");
			}

			let properties = {
				Name: "",
				Message: "",
				ChannelKey: channelKey,
				Username: username,
				UserAccountId: userAccountId,
				ScreenHeight: window.screen.height,
				ScreenWidth: window.screen.width,
				DocumentHeight: document.body.offsetHeight,
				DocumentWidth: document.body.offsetWidth,
				UserAgent: navigator.userAgent,
				UserAgentData: navigator.userAgentData,
				Vendor: navigator.vendor
			}

			if (eventName) {
				properties.Name = eventName;
			}

			if (message) {
				properties.Message = message;
			}

			if (additionalProperties) {
				properties = Object.assign({}, properties, additionalProperties);
			}

			return properties;
		},
		getQueryParam(name: string) {
			const queryParams = new URLSearchParams(window.location.search.split("#")[0]);
			return queryParams.get(name);
		},
		removeQueryParams(params: string[]) {
			if (!params || params.length == 0) {
				return;
			}

			params.push('_gl');

			try {
				const queryParams = new URLSearchParams(window.location.search.split("#")[0]);

				for (let i = 0; i < params.length; i++) {
					const key = params[i];
					queryParams.delete(key);
				}

				let url = `${window.location.pathname}`;

				if (queryParams.toString().length > 0) {
					url = `${url}?${queryParams.toString()}`;
				}
				url += "#";

				window.history.replaceState({}, document.title, url);
			} catch (err: any) {
				console.error("Error removing query params", err);
			}
		},
		isAuthReferrer(referrer: string | null): boolean {
			if (!referrer) {
				return false;
			}

			referrer = referrer.toLowerCase();
			return referrer.indexOf("/login") != -1 || referrer.indexOf("/register") != -1 || referrer.indexOf("/resetpassword") != -1;
		},
		getParams(): URLSearchParams {
			return new URLSearchParams(
				[...new URLSearchParams(window.location.search)].map(([key, value]) => [key.toLowerCase(), value])
			);
		},
	};
}