﻿export default class User {
	public readonly clientId: string;

	constructor(
		public readonly id: string,
		public readonly displayName: string
	) {
		this.clientId = id;
	}

	public get audioMuted(): boolean {
		return false;
	}

	public get audioOnly(): boolean {
		return false;
	}

	public get audioDiabled(): boolean {
		return false;
	}

	public get videoMuted(): boolean {
		return false;
	}

	public get videoDisabled(): boolean {
		return false;
	}
}
