<template>
	<div class="d-none">
		<audio ref="audioElement" :srcObject.prop.camel="audio.stream" autoplay="autoplay" />
		<span>Attendee: {{ audio?.attendee?.displayName }}</span>
		<span>{{ audio?.audioTrack?.level }}</span>
	</div>
</template>

<script lang="ts" setup>
	import type MeetingHandler from "@/classes/MeetingHandler";
	import type { Media } from "@liveswitch/sdk";
	import { onMounted, ref, type PropType } from "vue";

	const audioElement = ref<HTMLAudioElement>();

	const props = defineProps({
		audio: {
			type: Object as PropType<Media>,
			required: true,
		},
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
	});

	onMounted(() => {
		//testAudio();

		if (audioElement.value) {
			audioElement.value.onloadedmetadata = () => {
				audioElement.value?.play();
				console.debug(
					`Audio MetaData Loaded: MediaId=${props.audio.id}, StreamId=${
						props.audio.stream.id
					}, HasAudioTrack=${props.audio.audioTrack ? true : false}, Playing=${!audioElement.value?.paused}`
				);
			};
		}
	});

	function testAudio() {
		const random = Math.random() * 10000;
		console.debug(`audio test timeout: ${random}`);

		setTimeout(() => {
			if (audioElement.value) {
				console.debug("clearing audio stream");
				audioElement.value.srcObject = null;

				setTimeout(() => {
					if (audioElement.value) {
						console.debug("setting audio stream");
						audioElement.value.srcObject = props.audio.stream;
						testAudio();
					}
				}, 2000);
			}
		}, random);
	}
</script>
