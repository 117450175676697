import type { VirtualBackgroundImage } from "./VirtualBackgroundImage";

export class VirtualBackground{
    
    public images: VirtualBackgroundImage[] = [];
    public name: string = ''
    public id: string = ''

    public constructor(init?:Partial<VirtualBackground>) {
        Object.assign(this, init);
    }

    getImage(orientation: 'portrait' | 'landscape'): HTMLImageElement | null{
        let img = this.images.find(x=>x.orientation == orientation)
        if(img && img.image){
            return img.image
        }
        return null
    }
}

