import axios, { type AxiosResponse, type Method } from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import useEventBus from "@/composables/useEventBus";
import { useCookies } from "vue3-cookies";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import useLocalStorage from "@/composables/useLocalStorage";
import type { ApiSignInResponse, ApiSignUpResponse, RefreshTokenRequest, SignInRequest } from "@/classes/AuthenticationService";

export interface SomeRequest {
	// Example
}

export interface SomeResponse {
	// Example
}

export default class APIServiceClient {
	private _api = axios.create();

	private readonly fingerprintPromise = FingerprintJS.load();
	private fingerprint?: object;

	constructor(private address: string) {
		const api = this._api;
		const instance = this;

		api.interceptors.response.use((response) => {
			return response
		}, async function (error) {
			const originalRequest = error.config;

			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				const response = await instance.refreshToken();
				originalRequest.headers.Authorization = `Bearer ${useCookies().cookies.get("Authorization")}`;
				return api(originalRequest);
			}

			return Promise.reject(error);
		});
	}

	private async refreshToken(): Promise<any> {
		const refreshToken = useCookies().cookies.get("RefreshToken");
		const fingerprint = await this.getFingerprint();

		const request: RefreshTokenRequest = {
			AccountType: refreshToken ? "REFRESH" : "ANONYMOUS",
			RefreshToken: refreshToken,
			UserName: useLocalStorage().getUsername() || "Guest",
			SharedFingerprint: {
				WebFingerprint: fingerprint,
			},
		};

		const response = await this.executeRequestWithBaseUrl<ApiSignInResponse>(
			import.meta.env.VITE_API_URL,
			"api/account/refresh",
			"POST",
			request,
			undefined,
			undefined,
			true
		);

		if (!response.data.Error) {
			console.info("Token refreshed");
		}
	}

	private async getFingerprint(): Promise<object> {
		if (!this.fingerprint) {
			const fingerprintResult = await this.fingerprintPromise;
			this.fingerprint = await fingerprintResult.get();
		}

		return this.fingerprint;
	}

	public executeRequest<T>(
		path: string,
		method: Method,
		body?: any,
		headers?: any,
		parameters?: any,
		withCredentials: boolean = false
	) {
		return this.executeRequestWithBaseUrl<T>(undefined, path, method, body, headers, parameters, withCredentials);
	}

	public executeRequestWithBaseUrl<T>(
		url: string | undefined,
		path: string,
		method: Method,
		body?: any,
		headers?: any,
		parameters?: any,
		withCredentials: boolean = false
	) {
		return this.execute(new APIServiceRequest<T>({
			url: url,
			path: path,
			method: method,
			body: body,
			headers: headers,
			parameters: parameters,
			withCredentials: withCredentials
		}));
	}

	public execute<T>(request: APIServiceRequest<T>): Promise<AxiosResponse<T>> {
		//let responsePromise: Promise<AxiosResponse<T>> = axios.request<T>({

		let baseUrl = this.address;

		if (request.url) {
			baseUrl = request.url;
		}

		let responsePromise: Promise<AxiosResponse<T>> = this._api({
			baseURL: baseUrl,
			url: request.path,
			method: request.method,
			data: request.body,
			params: request.parameters,
			withCredentials: request.withCredentials, // TODO: Review if this is needed once cookie issues are resolved.
			headers: Object.assign(
				{
					"Content-Type": "application/json",
					"X-Requested-With": "XMLHttpRequest",
					Bearer: localStorage.getItem("token"),
				},
				request.headers ?? {}
			),
		});

		if (request.showErrorMessagePopUp) {
			responsePromise = responsePromise.then(async (resolved) => {
				const responseData = resolved.data as any;

				if (responseData.Error || responseData.Message?.Status === false) {
					useEventBus().emitEvent("loading-complete");

					await Swal.fire({
						title: "Error",
						text: responseData.Message?.Reason ?? "An unexpected error occurred.",
						confirmButtonText: "Close"
					});

					return resolved;
				} else {
					return resolved;
				}
			});
		}

		return responsePromise;
	}
}

export class APIServiceRequest<T> {
	public url?: string;
	public path: string;
	public method: Method;
	public body?: any;
	public headers?: any;
	public parameters?: any;
	public withCredentials: boolean = false;
	public showErrorMessagePopUp: boolean = false;

	constructor(init: Partial<APIServiceRequest<T>>) {
		if (!init.path) {
			throw "path is required.";
		}

		this.url = init.url;
		this.path = init.path;

		if (!init.method) {
			throw "method is required.";
		}

		this.method = init.method;
		Object.assign(this, init);
	}
}