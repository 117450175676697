﻿import type { ChannelDetails } from "@/classes/ChannelService";
import type { SelectedDevice } from "@/classes/SelectedDevice";
import type RoomSipNumber from "@liveswitch/sdk/dist/api/models/RoomSipNumber";
import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";
import { reactive, ref } from "vue";
import useHelpers from "./useHelpers";

const AudioInput = "audio-input";
const AudioOutput = "audio-output";
const VideoInput = "video-input";
const AudioEnabled = "audio-enabled";
const ChannelKey = "ChannelKey";
const Username = "Username";
const UserAccountId = "UserAccountId";
let appInsights: ApplicationInsights;

export function configureLocalStorage(insights: ApplicationInsights) {
	appInsights = insights;
};

export const videoStatsEnabled = ref(import.meta.env.VITE_VIDEO_STATS_ENABLED === "true");
export const persistenAttendeeEnabled = ref(import.meta.env.VITE_LS2_PERSISTENT_ATTENDEE === "true");
export const blurEnabled = ref(false);

export default function useLocalStorage() {
	return {
		getAudioInput() {
			return JSON.parse(localStorage.getItem(AudioInput) ?? "null") as SelectedDevice | null;
		},
		setAudioInput(input: SelectedDevice) {
			localStorage.setItem(AudioInput, JSON.stringify(input));
		},
		getChannel(): ChannelDetails | undefined {
			const json = localStorage.getItem("Channel");
			let channel: ChannelDetails | undefined = undefined;

			if (json) {
				try {
					channel = JSON.parse(json) as ChannelDetails;
				} catch (ex: any) {
					appInsights.trackException({
						exception: ex,
						id: "LocalStorageError",
						severityLevel: SeverityLevel.Critical
					}, useHelpers().getLoggingProperties("LocalStorageError", "Error retrieving 'Channel' from localStorage"));
				}
			}

			return channel;
		},
		getChannelKey(): string | null {
			return localStorage.getItem(ChannelKey);
		},
		setChannelKey(channelKey: string | undefined) {
			if (channelKey) {
				localStorage.setItem(ChannelKey, channelKey);
			}
		},
		getUsername(): string | null {
			return localStorage.getItem(Username);
		},
		getUserAccountId(): string | null {
			return localStorage.getItem(UserAccountId);
		},
		getDialInNumbers(key: string): RoomSipNumber[] {
			const json = localStorage.getItem(`DialInNumbers_${key}`);

			if (json) {
				try {
					const numbers = JSON.parse(json);
					return numbers;
				} catch (err) {
					console.error("Error parsing dial in numbers", err);
				}
			}

			return [];
		},
		setDialInNumbers(key: string, numbers: RoomSipNumber[] | undefined) {
			if (numbers && numbers.length > 0) {
				const json = JSON.stringify(numbers);
				localStorage.setItem(`DialInNumbers_${key}`, json);
			}
		}
	};
}
