import { computed, watch } from "vue";
import useEventBus from "@/composables/useEventBus";
import type MeetingHandler from "@/classes/MeetingHandler";

const recordingStartedMessage = "Recording has been started.";
const recordingStoppedMessage = "Recording has been ended.";
const recordingStoppedHostMessage = "Recording has been ended and is being processed.<br> Depending on the length of the recording, <br>it may take a few minutes or up to a few hours to appear in your account.";

export function setupRecording(meetingHandler: MeetingHandler) {
	const isRecording = computed(() => meetingHandler.isRecording);
	const role = computed(() => meetingHandler.localAttendee.role);

	watch(isRecording, (newValue) => {
		if (newValue) {
			//eventBus.toast("Recording has been started", 3000);
			useEventBus().navAlert(recordingStartedMessage, 5000);
		} else {
			useEventBus().navAlert(
				role.value == "HOST" ? recordingStoppedHostMessage : recordingStoppedMessage,
				5000
			);
		}
	});
}
