﻿import User from "./User";

export default class TestMedia {
	public readonly stream: MediaStream;
	public readonly user: User;

	constructor(public readonly id: string, displayName: string) {
		this.stream = new MediaStream();
		this.user = new User(`user-${id}`, displayName);
	}
}
