import { EventOwner, EventOwnerAsync, UserMedia } from "@liveswitch/sdk";

export default class LiveSwitchUserMedia extends UserMedia {
    private _streamChanged: Function;
    private _localStream: MediaStream;

    public constructor(audio: boolean, video: boolean) {
        super(audio, video);
    }

    public get streamChanged(): Function {
        return this._streamChanged;
    }

    public set streamChanged(callback: () => Promise<void>) {
        this._streamChanged = callback;
    }

    protected async getStream(constraints: MediaStreamConstraints) {
        const videoDeviceId = super.requestedVideoDeviceId; // (constraints.video as MediaTrackConstraints)?.deviceId;
        const audioDeviceId = super.requestedAudioDeviceId; //(constraints.audio as MediaTrackConstraints)?.deviceId;

        if ((constraints.video && constraints.audio) || !constraints.audio) {
            constraints.video = {
                deviceId: {
                    ideal: videoDeviceId
                },
                width: {
                    ideal: 1280,
                    max: 1920,
                    min: 640
                },
                height: {
                    ideal: 720,
                    max: 1080,
                    min: 480
                },
                frameRate: {
                    ideal: 25
                }
            } as MediaTrackConstraintSet;
        }

        const stream = await super.getStream(constraints);
        this._localStream = stream;
        return stream;
    }

    public async setVideoDevice(deviceId?: string | undefined, required?: boolean | undefined): Promise<void> {
        await super.setVideoDevice(deviceId, required);

        if (this._streamChanged) {
            await this._streamChanged();
        }
    }

    public async setAudioDevice(deviceId?: string | undefined, required?: boolean | undefined): Promise<void> {
        await super.setAudioDevice(deviceId, required);
        if (this._streamChanged) {
            await this._streamChanged();
        }
    }

    public async stop(): Promise<void> {
        this._localStream?.getTracks().forEach((track) => {
            track.stop();
        });
        await super.stop();
        this._localStream = null;
    }
}