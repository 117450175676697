<template>
	<!-- Modal -->
	<div
		class="modal fade"
		:class="{ show: modalOpen }"
		tabindex="-1"
		role="dialog"
		aria-labelledby="exampleModalLabel"
		aria-hidden="true"
		id="bot-modal"
		@click="componentClick">
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Hello!</h5>
					<i class="fal fa-times close" @click="useEventBus().emitEvent('modal-close', modalId)"></i>
				</div>
				<div class="modal-body">
                    <div class="row mt-2">
                        <div class="col-3" style="padding:0">
                            <img src="../assets/img/larry-the-lab.png" style="width:100%;padding:0 12px;border-bottom:1px solid #ccc" />

                        </div>
                        <div class="col-9">
                            <p>
                                I'm Larry, and I'm here to help you get the most of your LiveSwitch meeting!
                            </p>
                            <p>
                                First, select the category of report you want.
                            </p>
                            <p>
                            <Multiselect
                                
                                class="input-invite"
                                v-model="selectedCategory"
                                mode="single"
                                valueProp="id"
                                label="name"
                                placeholder="Category"
                                :autocomplete="'off'"
                                :close-on-select="true"
                                :clearOnBlur="false"
                                :searchable="false"
                                :create-option="false"
                                :noOptionsText="''"
                                :noResultsText="''"
                                :options="categories"
                                @select="categorySelected" />
                            </p>
                            <p>
                                Now select what you want me to do. Choose all that apply.
                            </p>
                            <p>
                                <Multiselect
                                    id="bot-templates"
                                    class="input-invite"
                                    v-model="selectedTemplates"
                                    :disabled="!selectedCategory"
                                    mode="tags"
                                    valueProp="id"
                                    label="name"
                                    placeholder="Action"
                                    :autocomplete="'off'"
                                    :loading="loading"
                                    :close-on-select="false"
                                    :clearOnBlur="false"
                                    :searchable="true"
                                    :create-option="false"
                                    :noOptionsText="''"
                                    :noResultsText="''"
                                    :options="templates"
                                    >
                                    <template v-slot:option="{ option }">
                                        <div style="display:flex;flex-direction:column">
                                            <div><b>{{ option.name }}</b></div>
                                            <div><span style="font-style:italic">{{ option.description }}</span></div>
                                        </div>
                                    </template>
                                </Multiselect>
                            </p>
                            
                        </div>
                    </div>
                    <div class="row mt-2">
                        
                        <div class="col-12" style="display: flex;justify-content: flex-end;">
                            <button v-if="props.meetingHandler.botConnected || props.meetingHandler.botConnecting"
                                type="submit"
                                class="btn btn-error"
                                style="background-color:#ff715b;margin-right:16px;color:white" 
                                @click="stop">
                                Stop Larry
                            </button>
                            <button
                                type="submit"
                                :disabled="!isEnabled()"
                                class="btn btn-primary"
                                @click="save">
                                {{ props.meetingHandler.botConnected || props.meetingHandler.botConnecting ? "Update Larry" : "Invite Larry"}}
                            </button>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>

    import type MeetingHandler from "@/classes/MeetingHandler";
	import useEventBus from "@/composables/useEventBus";
	import { onMounted, ref, type PropType } from "vue";
    import Multiselect from "@vueform/multiselect";
    import useHelpers from "@/composables/useHelpers";

	const modalId: string = "bot";
	const modalOpen = ref(false);

    const selectedCategory: string = ref('')
    const selectedTemplates = ref([])
    let loading = ref(false)

    let categories = ref([])
    let templates = ref([])

    const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
	});

    function isEnabled(){
        return selectedCategory.value && selectedTemplates.value.length > 0
    }
    
    async function categorySelected(categoryId:string){
        
        loading.value = true
        try{
            // load the templates for this category
            let remoteTemplates = await props.meetingHandler.getBotTemplates(categoryId);
            templates.value.splice(0, templates.value.length)
            for(let t of remoteTemplates){
                templates.value.push(t);
            }
            selectedTemplates.value.splice(0, selectedTemplates.value.length)
        }catch(err){
            console.error(err)
        }finally{
            loading.value = false
        }
    };

    async function stop(){
        useEventBus().emitEvent("bot-stop", {
            selectedCategory,
            selectedTemplates
        });
        useEventBus().emitEvent("modal-close", modalId);
        await props.meetingHandler.removeLiveSwitchBotAsync();
    }

    async function save(){
        // TODO: actually save...
        useEventBus().emitEvent("bot-save", {
            selectedCategory,
            selectedTemplates
        });
        useEventBus().emitEvent("modal-close", modalId);

        const categoryId = selectedCategory.value
        const templateIds = selectedTemplates.value.slice(0)
        await props.meetingHandler.addOrUpdateLiveSwitchBotAsync(categoryId, templateIds);

        /*if (props.meetingHandler.botConnected) {
			await channelService.removeLiveSwitchBotAsync(props.meetingHandler.meeting.roomKey);
		} else {
			await channelService.callLiveSwitchBotAsync(props.meetingHandler.meeting.roomKey);
		}*/
    }

	
	onMounted(async () => {
		useEventBus().onEvent("bot-open", () => {
			modalOpen.value = true;
		});

		useEventBus().onEvent("modal-close", (id: string) => {
			if (id == modalId) {
				modalOpen.value = false;
				console.log("bot closed");
			}
		});

        // load all categories
        categories = await props.meetingHandler.getBotCategories()

        // any pre-selected values
        const selectedCategoryId = await props.meetingHandler.getSelectedCategoryId()
        const selectedTemplateIds = await props.meetingHandler.getSelectedTemplateIds()

        if(selectedCategoryId){
            // load the selected category
            //alert(selectedCategoryId)
            await categorySelected(selectedCategoryId)
            selectedCategory.value = selectedCategoryId
        }
        
        // select any values in the template list now that it's populated
        if(selectedTemplateIds?.length){
            selectedTemplates.value.splice(0, selectedTemplates.value.length)
            for(let t of selectedTemplateIds){
                console.log(t)
                selectedTemplates.value.push(t)
            }
        }
	});

	function componentClick(event: MouseEvent) {
        return // for now we bail out
		if ((event.target as HTMLElement)?.tagName !== "BUTTON") {
			if ((event.target as HTMLDivElement)?.classList.contains("fade")) {
				useEventBus().emitEvent("modal-close", modalId);
			}
		}
	}
</script>

<style scoped>
	.multiselect .is-disabled{
        display: none;
    }
	.dropdown-menu {
		margin-left: -2px;
		width: calc(100% - 20px);
	}

	.show > .btn-secondary.dropdown-toggle {
		color: #323b4b;
		background-color: #ffd279;
		border-color: #ffd279;
	}

	#bot-modal {
		align-items: flex-start !important;
	}

	#bot-modal .modal-dialog {
		width: 40%;
        color:black;
	}

	@media (min-width: 320px) and (max-width: 767px) {
		#bot-modal .modal-dialog {
			width: 100%;
		}

		#bot-modal .modal-dialog .modal-content {
			background-color: #343b4a;
			color: white;
		}

		#bot-modal .modal-dialog .modal-content .modal-title {
			color: white;
		}

		#bot-modal .modal-dialog .close {
			color: white;
			opacity: 1;
		}

		#bot-modal {
			align-items: flex-end !important;
		}
	}
</style>
