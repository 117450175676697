import { UserMedia } from "@liveswitch/sdk";
import type LiveSwitchUserMedia from "./LiveSwitchUserMedia";

export default class CanvasUserMedia extends UserMedia {
    private readonly _originalUserMedia: LiveSwitchUserMedia;
    private _canvasStream: MediaStream;
    private _started: boolean = false;

    public constructor(audio: boolean, video: boolean, canvasStream: MediaStream, originalUserMedia: LiveSwitchUserMedia) {
        super(audio, video);
        this._canvasStream = canvasStream;
        this._originalUserMedia = originalUserMedia;
        this.initializeStreamHandlers();
    }

    protected async getStream(constraints: MediaStreamConstraints) {
        const stream: MediaStream = new MediaStream();
        stream.addTrack(this._originalUserMedia?.stream?.getAudioTracks()[0]);
        stream.addTrack(this._canvasStream.getVideoTracks()[0]);
        return stream;
    }

    public async setVideoDevice(deviceId?: string | undefined, required?: boolean | undefined): Promise<void> {
        await this._originalUserMedia.setVideoDevice(deviceId, required);
    }

    public async setAudioDevice(deviceId?: string | undefined, required?: boolean | undefined): Promise<void> {
        await this._originalUserMedia.setAudioDevice(deviceId, required);
    }

    public get requestedVideoDeviceId(): string {
        return this._originalUserMedia.requestedVideoDeviceId;
    }

    public get requestedAudioDeviceId(): string {
        return this._originalUserMedia.requestedAudioDeviceId;
    }

    public get audioDeviceId(): string {
        return this._originalUserMedia.audioDeviceId;
    }

    public get videoDeviceId(): string {
        return this._originalUserMedia.videoDeviceId;
    }

    public get isStarted(): boolean {
        return this._started;
    }

    public set isStarted(started: boolean) {
        this._started = started;
    }

    public async stop(): Promise<void> {
        await this.audioTrack.stop();
        await this.videoTrack.stop();
        await this._originalUserMedia.stop();

        if (this._canvasStream) {
            this._canvasStream.getTracks().forEach((track) => {
                track.stop();
            })

            this._canvasStream = null;
        }
    }

    private initializeStreamHandlers() {
        this.videoTrack.reload.prototype = () => { console.log('reloading track') };
    }
}