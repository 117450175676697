<template>
	<transition name="fade">
		<div class="custom-modal modal show fade" tabindex="-1" aria-hidden="true" v-show="show">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-body">
						<!-- <div class="d-flex justify-content-end mb-2">
							<i class="fal fa-times close" @click="close"></i>
						</div> -->
						<div id="main-modal-title" class="main-modal-title-centered order-2">
							Camera and microphone permissions required.
						</div>
						<div>
							LiveSwitch Video requires access to your camera and microphone to join a meeting. Please
							enable your camera and microphone permissions.
						</div>

						<div class="card audio-section">
							<div>
								<h3>
									Microphone Permissions
									<i
										:class="isAudioAvailable ? 'icon-audio-mic' : 'icon-audio-mic-slash'"
										:style="isAudioAvailable ? 'color:green;' : 'color:red;'" />
								</h3>
							</div>
							<div v-if="!isAudioAvailable">
								<button class="btn btn-primary btn-join" @click="checkAudioPermissions">
									Test Microphone Permissions
								</button>
								<div class="accordion" id="microphoneAccordion">
									<div class="accordion-item">
										<h2 class="accordion-header" id="heading-microphone">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#accordion-microphone"
												aria-expanded="false"
												aria-controls="accordion-microphone">
												{{ microphoneTitle }}
											</button>
										</h2>
										<div
											id="accordion-microphone"
											class="accordion-collapse collapsed collapse"
											aria-labelledby="heading-microphone"
											data-bs-parent="#microphoneAccordion">
											<div v-for="step in microphoneSteps" :key="step.title" class="step">
												<h5>{{ step.title }}</h5>
												<p>{{ step.instructions }}</p>
												<img v-if="step.image" :src="step.image" />
												<button
													v-if="step.button"
													class="btn btn-primary btn-join"
													@click="checkAudioPermissions">
													Test Microphone Permissions
												</button>
											</div>
										</div>
									</div>
									<div v-if="showSecondary" class="accordion-item">
										<h2 class="accordion-header" id="heading-microphone-secondary">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#accordion-microphone-secondary"
												aria-expanded="false"
												aria-controls="accordion-microphone-secondary">
												{{ microphoneSecondaryTitle }}
											</button>
										</h2>
										<div
											id="accordion-microphone-secondary"
											class="accordion-collapse collapsed collapse"
											aria-labelledby="heading-microphone-secondary"
											data-bs-parent="#microphoneAccordion">
											<div
												v-for="step in microphoneSecondarySteps"
												:key="step.title"
												class="step">
												<h5>{{ step.title }}</h5>
												<p>{{ step.instructions }}</p>
												<img v-if="step.image" :src="step.image" />
												<button
													v-if="step.button"
													class="btn btn-primary btn-join"
													@click="checkAudioPermissions">
													Test Microphone Permissions
												</button>
											</div>
										</div>
									</div>
									<div v-if="showOS" class="accordion-item">
										<h2 class="accordion-header" id="heading-microphone-os">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#accordion-microphone-os"
												aria-expanded="false"
												aria-controls="accordion-microphone-os">
												{{ osMicrophoneTitle }}
											</button>
										</h2>
										<div
											id="accordion-microphone-os"
											class="accordion-collapse collapsed collapse"
											aria-labelledby="heading-microphone-os"
											data-bs-parent="#microphoneAccordion">
											<div v-for="step in osMicrophoneSteps" :key="step.title" class="step">
												<h5>{{ step.title }}</h5>
												<p>{{ step.instructions }}</p>
												<img v-if="step.image" :src="step.image" />
												<button
													v-if="step.button"
													class="btn btn-primary btn-join"
													@click="checkAudioPermissions">
													Test Microphone Permissions
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else>You have microphone permissions granted.</div>
						</div>
						<div class="card video-section">
							<div>
								<h3>
									Camera Permissions
									<i
										:class="isVideoAvailable ? 'icon-video' : 'icon-video-slash'"
										:style="isVideoAvailable ? 'color:green;' : 'color:red;'" />
								</h3>
							</div>
							<div v-if="!isVideoAvailable">
								<button class="btn btn-primary btn-join" @click="checkVideoPermissions">
									Test Camera Permissions
								</button>
								<div class="accordion" id="cameraAccordion">
									<div class="accordion-item">
										<h2 class="accordion-header" id="heading-camera">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#accordion-camera"
												aria-expanded="false"
												aria-controls="accordion-camera">
												{{ cameraTitle }}
											</button>
										</h2>
										<div
											id="accordion-camera"
											class="accordion-collapse collapsed collapse"
											aria-labelledby="heading-camera"
											data-bs-parent="#cameraAccordion">
											<div v-for="step in cameraSteps" :key="step.title" class="step">
												<h5>{{ step.title }}</h5>
												<p>{{ step.instructions }}</p>
												<img v-if="step.image" :src="step.image" />
												<button
													v-if="step.button"
													class="btn btn-primary btn-join"
													@click="checkVideoPermissions">
													Test Camera Permissions
												</button>
											</div>
										</div>
									</div>
									<div v-if="showSecondary" class="accordion-item">
										<h2 class="accordion-header" id="heading-camera-secondary">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#accordion-camera-secondary"
												aria-expanded="false"
												aria-controls="accordion-camera-secondary">
												{{ cameraSecondaryTitle }}
											</button>
										</h2>
										<div
											id="accordion-camera-secondary"
											class="accordion-collapse collapsed collapse"
											aria-labelledby="heading-camera-secondary"
											data-bs-parent="#cameraAccordion">
											<div v-for="step in cameraSecondarySteps" :key="step.title" class="step">
												<h5>{{ step.title }}</h5>
												<p>{{ step.instructions }}</p>
												<img v-if="step.image" :src="step.image" />
												<button
													v-if="step.button"
													class="btn btn-primary btn-join"
													@click="checkVideoPermissions">
													Test Camera Permissions
												</button>
											</div>
										</div>
									</div>
									<div v-if="showOS" class="accordion-item">
										<h2 class="accordion-header" id="heading-camera-os">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#accordion-camera-os"
												aria-expanded="false"
												aria-controls="accordion-camera-os">
												{{ osCameraTitle }}
											</button>
										</h2>
										<div
											id="accordion-camera-os"
											class="accordion-collapse collapsed collapse"
											aria-labelledby="heading-camera-os"
											data-bs-parent="#cameraAccordion">
											<div v-for="step in osCameraSteps" :key="step.title" class="step">
												<h5>{{ step.title }}</h5>
												<p>{{ step.instructions }}</p>
												<img v-if="step.image" :src="step.image" />
												<button
													v-if="step.button"
													class="btn btn-primary btn-join"
													@click="checkVideoPermissions">
													Test Camera Permissions
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else>You have camera permissions granted.</div>
						</div>
						<div class="card" v-if="isAudioAvailable && isVideoAvailable">
							<h5>Permissions Granted!</h5>
							<div>
								<div>
									You have granted the access to your microphone and camera. You can now join the
									call!
								</div>
								<button class="btn btn-primary btn-join" @click="joinCall">Refresh Page</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script lang="ts" setup>
	import { ref, onMounted } from "vue";
	import useHelpers from "@/composables/useHelpers";
	defineProps(["show", "body", "actionText"]);
	const emits = defineEmits(["execute", "update:show"]);

	import chromeStep1 from "@/assets/img/permissions/chrome-step-1.png";
	import chromeMicStep2 from "@/assets/img/permissions/chrome-mic-step-2.png";
	import chromeMicStep3 from "@/assets/img/permissions/chrome-mic-step-3.png";
	import chromeCamStep2 from "@/assets/img/permissions/chrome-camera-step-2.png";
	import chromeCamStep3 from "@/assets/img/permissions/chrome-camera-step-3.png";

	import firefoxStep1 from "@/assets/img/permissions/firefox-step-1.png";
	import firefoxStep2 from "@/assets/img/permissions/firefox-step-2.png";
	import firefoxStep3 from "@/assets/img/permissions/firefox-step-3.png";
	import firefoxMicStep4 from "@/assets/img/permissions/firefox-mic-step-4.png";
	import firefoxMicStep5 from "@/assets/img/permissions/firefox-mic-step-5.png";
	import firefoxMicStep6 from "@/assets/img/permissions/firefox-mic-step-6.png";
	import firefoxMicStep7 from "@/assets/img/permissions/firefox-mic-step-7.png";
	import firefoxCamStep4 from "@/assets/img/permissions/firefox-camera-step-4.png";
	import firefoxCamStep5 from "@/assets/img/permissions/firefox-camera-step-5.png";
	import firefoxCamStep6 from "@/assets/img/permissions/firefox-camera-step-6.png";
	import firefoxCamStep7 from "@/assets/img/permissions/firefox-camera-step-7.png";

	import firefoxSecondaryStep1 from "@/assets/img/permissions/firefox-temp-step-1.png";
	import firefoxSecondaryMicStep2 from "@/assets/img/permissions/firefox-temp-mic-step-2.png";
	import firefoxSecondaryMicStep4 from "@/assets/img/permissions/firefox-temp-mic-step-4.png";
	import firefoxSecondaryCamStep2 from "@/assets/img/permissions/firefox-temp-camera-step-2.png";
	import firefoxSecondaryCamStep4 from "@/assets/img/permissions/firefox-temp-camera-step-4.png";

	import macosStep1 from "@/assets/img/permissions/macos-step-1.png";
	import macosStep2 from "@/assets/img/permissions/macos-step-2.png";
	import macosStep3 from "@/assets/img/permissions/macos-step-3.png";
	import macosMicStep4 from "@/assets/img/permissions/macos-mic-step-4.png";
	import macosFirefoxMicStep5 from "@/assets/img/permissions/macos-mic-firefox-step-5.png";
	import macosFirefoxMicStep6 from "@/assets/img/permissions/macos-mic-firefox-step-6.png";
	import macosChromeMicStep5 from "@/assets/img/permissions/macos-mic-chrome-step-5.png";
	import macosChromeMicStep6 from "@/assets/img/permissions/macos-mic-chrome-step-6.png";
	import macosCamStep4 from "@/assets/img/permissions/macos-camera-step-4.png";
	import macosFirefoxCamStep5 from "@/assets/img/permissions/macos-camera-firefox-step-5.png";
	import macosFirefoxCamStep6 from "@/assets/img/permissions/macos-camera-firefox-step-6.png";
	import macosChromeCamStep5 from "@/assets/img/permissions/macos-camera-chrome-step-5.png";
	import macosChromeCamStep6 from "@/assets/img/permissions/macos-camera-chrome-step-6.png";

	import mobileChromeStep1 from "@/assets/img/permissions/mobile-chrome-step-1.png";
	import mobileChromeStep2 from "@/assets/img/permissions/mobile-chrome-step-2.png";
	import mobileChromeMicStep3 from "@/assets/img/permissions/mobile-chrome-mic-step-3.png";
	import mobileChromeCamStep3 from "@/assets/img/permissions/mobile-chrome-camera-step-3.png";

	import mobileChromeIosStep2 from "@/assets/img/permissions/mobile-chrome-ios-step-2.png";
	import mobileChromeIosMicStep3 from "@/assets/img/permissions/mobile-chrome-ios-mic-step-3.png";
	import mobileChromeIosCamStep3 from "@/assets/img/permissions/mobile-chrome-ios-camera-step-3.png";

	import mobileSafariStep1 from "@/assets/img/permissions/mobile-safari-browser-step-1.png";
	import mobileSafariStep2 from "@/assets/img/permissions/mobile-safari-browser-step-2.png";
	import mobileSafariMicStep3 from "@/assets/img/permissions/mobile-safari-browser-mic-step-3.png";
	import mobileSafariMicStep4 from "@/assets/img/permissions/mobile-safari-browser-mic-step-4.png";
	import mobileSafariStep5 from "@/assets/img/permissions/mobile-safari-browser-step-5.png";
	import mobileSafariCamStep3 from "@/assets/img/permissions/mobile-safari-browser-camera-step-3.png";
	import mobileSafariCamStep4 from "@/assets/img/permissions/mobile-safari-browser-camera-step-4.png";
	import mobileSafariSecondaryStep1 from "@/assets/img/permissions/mobile-safari-phone-01.png";
	import mobileSafariSecondaryStep2 from "@/assets/img/permissions/mobile-safari-phone-02.png";
	import mobileSafariSecondaryMicStep3 from "@/assets/img/permissions/mobile-safari-phone-mic-03.png";
	import mobileSafariSecondaryMicStep4 from "@/assets/img/permissions/mobile-safari-phone-mic-04.png";
	import mobileSafariSecondaryCamStep3 from "@/assets/img/permissions/mobile-safari-phone-camera-03.png";
	import mobileSafariSecondaryCamStep4 from "@/assets/img/permissions/mobile-safari-phone-camera-04.png";

	import safariStep1 from "@/assets/img/permissions/safari-step-1.png";
	import safariStep2 from "@/assets/img/permissions/safari-step-2.png";
	import safariStep3 from "@/assets/img/permissions/safari-step-3.png";
	import safariMicStep4 from "@/assets/img/permissions/safari-mic-step-4.png";
	import safariMicStep5 from "@/assets/img/permissions/safari-mic-step-5.png";
	import safariMicStep6 from "@/assets/img/permissions/safari-mic-step-6.png";
	import safariCamStep4 from "@/assets/img/permissions/safari-camera-step-4.png";
	import safariCamStep5 from "@/assets/img/permissions/safari-camera-step-5.png";
	import safariCamStep6 from "@/assets/img/permissions/safari-camera-step-6.png";

	import windowsStep1 from "@/assets/img/permissions/windows-step-1.png";
	import windowsStep2 from "@/assets/img/permissions/windows-step-2.png";
	import windowsStep3 from "@/assets/img/permissions/windows-step-3.png";
	import windowsMicStep4 from "@/assets/img/permissions/windows-mic-step-4.png";
	import windowsMicStep5 from "@/assets/img/permissions/windows-mic-step-5.png";
	import windowsMicStep6 from "@/assets/img/permissions/windows-mic-step-6.png";
	import windowsMicStep7 from "@/assets/img/permissions/windows-mic-step-7.png";
	import windowsMicStep8 from "@/assets/img/permissions/windows-mic-step-8.png";
	import windowsCamStep4 from "@/assets/img/permissions/windows-camera-step-4.png";
	import windowsCamStep5 from "@/assets/img/permissions/windows-camera-step-5.png";
	import windowsCamStep6 from "@/assets/img/permissions/windows-camera-step-6.png";
	import windowsCamStep7 from "@/assets/img/permissions/windows-camera-step-7.png";
	import windowsCamStep8 from "@/assets/img/permissions/windows-camera-step-8.png";

	const showSecondary = ref(false);
	const isAudioAvailable = ref(false);
	const isVideoAvailable = ref(false);
	const browser = ref("");
	const cameraSteps = ref([]);
	const microphoneSteps = ref([]);
	const cameraSecondarySteps = ref([]);
	const microphoneSecondarySteps = ref([]);
	const cameraTitle = ref("");
	const microphoneTitle = ref("");
	const cameraSecondaryTitle = ref("");
	const microphoneSecondaryTitle = ref("");

	const showOS = ref(false);
	const osCameraSteps = ref([]);
	const osMicrophoneSteps = ref([]);
	const osCameraTitle = ref("");
	const osMicrophoneTitle = ref("");

	const allSteps = ref({
		chrome: {
			"microphone-title": "How to grant microphone permissions.",
			microphone: [
				{
					id: "chrome-mic-1",
					title: "Step 1: Open Site Information",
					image: chromeStep1,
					instructions: "Click on the lock icon in the address bar.",
				},
				{
					id: "chrome-mic-2",
					title: "Step 2: Enable Microphone",
					image: chromeMicStep2,
					instructions: "Click on the microphone toggle.",
				},
				{
					id: "chrome-mic-3",
					title: "Step 3: Close Site Information",
					image: chromeMicStep3,
					instructions: "Click the close button.",
				},
				{
					id: "chrome-mic-4",
					title: "Step 4: Test Microphone permissions",
					button: true,
					instructions: "Click the Test Microphone Permissions button.",
				},
			],
			"camera-title": "How to grant camera permissions.",
			camera: [
				{
					id: "chrome-camera-1",
					title: "Step 1: Open Site Information",
					image: chromeStep1,
					instructions: "Click on the lock icon in the address bar.",
				},
				{
					id: "chrome-camera-2",
					title: "Step 2: Enable Camera",
					image: chromeCamStep2,
					instructions: "Click on the camera toggle.",
				},
				{
					id: "chrome-camera-3",
					title: "Step 3: Close Site Information",
					image: chromeCamStep3,
					instructions: "Click the close button.",
				},
				{
					id: "chrome-camera-4",
					title: "Step 4: Test Camera permissions",
					button: true,
					instructions: "Click the Test Microphone Permissions button.",
				},
			],
		},
		firefox: {
			"microphone-title": "How to grant microphone permissions.",
			microphone: [
				{
					id: "firefox-mic-1",
					title: "Step 1: Open Browser Menu",
					image: firefoxStep1,
					instructions: "Click on the browser menu.",
				},
				{
					id: "firefox-mic-2",
					title: "Step 2: Open Settings",
					image: firefoxStep2,
					instructions: "Click settings.",
				},
				{
					id: "firefox-mic-3",
					title: "Step 3: Open Privacy & Security",
					image: firefoxStep3,
					instructions: "Click Privacy & Security.",
				},
				{
					id: "firefox-mic-4",
					title: "Step 4: Open Microphone Settings",
					image: firefoxMicStep4,
					instructions: "Scroll down and click Microphone Settings.",
				},
				{
					id: "firefox-mic-5",
					title: "Step 5: Click Status Dropdown",
					image: firefoxMicStep5,
					instructions: "Click and open the status dropdown.",
				},
				{
					id: "firefox-mic-6",
					title: "Step 6: Select Allow",
					image: firefoxMicStep6,
					instructions: "Select Allow in the dropdown.",
				},
				{
					id: "firefox-mic-7",
					title: "Step 7: Save Changes",
					image: firefoxMicStep7,
					instructions: "Click Save Changes!",
				},
				{
					id: "firefox-mic-8",
					title: "Step 8: Test Microphone permissions",
					button: true,
					instructions: "Click the Test Microphone Permissions button.",
				},
			],
			"microphone-secondary-title": "How to update temporary microphone permissions.",
			"microphone-secondary": [
				{
					id: "firefox-mic-secondary-1",
					title: "Step 1: Open Permissions Menu",
					image: firefoxSecondaryStep1,
					instructions: "Click on the microphone icon in the address bar.",
				},
				{
					id: "firefox-mic-secondary-2",
					title: "Step 2: Remove Temporary Permission",
					image: firefoxSecondaryMicStep2,
					instructions: "Click Blocked Temporarily.",
				},
				{
					id: "firefox-mic-secondary-3",
					title: "Step 3: Ask for permissions",
					button: true,
					instructions: "Click the Test Microphone Permissions button.",
				},
				{
					id: "firefox-mic-secondary-4",
					title: "Step 4: Allow Microphone Permissions",
					image: firefoxSecondaryMicStep4,
					instructions: "Click Allow.",
				},
			],
			"camera-title": "How to grant camera permissions.",
			camera: [
				{
					id: "firefox-camera-1",
					title: "Step 1: Open Browser Menu",
					image: firefoxStep1,
					instructions: "Click on the browser menu.",
				},
				{
					id: "firefox-camera-2",
					title: "Step 2: Open Settings",
					image: firefoxStep2,
					instructions: "Click settings.",
				},
				{
					id: "firefox-camera-3",
					title: "Step 3: Open Privacy & Security",
					image: firefoxStep3,
					instructions: "Click Privacy & Security.",
				},
				{
					id: "firefox-camera-4",
					title: "Step 4: Open Camera Settings",
					image: firefoxCamStep4,
					instructions: "Scroll down and click Camera Settings.",
				},
				{
					id: "firefox-camera-5",
					title: "Step 5: Click Status Dropdown",
					image: firefoxCamStep5,
					instructions: "Click and open the status dropdown.",
				},
				{
					id: "firefox-camera-6",
					title: "Step 6: Select Allow",
					image: firefoxCamStep6,
					instructions: "Select Allow in the dropdown.",
				},
				{
					id: "firefox-camera-7",
					title: "Step 7: Save Changes",
					image: firefoxCamStep7,
					instructions: "Click Save Changes!",
				},
				{
					id: "firefox-camera-8",
					title: "Step 8: Test Camera permissions",
					button: true,
					instructions: "Click the Test Camera Permissions button.",
				},
			],
			"camera-secondary-title": "How to update temporary camera permissions.",
			"camera-secondary": [
				{
					id: "firefox-camera-secondary-1",
					title: "Step 1: Open Permissions Menu",
					image: firefoxSecondaryStep1,
					instructions: "Click on the camera icon in the address bar.",
				},
				{
					id: "firefox-camera-secondary-2",
					title: "Step 2: Remove Temporary Permission",
					image: firefoxSecondaryCamStep2,
					instructions: "Click Blocked Temporarily.",
				},
				{
					id: "firefox-camera-secondary-3",
					title: "Step 3: Ask for permissions",
					button: true,
					instructions: "Click the Test Camera Permissions button.",
				},
				{
					id: "firefox-camera-secondary-4",
					title: "Step 4: Allow Camera Permissions",
					image: firefoxSecondaryCamStep4,
					instructions: "Click Allow.",
				},
			],
		},
		safari: {
			"microphone-title": "How to grant microphone permissions.",
			microphone: [
				{
					id: "safari-mic-1",
					title: "Step 1: Open Browser Menu",
					image: safariStep1,
					instructions: "Click on Safari.",
				},
				{
					id: "safari-mic-2",
					title: "Step 2: Open Settings",
					image: safariStep2,
					instructions: "Click settings.",
				},
				{
					id: "safari-mic-3",
					title: "Step 3: Open Websites",
					image: safariStep3,
					instructions: "Click Websites.",
				},
				{
					id: "safari-mic-4",
					title: "Step 4: Open Microphone Settings",
					image: safariMicStep4,
					instructions: "Click Microphone.",
				},
				{
					id: "safari-mic-5",
					title: "Step 5: Click Dropdown",
					image: safariMicStep5,
					instructions: "Click and open the dropdown.",
				},
				{
					id: "safari-mic-6",
					title: "Step 6: Select Allow",
					image: safariMicStep6,
					instructions: "Select Allow in the dropdown.",
				},
				{
					id: "safari-mic-7",
					title: "Step 7: Test Microphone permissions",
					button: true,
					instructions: "Click the Test Microphone Permissions button.",
				},
			],
			"camera-title": "How to grant camera permissions.",
			camera: [
				{
					id: "safari-camera-1",
					title: "Step 1: Open Browser Menu",
					image: safariStep1,
					instructions: "Click on Safari.",
				},
				{
					id: "safari-camera-2",
					title: "Step 2: Open Settings",
					image: safariStep2,
					instructions: "Click settings.",
				},
				{
					id: "safari-camera-3",
					title: "Step 3: Open Websites",
					image: safariStep3,
					instructions: "Click Websites.",
				},
				{
					id: "safari-camera-4",
					title: "Step 4: Open Camera Settings",
					image: safariCamStep4,
					instructions: "Click Camera.",
				},
				{
					id: "safari-camera-5",
					title: "Step 5: Click Status Dropdown",
					image: safariCamStep5,
					instructions: "Click and open the dropdown.",
				},
				{
					id: "safari-camera-6",
					title: "Step 6: Select Allow",
					image: safariCamStep6,
					instructions: "Select Allow in the dropdown.",
				},
				{
					id: "safari-camera-7",
					title: "Step 7: Test Camera permissions",
					button: true,
					instructions: "Click the Test Camera Permissions button.",
				},
			],
		},
		"mobile-chrome": {
			"microphone-title": "How to grant microphone permissions.",
			microphone: [
				{
					id: "mobile-chrome-mic-1",
					title: "Step 1: Open Settings",
					image: mobileChromeStep1,
					instructions: "Click the lock icon or settings icon on the left end of address bar.",
				},
				{
					id: "mobile-chrome-mic-2",
					title: "Step 2: Open Permissions",
					image: mobileChromeStep2,
					instructions: "Click on 'Permissions' in the site information pop-up.",
				},
				{
					id: "mobile-chrome-mic-3",
					title: "Step 3: Enable Microphone",
					image: mobileChromeMicStep3,
					instructions: "Click the option to enable microphone access.",
				},
				{
					id: "mobile-chrome-mic-4",
					title: "Step 4: Test Microphone permissions",
					button: true,
					instructions: "Click the Test Microphone Permissions button.",
				},
			],
			"camera-title": "How to grant camera permissions.",
			camera: [
				{
					id: "mobile-chrome-camera-1",
					title: "Step 1: Open Settings",
					image: mobileChromeStep1,
					instructions: "Click the lock icon or settings icon on the left end of address bar.",
				},
				{
					id: "mobile-chrome-camera-2",
					title: "Step 2: Open Permissions",
					image: mobileChromeStep2,
					instructions: "Click on 'Permissions' in the site information pop-up.",
				},
				{
					id: "mobile-chrome-camera-3",
					title: "Step 3: Enable Camera",
					image: mobileChromeCamStep3,
					instructions: "Click the option to enable camera access.",
				},
				{
					id: "mobile-chrome-camera-4",
					title: "Step 4: Test Camera permissions",
					button: true,
					instructions: "Click the Test Microphone Permissions button.",
				},
			],
		},
		"mobile-chrome-ios": {
			"microphone-title": "How to grant microphone permissions.",
			microphone: [
				{
					id: "mobile-chrome-mic-1",
					title: "Step 1: Open Settings",
					image: mobileSafariSecondaryStep1,
					instructions: "Find and open the settings app on your phones home screen.",
				},
				{
					id: "mobile-chrome-mic-2",
					title: "Step 2: Open Chrome",
					image: mobileChromeIosStep2,
					instructions: "Find Chrome and click it.",
				},
				{
					id: "mobile-chrome-mic-3",
					title: "Step 3: Enable Microphone",
					image: mobileChromeIosMicStep3,
					instructions: "Toggle on the option to enable microphone access.",
				},
				{
					id: "mobile-chrome-mic-4",
					title: "Step 4: Test Microphone permissions",
					button: true,
					instructions: "Return back to Chrome and click the Test Microphone Permissions button.",
				},
			],
			"camera-title": "How to grant camera permissions.",
			camera: [
				{
					id: "mobile-chrome-camera-1",
					title: "Step 1: Open Settings",
					image: mobileSafariSecondaryStep1,
					instructions: "Find and open the settings app on your phones home screen.",
				},
				{
					id: "mobile-chrome-camera-2",
					title: "Step 2: Open Chrome",
					image: mobileChromeIosStep2,
					instructions: "Find Chrome and click it.",
				},
				{
					id: "mobile-chrome-camera-3",
					title: "Step 3: Enable Camera",
					image: mobileChromeIosCamStep3,
					instructions: "Toggle on the option to enable camera access.",
				},
				{
					id: "mobile-chrome-camera-4",
					title: "Step 4: Test Camera permissions",
					button: true,
					instructions: "Return back to Chrome and click the Test Microphone Permissions button.",
				},
			],
		},
		"mobile-safari": {
			"microphone-title": "How to grant microphone permissions in browser.",
			microphone: [
				{
					id: "mobile-safari-mic-1",
					title: "Step 1",
					image: mobileSafariStep1,
					instructions: "Click on the browser menu.",
				},
				{
					id: "mobile-safari-mic-2",
					title: "Step 2",
					image: mobileSafariStep2,
					instructions: "Click website settings.",
				},
				{
					id: "mobile-safari-mic-3",
					title: "Step 3",
					image: mobileSafariMicStep3,
					instructions: "Click on Microphone.",
				},
				{
					id: "mobile-safari-mic-4",
					title: "Step 4",
					image: mobileSafariMicStep4,
					instructions: "Select the Allow option.",
				},
				{
					id: "mobile-safari-mic-5",
					title: "Step 5",
					image: mobileSafariStep5,
					instructions: "Click done!",
				},
				{
					id: "mobile-safari-mic-6",
					title: "Step 6",
					button: true,
					instructions:
						"Click the Test Microphone Permissions button. If permissions are granted correctly, the red microphone icon above will turn green!",
				},
			],
			"camera-title": "How to grant camera permissions in browser.",
			camera: [
				{
					id: "mobile-safari-camera-1",
					title: "Step 1",
					image: mobileSafariStep1,
					instructions: "Click on the browser menu.",
				},
				{
					id: "mobile-safari-camera-2",
					title: "Step 2",
					image: mobileSafariStep2,
					instructions: "Click website settings.",
				},
				{
					id: "mobile-safari-camera-3",
					title: "Step 3",
					image: mobileSafariCamStep3,
					instructions: "Click on Camera.",
				},
				{
					id: "mobile-safari-camera-4",
					title: "Step 4",
					image: mobileSafariCamStep4,
					instructions: "Select the Allow option.",
				},
				{
					id: "mobile-safari-camera-5",
					title: "Step 5",
					image: mobileSafariStep5,
					instructions: "Click done!",
				},
				{
					id: "mobile-safari-camera-6",
					title: "Step 6",
					button: true,
					instructions:
						"Click the Test Camera Permissions button. If permissions are granted correctly, the red camera icon above will turn green!",
				},
			],
			"microphone-secondary-title": "How to grant microphone permissions in phone settings.",
			"microphone-secondary": [
				{
					id: "mobile-safari-mic-1",
					title: "Step 1",
					image: mobileSafariSecondaryStep1,
					instructions: "Find and open the settings app on your phones home screen.",
				},
				{
					id: "mobile-safari-mic-2",
					title: "Step 2",
					image: mobileSafariSecondaryStep2,
					instructions: "Find Safari and click it.",
				},
				{
					id: "mobile-safari-mic-3",
					title: "Step 3",
					image: mobileSafariSecondaryMicStep3,
					instructions: "Click on Microphone.",
				},
				{
					id: "mobile-safari-mic-4",
					title: "Step 4",
					image: mobileSafariSecondaryMicStep4,
					instructions: "Select the Allow option.",
				},
				{
					id: "mobile-safari-mic-5",
					title: "Step 5",
					button: true,
					instructions:
						"Click the Test Microphone Permissions button. If permissions are granted correctly, the red microphone icon above will turn green!",
				},
			],
			"camera-secondary-title": "How to grant camera permissions in phone settings.",
			"camera-secondary": [
				{
					id: "mobile-safari-camera-1",
					title: "Step 1",
					image: mobileSafariSecondaryStep1,
					instructions: "Find and open the settings app on your phones home screen.",
				},
				{
					id: "mobile-safari-camera-2",
					title: "Step 2",
					image: mobileSafariSecondaryStep2,
					instructions: "Find Safari and click it.",
				},
				{
					id: "mobile-safari-camera-3",
					title: "Step 3",
					image: mobileSafariSecondaryCamStep3,
					instructions: "Click on Camera.",
				},
				{
					id: "mobile-safari-camera-4",
					title: "Step 4",
					image: mobileSafariSecondaryCamStep4,
					instructions: "Select the Allow option.",
				},
				{
					id: "mobile-safari-camera-5",
					title: "Step 5",
					button: true,
					instructions:
						"Click the Test Camera Permissions button. If permissions are granted correctly, the red camera icon above will turn green!",
				},
			],
		},
		windows: {
			"microphone-title": "How to grant microphone permissions in Windows.",
			microphone: [
				{
					id: "windows-mic-1",
					title: "Step 1: Open Start Menu",
					image: windowsStep1,
					instructions: "Click on Start menu.",
				},
				{
					id: "windows-mic-2",
					title: "Step 2: Open Settings",
					image: windowsStep2,
					instructions: "Click settings.",
				},
				{
					id: "windows-mic-3",
					title: "Step 3: Open Privacy",
					image: windowsStep3,
					instructions: "Click Privacy.",
				},
				{
					id: "windows-mic-4",
					title: "Step 4: Open Microphone Settings",
					image: windowsMicStep4,
					instructions: "Click Microphone.",
				},
				{
					id: "windows-mic-5",
					title: "Step 5: Allow Microphone access on this device",
					image: windowsMicStep5,
					instructions: "If microphone access for this device is off. Click the change button.",
				},
				{
					id: "windows-mic-6",
					title: "Step 6: Allow Access",
					image: windowsMicStep6,
					instructions: "Turn on access for this device.",
				},
				{
					id: "windows-mic-7",
					title: "Step 7: Allow Apps to access your microphone",
					image: windowsMicStep7,
					instructions: "Turn on access for apps.",
				},
				{
					id: "windows-mic-8",
					title: "Step 8: Allow Desktop Apps to access your microphone",
					image: windowsMicStep8,
					instructions: "Turn on access for desktop apps.",
				},
				{
					id: "windows-mic-9",
					title: "Step 9: Test Microphone permissions",
					button: true,
					instructions: "Click the Test Microphone Permissions button.",
				},
			],
			"camera-title": "How to grant camera permissions in Windows.",
			camera: [
				{
					id: "windows-camera-1",
					title: "Step 1: Open Start Menu",
					image: windowsStep1,
					instructions: "Click on Start menu.",
				},
				{
					id: "windows-camera-2",
					title: "Step 2: Open Settings",
					image: windowsStep2,
					instructions: "Click settings.",
				},
				{
					id: "windows-camera-3",
					title: "Step 3: Open Privacy",
					image: windowsStep3,
					instructions: "Click Privacy.",
				},
				{
					id: "windows-camera-4",
					title: "Step 4: Open Camera Settings",
					image: windowsCamStep4,
					instructions: "Click Camera.",
				},
				{
					id: "windows-camera-5",
					title: "Step 5: Allow Camera access on this device",
					image: windowsCamStep5,
					instructions: "If camera access for this device is off. Click the change button.",
				},
				{
					id: "windows-camera-6",
					title: "Step 6: Allow Access",
					image: windowsCamStep6,
					instructions: "Turn on access for this device.",
				},
				{
					id: "windows-camera-7",
					title: "Step 7: Allow Apps to access your camera",
					image: windowsCamStep7,
					instructions: "Turn on access for apps.",
				},
				{
					id: "windows-camera-8",
					title: "Step 8: Allow Desktop Apps to access your camera",
					image: windowsCamStep8,
					instructions: "Turn on access for desktop apps.",
				},
				{
					id: "windows-camera-9",
					title: "Step 9: Test Camera permissions",
					button: true,
					instructions: "Click the Test Camera Permissions button.",
				},
			],
		},
		"macos-firefox": {
			"microphone-title": "How to grant microphone permissions in MacOS.",
			microphone: [
      {
					id: "macos-firefox-mic-1",
					title: "Step 1: Open Apple Menu",
					image: macosStep1,
					instructions: "Click on Apple icon.",
				},
				{
					id: "macos-firefox-mic-2",
					title: "Step 2: Open System Settings",
					image: macosStep2,
					instructions: "Click System Settings.",
				},
				{
					id: "macos-firefox-mic-3",
					title: "Step 3: Open Privacy & Security",
					image: macosStep3,
					instructions: "Click Privacy & Security.",
				},
				{
					id: "macos-firefox-mic-4",
					title: "Step 4: Open Microphone Settings",
					image: macosMicStep4,
					instructions: "Click Microphone.",
				},
				{
					id: "macos-firefox-mic-5",
					title: "Step 5: Allow Microphone access to Firefox",
					image: macosFirefoxMicStep5,
					instructions: "Turn on microphone access to Firefox.",
				},
				{
					id: "macos-firefox-mic-6",
					title: "Step 6: Reopen Firefox",
					image: macosFirefoxMicStep6,
					instructions: "Click Quit & Reopen to finalize microphone access to Firefox.",
				},
				{
					id: "macos-firefox-mic-7",
					title: "Step 7: Test Microphone permissions",
					button: true,
					instructions: "Click the Test Microphone Permissions button.",
				},
			],
			"camera-title": "How to grant camera permissions in MacOS.",
			camera: [
      {
					id: "macos-firefox-camera-1",
					title: "Step 1: Open Apple Menu",
					image: macosStep1,
					instructions: "Click on Apple icon.",
				},
				{
					id: "macos-firefox-camera-2",
					title: "Step 2: Open System Settings",
					image: macosStep2,
					instructions: "Click System Settings.",
				},
				{
					id: "macos-firefox-camera-3",
					title: "Step 3: Open Privacy & Security",
					image: macosStep3,
					instructions: "Click Privacy & Security.",
				},
				{
					id: "macos-firefox-camera-4",
					title: "Step 4: Open Camera Settings",
					image: macosCamStep4,
					instructions: "Click Camera.",
				},
				{
					id: "macos-firefox-camera-5",
					title: "Step 5: Allow Camera access to Firefox",
					image: macosFirefoxCamStep5,
					instructions: "Turn on camera access to Firefox.",
				},
				{
					id: "macos-firefox-camera-6",
					title: "Step 6: Reopen Firefox",
					image: macosFirefoxCamStep6,
					instructions: "Click Quit & Reopen to finalize camera access to Firefox.",
				},
				{
					id: "macos-firefox-camera-7",
					title: "Step 7: Test Camera permissions",
					button: true,
					instructions: "Click the Test Camera Permissions button.",
				},
			],
		},
		"macos-chrome": {
			"microphone-title": "How to grant microphone permissions in MacOS.",
			microphone: [
				{
					id: "macos-chrome-mic-1",
					title: "Step 1: Open Apple Menu",
					image: macosStep1,
					instructions: "Click on Apple icon.",
				},
				{
					id: "macos-chrome-mic-2",
					title: "Step 2: Open System Settings",
					image: macosStep2,
					instructions: "Click System Settings.",
				},
				{
					id: "macos-chrome-mic-3",
					title: "Step 3: Open Privacy & Security",
					image: macosStep3,
					instructions: "Click Privacy & Security.",
				},
				{
					id: "macos-chrome-mic-4",
					title: "Step 4: Open Microphone Settings",
					image: macosMicStep4,
					instructions: "Click Microphone.",
				},
				{
					id: "macos-chrome-mic-5",
					title: "Step 5: Allow Microphone access to Chrome",
					image: macosChromeMicStep5,
					instructions: "Turn on microphone access to Chrome.",
				},
				{
					id: "macos-chrome-mic-6",
					title: "Step 6: Reopen Chrome",
					image: macosChromeMicStep6,
					instructions: "Click Quit & Reopen to finalize microphone access to Chrome.",
				},
				{
					id: "macos-chrome-mic-7",
					title: "Step 7: Test Microphone permissions",
					button: true,
					instructions: "Click the Test Microphone Permissions button.",
				},
			],
			"camera-title": "How to grant camera permissions in MacOS.",
			camera: [
				{
					id: "macos-chrome-camera-1",
					title: "Step 1: Open Apple Menu",
					image: macosStep1,
					instructions: "Click on Apple icon.",
				},
				{
					id: "macos-chrome-camera-2",
					title: "Step 2: Open System Settings",
					image: macosStep2,
					instructions: "Click System Settings.",
				},
				{
					id: "macos-chrome-camera-3",
					title: "Step 3: Open Privacy & Security",
					image: macosStep3,
					instructions: "Click Privacy & Security.",
				},
				{
					id: "macos-chrome-camera-4",
					title: "Step 4: Open Camera Settings",
					image: macosCamStep4,
					instructions: "Click Camera.",
				},
				{
					id: "macos-chrome-camera-5",
					title: "Step 5: Allow Camera access to Chrome",
					image: macosChromeCamStep5,
					instructions: "Turn on camera access to Chrome.",
				},
				{
					id: "macos-chrome-camera-6",
					title: "Step 6: Reopen Chrome",
					image: macosChromeCamStep6,
					instructions: "Click Quit & Reopen to finalize camera access to Chrome.",
				},
				{
					id: "macos-chrome-camera-7",
					title: "Step 7: Test Camera permissions",
					button: true,
					instructions: "Click the Test Camera Permissions button.",
				},
			],
		},
	});

	function close() {
		emits("update:show", false);
	}

	function joinCall() {
		window.location.reload();
	}

	async function checkAudioPermissions() {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
				video: false,
			});
			stream.getAudioTracks()[0].stop();
			isAudioAvailable.value = true;
		} catch (err) {
			isAudioAvailable.value = false;
		}
	}

	async function checkVideoPermissions() {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: false,
				video: true,
			});
			stream.getVideoTracks()[0].stop();
			isVideoAvailable.value = true;
		} catch (err) {
			isVideoAvailable.value = false;
		}
	}

	onMounted(async () => {
		// on mount check the permissions again just for sanity
		await checkAudioPermissions();
		await checkVideoPermissions();
		if (useHelpers().isIpad()) {
			if (useHelpers().isMobileSafari() && !useHelpers().isMobileChromeIos()) {
				browser.value = "mobile-safari";
				showSecondary.value = true;
			} else if (useHelpers().isMobileChromeIos()) {
				browser.value = "mobile-chrome-ios";
			}
		} else if (useHelpers().isMobileBrowser()) {
			if (useHelpers().isMobileSafari() && !useHelpers().isMobileChromeIos()) {
				browser.value = "mobile-safari";
				showSecondary.value = true;
			} else if (useHelpers().isMobileChromeIos()) {
				browser.value = "mobile-chrome-ios";
			} else if (useHelpers().isFirefox()) {
				close();
			} else {
				browser.value = "mobile-chrome";
			}
		} else {
			if (useHelpers().isSafari()) {
				browser.value = "safari";
			} else if (useHelpers().isFirefox()) {
				browser.value = "firefox";
				showSecondary.value = true;
			} else {
				browser.value = "chrome";
			}

			if (useHelpers().isWindows()) {
				showOS.value = true;
				osCameraTitle.value = allSteps.value["windows"]["camera-title"];
				osMicrophoneTitle.value = allSteps.value["windows"]["microphone-title"];
				osCameraSteps.value = allSteps.value["windows"]["camera"];
				osMicrophoneSteps.value = allSteps.value["windows"]["microphone"];
			} else if (useHelpers().isMacOS() && !useHelpers().isSafari()) {
        showOS.value = true;
        osCameraTitle.value = allSteps.value[`macos-${browser.value}`]["camera-title"];
        osMicrophoneTitle.value = allSteps.value[`macos-${browser.value}`]["microphone-title"];
        osCameraSteps.value = allSteps.value[`macos-${browser.value}`]["camera"];
        osMicrophoneSteps.value = allSteps.value[`macos-${browser.value}`]["microphone"];
			}
		}

		if (browser.value != "") {
			cameraSteps.value = allSteps.value[browser.value]["camera"];
			microphoneSteps.value = allSteps.value[browser.value]["microphone"];

			cameraTitle.value = allSteps.value[browser.value]["camera-title"];
			microphoneTitle.value = allSteps.value[browser.value]["microphone-title"];
			if (showSecondary.value) {
				cameraSecondarySteps.value = allSteps.value[browser.value]["camera-secondary"];
				microphoneSecondarySteps.value = allSteps.value[browser.value]["microphone-secondary"];
				cameraSecondaryTitle.value = allSteps.value[browser.value]["camera-secondary-title"];
				microphoneSecondaryTitle.value = allSteps.value[browser.value]["microphone-secondary-title"];
			}
		}
	});
</script>
<style lang="scss" scoped>
	.custom-modal {
		.modal-dialog {
		}

		.modal-title {
			font-family: OrpheusPro_Bold !important;
		}

		.modal-footer,
		.modal-header {
			border: none;
		}

		.modal-body {
			color: #444;
			padding: 1em 2em 2.5em;
		}
	}

	.main-modal-title-centered {
		font-family: "Inter_Medium";
		font-size: 28px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		text-align: center;
		color: #323b4b;
	}

	.card {
		padding: 15px;
		margin-bottom: 15px;
	}

	.accordion-collapse {
		padding: 5px;
	}

	.accordion {
		margin-top: 5px;
	}

	.accordion-collapse > .step:not(:first-child) {
		margin-top: 15px;
	}
</style>
