<template>
	<canvas
		ref="canvasElement"
		style="object-fit: contain;background-color:black"
		:class="mirrorStyle"></canvas>
	<!-- <div class="lobby-stats" v-if="videoStatsEnabled">
		<div class="stat">Video Stream Dimensions: {{ videoStreamWidth }}x{{ videoStreamHeight }}</div>
		<div class="stat">Video Stream FPS: {{ videoStreamFrameRate }}</div>
		<div class="stat">
			Canvas Stream Dimensions: {{ canvasStream?.getVideoTracks()[0]?.getSettings()?.width }}x{{
				canvasStream?.getVideoTracks()[0]?.getSettings()?.height
			}}
		</div>
		<div class="stat">Canvas Stream FPS: {{ canvasStream?.getVideoTracks()[0]?.getSettings()?.frameRate }}</div>
	</div> -->
</template>
<script lang="ts" setup>
	import type MeetingHandler from "@/classes/MeetingHandler";
	import VideoBlurHelper from "@/classes/VideoBlurHelper";
	import useEventBus from "@/composables/useEventBus";
	import useHelpers from "@/composables/useHelpers";
	import { videoStatsEnabled } from "@/composables/useLocalStorage";
	import type { UserMedia } from "@liveswitch/sdk";
	import { ref, type PropType, onMounted, computed, watch, onUnmounted, inject } from "vue";
	import LiveSwitchUserMedia from "@/classes/LiveSwitchUserMedia";
	import { InjectionKeyAppInsights } from "@/composables/injectKeys";
	import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";

	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const videoBlurred = ref(false);
	const videoElement = ref<HTMLVideoElement>();
	const canvasElement = ref<HTMLCanvasElement>();
	const canvasContext = ref<CanvasRenderingContext2D>();
	const tempCanvas = ref<HTMLCanvasElement>();
	const tempCanvasContext = ref<CanvasRenderingContext2D>();
	const canvasStream = ref<MediaStream>();
	const windowInFocus = ref(true);
	const animationInterval = ref(0);
	const videoInitializing = ref(true);
	const lastTime = ref(0);


	function getCurrentOrientation() {
		if(videoElement && videoElement.value){
			let el = videoElement.value
			if(el.videoHeight > el.videoWidth){
				return 'portrait'
			}else if(el.videoHeight < el.videoWidth){
				return 'landscape'
			}
		}
	}

	const props = defineProps({
		userMedia: {
			type: Object as PropType<UserMedia>,
			required: true,
		},
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
		},
		height: {
			type: Number,
		},
		width: {
			type: Number,
		}
	});

	const emit = defineEmits<{
		(event: "videoBlur", videoBlurred: boolean): void;
		(event: "videoReady"): void;
	}>();

	const segmentVideo = function(){
		return props.meetingHandler.backgroundMode == 'blur' ||
			props.meetingHandler.backgroundMode == 'virtual'
	}

	const videoStreamHeight = function(){
		//return videoElement.value.videoHeight

		const width = videoElement.value.videoWidth
		const height = videoElement.value.videoHeight
		// see todo in this function; we really want to match the layout
		// of the canvas to the layout of the camera, NOT the layout of the device 
		if (getCurrentOrientation() === 'portrait') {
			// app is portrait, return the max value for height
			return Math.max(width, height)
		} else {
			return Math.min(width, height)
		}
	}

	const videoStreamWidth = function(){
		//return videoElement.value.videoWidth
		const width = videoElement.value.videoWidth
		const height = videoElement.value.videoHeight
		if (getCurrentOrientation() === 'portrait') {
			// app is portrait, return the min value for width
			return Math.min(width, height)
		} else {
			return Math.max(width, height)
		}
	};

	const mirrorStyle = computed(() => {
		return {
			mirror: props.userMedia?.isUser && !props.userMedia?.isRemote,
			speaking: props.meetingHandler?.localUserMedia?.audioTrack?.isSpeaking,
			blurred: props.meetingHandler.backgroundMode == 'blur'
		};
	});

	onMounted(async () => {
		/*videoBlurred.value = 
			props.meetingHandler.backgroundMode = 'blur' ||
			props.meetingHandler.backgroundMode = 'virtual'*/
		
		VideoBlurHelper.init();
		VideoBlurHelper.instance.onResultsCallback = onSegmentationResults;
		addWindowHandlers();

		if (props.userMedia.isStarted) {
			await initializeVideo();
		} else {
			props.userMedia.started.bind(async () => {
				console.log('started')
				await initializeVideo();
			});
		}

		if (props.userMedia instanceof LiveSwitchUserMedia) {
			props.userMedia.videoTrack.streamUnbound.bind(() => {
				videoInitializing.value = true;
			});

			(props.userMedia as LiveSwitchUserMedia).streamChanged = async (p) => {
				await initializeVideo();
			};
		}

		// kick off drawing
		await drawCanvas();
	});

	onUnmounted(() => {
		VideoBlurHelper.instance.close();
		
		removeWindowHandlers();
	});

	function addWindowHandlers(): void {
		// Add handlers to detect when the tab is not in focus
		window.onfocus = function () {
			windowInFocus.value = true;
		};

		window.onblur = function () {
			windowInFocus.value = false;
			// Make sure the loop has run before so that all the elements are already set up.
			// We run it here again because the loop wont run on a tab change for RAF, thus we need to run the loop once to implement an interval approach onto our looping
			//drawCanvas();
		};
	}

	function removeWindowHandlers(): void {
		window.onfocus = null;
		window.onblur = null;

		if (animationInterval.value > 0) {
			clearInterval(animationInterval.value);
		}
	}

	async function initializeVideo() {
		
		videoInitializing.value = true;
		
		// TODO: i don't believe we actually need to apply this to the DOM at all
		// it was useful for debugging, but not needed
		// that said, it's the day before release, and everything else works
		// so i'm not changing this right now
		// Jerod, Nov 29, 2023
		if(videoElement.value){
			// remove it if we already have it
			document.body.removeChild(videoElement.value)
		}
		// create a new video element
		videoElement.value = document.createElement("video") as HTMLVideoElement;
		tempCanvas.value = document.createElement("canvas") as HTMLCanvasElement;

		// display offscreen
		videoElement.value.style = 'position:absolute;left:-9999999px;top:0;'
		document.body.appendChild(videoElement.value)

		if (videoElement.value) {
			let loop = async () => {
				if(!videoElement.value.videoHeight){
					setTimeout(() => {
						loop()
					}, 50);
					return
				}
				
				canvasStream.value = canvasElement.value.captureStream(15);
				videoInitializing.value = false;
				
				canvasElement.value.height = videoStreamHeight();
				canvasElement.value.width = videoStreamWidth();
				
				tempCanvas.value.height = videoStreamHeight();
				tempCanvas.value.width = videoStreamWidth();

				videoElement.value.height = videoStreamHeight();
				videoElement.value.width = videoStreamWidth();

				emit("videoReady");

				canvasContext.value = canvasElement.value.getContext("2d", {
					willReadFrequently: true,
				} as CanvasRenderingContext2DSettings) as CanvasRenderingContext2D;

				tempCanvasContext.value = tempCanvas.value.getContext("2d", {
					willReadFrequently: true,
				} as CanvasRenderingContext2DSettings) as CanvasRenderingContext2D;
				
			};
			loop()

			videoElement.value.muted = true;
			videoElement.value.playsInline = true;
			videoElement.value.srcObject = props.userMedia.stream;
			videoElement.value.removeAttribute("autoplay");
			videoElement.value.play();
		}

	}

	// this function should be called 1x on mount ONLY
	let lastVideoHeight = -1
	let lastDrawTime = 0
	let timeout = 0

	async function drawCanvas() {
		if(lastDrawTime == 0){
			// hook up a watchdog timer
			window.setInterval(function(){
				let timeSinceLastLoop = (new Date()).getTime() - lastDrawTime
				if(timeSinceLastLoop > 3000){
					// it's been a full three seconds since the last draw time was updated;
					// we can safely assume something is going wrong, and we should
					// re-start the loop
					// for example, if there is a tab switch, requestAnimationFrame pauses, so
					// possibly the loop dies; it would recover if they come back to the tab but who wants 
					// that experience...
					console.log('Watchdog timer hit, failsafe triggering...')
					clearTimeout(timeout)
					drawCanvas()
				}
			}, 1000)
		}
		
		try{
			lastDrawTime = (new Date()).getTime()

			if (videoInitializing.value) {
				// re-loop
				timeout = setTimeout(drawCanvas, 100)
				return;
			}

			if(videoElement && 
				videoElement.value && 
				videoElement.value.videoHeight != lastVideoHeight && 
				videoElement.value.videoHeight > 0){
					console.log('re-initializing')
				lastVideoHeight = videoElement.value.videoHeight
				await initializeVideo()
				// re-loop
				timeout = setTimeout(drawCanvas, 100)
				return
			}

			if (canvasContext.value && canvasElement.value && videoElement.value) {
				if (segmentVideo()) {
					try {
						if(videoElement.value.videoHeight > 2 && videoElement.value.videoWidth > 2){
							//await VideoBlurHelper.instance.reset();
							await VideoBlurHelper.instance.sendImage(videoElement.value);
						}
					} catch (err: any) {
						appInsights.trackException(
							{
								exception: err,
								id: "MediaStreamError",
								severityLevel: SeverityLevel.Critical,
							},
							useHelpers().getLoggingProperties("BlurError", "BlurError")
						);
						console.warn("Error during video background blur", err);
						if (err.message.indexOf("Abort") != -1) {
							//await VideoBlurHelper.instance.close();
							VideoBlurHelper.instance.reset();
						} else {
							VideoBlurHelper.instance.reset();
						}
					}
				} else {
					canvasContext.value.save();
					canvasContext.value.clearRect(0, 0, canvasElement.value.width, canvasElement.value.height);
					canvasContext.value.globalCompositeOperation = "source-over";
					canvasContext.value.drawImage(
						videoElement.value,
						0,
						0,
						canvasElement.value.width,
						canvasElement.value.height
					);
				}
				
				// always reschedule the draw again
				// requestAnimationFrame where we can
				if (windowInFocus.value) {
					requestAnimationFrame(drawCanvas);
				} else {
					timeout = setTimeout(drawCanvas, 1000 / 15);
				}
			}else{
				// re-loop
				timeout = setTimeout(drawCanvas, 100)
			}
		}catch(e){
			console.error(e)
			// re-loop
			setTimeout(drawCanvas, 100)
		}
	}

	const BACKGROUND_BLUR_DISTANCE = 35
	const MASK_BLUR_DISTANCE = 12

	async function onSegmentationResults(results: any) {
		if (canvasContext.value && canvasElement.value) {
			// Draw selfie outline
			canvasContext.value.save();
			canvasContext.value.clearRect(0, 0, canvasElement.value.width, canvasElement.value.height);

			// this draws a mask on the canvas where the person is on the image
			canvasContext.value.drawImage(
				results.segmentationMask,
				0,
				0,
				canvasElement.value.width,
				canvasElement.value.height
			);
			let data = canvasContext.value.getImageData(
				0,
				0,
				canvasElement.value.width,
				canvasElement.value.height
			);
			// in theory this was supposed to kind of "smooth" the lines...doesn't seem super
			// effective though.
			//VideoBlurHelper.instance.applyBlurFilter(data, MASK_BLUR_DISTANCE, 1);
			canvasContext.value.putImageData(data, 0, 0);

			// this fills in the mask with the person
			canvasContext.value.globalCompositeOperation = "source-in";
			canvasContext.value.drawImage(results.image, 0, 0, canvasElement.value.width, canvasElement.value.height);

			// Draw the blurred background without the selfie image
			canvasContext.value.globalCompositeOperation = "destination-atop";
			if(!props.userMedia.stream.getVideoTracks()[0].enabled){
				// video track isn't enabled => just draw it
				canvasContext.value.drawImage(videoElement.value, 0, 0, canvasElement.value.width, canvasElement.value.height);
			}else{
				switch(props.meetingHandler?.backgroundMode){
					case 'virtual':
						let img = props.meetingHandler?.virtualBackground.getImage(getCurrentOrientation())
						if(img){
							canvasContext.value.drawImage(img, 0, 0, canvasElement.value.width, canvasElement.value.height);
						}
						break;
					case 'blur':
						VideoBlurHelper.instance.applyCustomBlur(
							canvasContext.value,
							videoElement.value,
							tempCanvas.value,
							tempCanvasContext.value,
							videoStreamWidth(),
							videoStreamHeight(),
							BACKGROUND_BLUR_DISTANCE
						);
						break;
					default:
						// default is just draw the video element
						canvasContext.value.drawImage(videoElement.value, 0, 0, canvasElement.value.width, canvasElement.value.height);
						break;
				}
			}
			
			canvasContext.value.restore();
		}
	}

	/*function toggleVideoBlur() {
		//videoBlurred.value = !videoBlurred.value;
		props.meetingHandler.backgroundMode = props.meetingHandler.backgroundMode == 'blur' ? '' : 'blur'
		if (props.meetingHandler.backgroundMode == 'blur') {
			appInsights.trackMetric(
				{
					name: "ToggleBlurOn",
					average: 1,
				},
				useHelpers().getLoggingProperties()
			);
		} else {
			appInsights.trackMetric(
				{
					name: "ToggleBlurOff",
					average: 1,
				},
				useHelpers().getLoggingProperties()
			);
		}
		useEventBus().emitEvent("video-blur-updated", props.meetingHandler.backgroundMode == 'blur');
	}*/

	/*function toggleVideoMute() {
		if (!props.userMedia.stream.getVideoTracks()[0].enabled) {
			props.userMedia.stream.getVideoTracks()[0].enabled = true;
		} else {
			props.userMedia.stream.getVideoTracks()[0].enabled = false;
		}
	}
*/
	async function setVideoMuted(muted){
		props.userMedia.stream.getVideoTracks()[0].enabled = !muted

		if(!muted){
			await initializeVideo()
		}
	}

	function stop() {
		if (canvasStream.value) {
			canvasStream.value.getVideoTracks().forEach((track: MediaStreamTrack) => {
				track.stop();
			});
		}

		if (props.userMedia) {
			props.userMedia.stop;
		}
	}

	defineExpose({
		//toggleVideoBlur,
		//toggleVideoMute,
		setVideoMuted,
		stop,
		videoBlurred,
		canvasStream,
	});
</script>
<style>
	canvas {
		height: 100%;
		width: 100%;
	}

	.mirror {
		transform: scale(-1, 1);
	}

	.speaking {
		border-color: #ffd279 !important;
	}

	.lobby-stats {
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.6);
		color: black;
	}
</style>
