﻿<template>
	<!-- Modal -->
	<div
		class="modal fade"
		:class="{ show: modalOpen }"
		tabindex="-1"
		role="dialog"
		aria-labelledby="exampleModalLabel"
		aria-hidden="true"
		id="settings-modal"
		@click="componentClick">
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Settings</h5>
					<i class="fal fa-times close" @click="useEventBus().emitEvent('modal-close', modalId)"></i>
				</div>
				<div class="modal-body">
					<div class="row mt-2" v-show="meetingHandler.filteredVideoInputDevices.length > 0">
						<div class="dropdown col-12">
							<button
								class="btn btn-secondary dropdown-toggle w-100 device-setting"
								type="button"
								id="dropdownMenuVideoInput"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								@click="openDropdown('video-input')">
								<i class="fas fa-video fa-1x mr-3" style="margin-top: 4px"></i>
								{{ meetingHandler.selectedVideoInputDeviceName }}
							</button>
							<div
								class="dropdown-menu"
								aria-labelledby="dropdownMenuVideoInput"
								:style="{
									display: openDropdownId == 'video-input' ? 'block' : 'none',
								}">
								<button
									v-for="videoInput in meetingHandler.filteredVideoInputDevices"
									class="dropdown-item"
									type="button"
									@click="selectVideoInput(videoInput)">
									{{ videoInput.label || "No camera found" }}
								</button>
							</div>
						</div>
					</div>
					<div class="row mt-2" v-show="meetingHandler.filteredAudioInputDevices.length > 0">
						<div class="dropdown col-12">
							<button
								class="btn btn-secondary dropdown-toggle w-100 device-setting"
								type="button"
								id="dropdownMenuAudioInput"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								@click="openDropdown('audio-input')">
								<i class="fas fa-microphone fa-1x mr-3" style="margin-top: 4px"></i>
								{{ meetingHandler.selectedAudioInputDeviceName }}
							</button>
							<div
								class="dropdown-menu"
								aria-labelledby="dropdownMenuAudioInput"
								:style="{
									display: openDropdownId == 'audio-input' ? 'block' : 'none',
								}">
								<button
									v-for="audioInput in meetingHandler.filteredAudioInputDevices"
									class="dropdown-item"
									type="button"
									@click="selectAudioInput(audioInput)">
									{{ audioInput.label || "No microphone found" }}
								</button>
							</div>
						</div>
					</div>
					<div class="row mt-2" v-show="meetingHandler.filteredAudioOutputDevices.length > 0">
						<div class="dropdown col-12">
							<button
								class="btn btn-secondary dropdown-toggle w-100 device-setting"
								type="button"
								id="dropdownMenuAudioOutput"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								@click="openDropdown('audio-output')">
								<i class="fas fa-volume-up fa-1x mr-3" style="margin-top: 4px"></i>
								{{ meetingHandler.selectedAudioOutputDeviceName }}
							</button>
							<div
								class="dropdown-menu"
								aria-labelledby="dropdownMenuAudioOutput"
								:style="{
									display: openDropdownId == 'audio-output' ? 'block' : 'none',
								}">
								<button
									v-for="audioOutput in meetingHandler.filteredAudioOutputDevices"
									class="dropdown-item"
									type="button"
									@click="selectAudioOutput(audioOutput)">
									{{ audioOutput.label || "No speaker found" }}
								</button>
							</div>
						</div>
					</div>

					<div class="row mt-2">
						<h5 class="modal-title" style="margin-top:12px">Virtual Background</h5>
						<div class="col-3" style="margin-top:20px" v-for="vb in meetingHandler.allVirtualBackgrounds">
							<a 
								href="#" 
								@click.stop="meetingHandler.virtualBackground = vb" 
								:class="{
									highlight: meetingHandler.virtualBackground == vb && meetingHandler.backgroundMode == 'virtual'
								}">
								<!-- 
									mirror because that's how it'll appear to the user in 
									their local preview
								-->
								<img style="transform: scaleX(-1);" :src="vb.images[0].src" />
							</a>
						</div>
						<div class="col-3" style="margin-top:20px;display:flex;justify-content:center;">
							<a 
								href="#" 
								class="none-holder"
								style="text-decoration:none;display:flex;flex-direction:column;justify-content:center;width:100%;text-align:center;border-radius:4px"
								@click.stop="meetingHandler.backgroundMode = ''" 
								:class="{
									highlight: meetingHandler.backgroundMode !== 'virtual'
								}">
								<!-- 
									mirror because that's how it'll appear to the user in 
									their local preview
								-->
								<span>None</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import type MeetingHandler from "@/classes/MeetingHandler";
	import useEventBus from "@/composables/useEventBus";
	import { onMounted, ref, type PropType } from "vue";

	const modalId: string = "settings";
	const modalOpen = ref(false);
	const openDropdownId = ref("");

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
	});

	const permissionsGranted = ref(props.meetingHandler.permissionsGranted);

	onMounted(async () => {
		useEventBus().onEvent("settings-open", () => {
			modalOpen.value = true;
		});

		useEventBus().onEvent("modal-close", (id: string) => {
			if (id == modalId) {
				modalOpen.value = false;
				console.log("settings closed");
			}
		});

		useEventBus().onEvent("permissions-enabled", () => {
			setTimeout(() => {
				permissionsGranted.value = true;
			}, 1500);
		});
	});

	function openDropdown(dropdownId: string) {
		if (openDropdownId.value == dropdownId) {
			openDropdownId.value = "";
		} else {
			openDropdownId.value = dropdownId;
		}
	}

	async function selectAudioInput(input: MediaDeviceInfo) {
		await props.meetingHandler.changeAudioInput(input.deviceId);
		closeDropdown();
	}

	async function selectVideoInput(input: MediaDeviceInfo) {
		await props.meetingHandler.changeVideoInput(input.deviceId);
		closeDropdown();
	}

	async function selectAudioOutput(output: MediaDeviceInfo) {
		await props.meetingHandler.changeAudioOutput(output.deviceId);
		closeDropdown();
	}

	function closeDropdown() {
		openDropdownId.value = "";
	}

	function componentClick(event: MouseEvent) {
		if ((event.target as HTMLElement)?.tagName !== "BUTTON") {
			closeDropdown();
			if ((event.target as HTMLDivElement)?.classList.contains("fade")) {
				useEventBus().emitEvent("modal-close", modalId);
			}
		}
	}
</script>

<style scoped>
	.device-setting {
		text-align: start;
		color: #323b4b;
		background-color: white;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 4px;
	}
	.dropdown-menu {
		margin-left: -2px;
		width: calc(100% - 20px);
	}

	.device-setting:hover {
		color: #323b4b;
		background-color: #ffd279;
		border: 1px solid #6c757d;
	}

	.show > .btn-secondary.dropdown-toggle {
		color: #323b4b;
		background-color: #ffd279;
		border-color: #ffd279;
	}

	#settings-modal {
		align-items: flex-start !important;
	}

	#settings-modal .modal-dialog {
		width: 40%;
	}

	
	#settings-modal a.none-holder{
		border:1px solid black;
	}
	#settings-modal a.none-holder span{
		color:black;
	}
	
	#settings-modal a.highlight img,
	#settings-modal a.highlight.none-holder{
		border:2px solid #ffd279;
	}
	#settings-modal a img{
		border-radius: 4px;
	}

	@media (min-width: 320px) and (max-width: 767px) {
		#settings-modal .modal-dialog {
			width: 100%;
		}

		#settings-modal .modal-dialog .modal-content a.none-holder{
			border:1px solid white;
		}

		#settings-modal .modal-dialog .modal-content,
		#settings-modal .modal-dialog .modal-content a.none-holder span{
			background-color: #343b4a;
			color: white;
		}

		#settings-modal .modal-dialog .modal-content .modal-title {
			color: white;
		}

		#settings-modal .modal-dialog .close {
			color: white;
			opacity: 1;
		}

		#settings-modal {
			align-items: flex-end !important;
		}

	}
</style>
