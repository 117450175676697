<template>
	<div v-if="props.meetingHandler.waitingRoomOpen">
		<div class="background" @click="componentClick"></div>
		<div class="waiting-room">
			<nav class="side-panel-nav">
				<span class="title-text-24 title"> Waiting Room </span>
				<i class="fal fa-times close" @click="close"></i>
			</nav>
			<div class="participant-list">
				<div class="list-group participant-list-container">
					<div class="list-group-item participant-box">
						<div
							class="participant-row"
							v-for="attendee in props.lobbyHandler.meeting.attendees.filter(
								(a) => a.id != props.lobbyHandler.meeting.localAttendee.id
							)"
							:key="attendee.id">
							<div>{{ attendee.displayName }}</div>
							<div>
								<button
									title="Admit"
									class="btn btn-waiting-room accept"
									@click="acceptAttendee(attendee.id)">
									<i class="fal fa-check"></i>
								</button>
								<button
									title="Decline"
									class="btn btn-waiting-room decline"
									@click="blockAttendee(attendee.id)">
									<i class="fal fa-xmark"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import type { PropType } from "vue";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import type LobbyHandler from "@/classes/LobbyHandler";

	const props = defineProps({
		lobbyHandler: {
			type: Object as PropType<LobbyHandler>,
			required: true,
		},
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
	});

	const emit = defineEmits<{
		(event: "refreshLobby", userInteraction: boolean): void;
		(event: "ignoreChime", ignoreChime: boolean): void;
	}>();

	function close() {
		props.meetingHandler.waitingRoomOpen = false;
	}

	async function acceptAttendee(id: string) {
		await props.lobbyHandler.meeting.chat.defaultChannel.send(
			JSON.stringify({
				id: id,
				admitted: true,
			})
		);
	}

	async function blockAttendee(id: string) {
		emit("ignoreChime", false);
		await props.lobbyHandler.meeting.chat.defaultChannel.send(
			JSON.stringify({
				id: id,
				admitted: false,
			})
		);
	}

	function componentClick(event: MouseEvent) {
		if ((event.target as HTMLDivElement)?.classList.contains("background")) {
			close();
		}
	}
</script>

<style scoped>
	.background {
		position: absolute;
		top: 0;
		left: 0;
		background: darkgray;
		opacity: 0.5;
		height: 100%;
		width: 100%;
		z-index: 999;
	}
	.waiting-room {
		position: absolute;
		top: 5%;
		right: 15px;
		height: 50%;
		width: 20%;
		background-color: #fff;
		padding: 0.75em;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		z-index: 1000;
	}

	.side-panel-nav {
		color: black;
		display: flex;
		align-items: center;
		width: 100%;
	}

	.side-panel-nav .title {
		margin-left: 0;
	}

	.side-panel-nav .close {
		justify-self: flex-end;
		margin-right: 0.25em;
		font-size: 24px;
		border-radius: 50%;
		padding: 6px;
		height: 36px;
		width: 36px;
		justify-content: center;
		display: flex;
	}

	.close:hover {
		cursor: pointer;
		color: #444;
		background-color: #aaa !important;
	}

	.title-text-24 {
		flex: 1;
		font-family: "Inter_Medium";
		font-size: 24px;
		font-weight: normal;
		padding-left: 0.5em;
	}

	.participant-row {
		display: flex;
	}

	.participant-row > div:first-child {
		flex: 3;
	}

	.participant-row > div:not(:first-child) {
		flex: 2;
		display: flex;
		justify-content: end;
	}

	.btn-waiting-room {
		height: 32px;
		width: 32px;
		margin-right: 8px;
		padding: 0;
		background: none;
		font-size: 20px;
		color: black;
		background-color: white;
		border-radius: 50%;
	}

	.btn-waiting-room.accept {
		color: green;
	}

	.btn-waiting-room.accept:hover {
		background-color: white;
		color: green !important;
	}

	.btn-waiting-room.decline {
		color: red;
	}

	.btn-waiting-room.decline:hover {
		background-color: white;
		color: red !important;
	}

	@media (max-width: 767px) {
		.waiting-room {
			width: 94%;
		}
	}

	@media (min-width: 768px) and (max-width: 1181px) {
		.waiting-room {
			width: 35%;
		}
	}

	@media (min-width: 320px) and (max-width: 767px), (orientation: landscape) and (max-height: 420px) {
		.side-panel {
			border-radius: 0;
		}
	}
</style>
