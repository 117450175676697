﻿<template>
	<div
		class="video-grid-container"
		v-if="meetingHandler.remoteScreenSharing || !useHelpers().isMobileBrowser()"
		:type="'screen'"
		:class="{ 'mobile-grid': useHelpers().isMobileBrowser() }">
		<!--v-if="meetingHandler.remoteScreenSharing">-->
		<div class="remote-screen" v-if="meetingHandler.remoteScreenSharing">
			<Video
				v-for="video in meetingHandler.visibleDisplayMedias"
				:video="video"
				:videoCount="1"
				:meetingHandler="meetingHandler"
				:videoType="'screen'"
				:key="video.id" />
		</div>
		<div
			v-if="!useHelpers().isMobileBrowser()"
			class="video-grid"
			:class="{
				'thumbnail-container': meetingHandler.remoteScreenSharing,
				'mobile-grid': useHelpers().isMobileBrowser(),
			}">
			<Video
				v-if="meetingHandler.localScreenSharing"
				v-for="video in meetingHandler.visibleDisplayMedias"
				:video="video"
				:videoCount="videoCount"
				:meetingHandler="meetingHandler"
				:videoType="'screen'"
				:key="video.id" />
			<Video
				v-if="!useHelpers().isMobileBrowser()"
				:video="localUserMedia"
				:videoCount="videoCount"
				:meetingHandler="meetingHandler"
				:videoType="meetingHandler.remoteScreenSharing ? 'thumbnail' : 'gallery'"
				:key="localUserMedia?.id"
				:localMediaBlurred="localMediaBlurred" />
			<Video
				v-if="!useHelpers().isMobileBrowser()"
				v-for="video in displayVideos"
				:video="video"
				:videoCount="videoCount"
				:meetingHandler="meetingHandler"
				:videoType="meetingHandler.remoteScreenSharing ? 'thumbnail' : 'gallery'"
				:key="video.id"
				:testVideo="testing" />
		</div>
	</div>

	<Carousel
		ref="carousel"
		v-if="useHelpers().isMobileBrowser()"
		:items-to-show="1"
		@slide-start="slideStart"
		@slide-end="slideEnd"
		@touchstart="touchStart"
		:class="{
			'no-nav': mobilePages == 1,
			'screen-share': meetingHandler.remoteScreenSharing,
		}">
		<Slide v-for="slide in mobilePages" :key="slide" :id="`slide-${slide}`">
			<div
				v-if="meetingHandler.remoteScreenSharing && showMobileLocalVideo"
				v-show="!sliding"
				class="video-grid"
				:type="'user'"
				:class="videoGridStyle">
				<Video
					v-if="currentPage == 0 || (currentPage > 0 && currentPage == slide - 1)"
					v-show="
						currentPage == 0 ||
						(!meetingHandler.sidePanelOpen && !meetingHandler.videoMuted && localUserMedia.isStarted)
					"
					:floating="currentPage > 0"
					:video="localUserMedia"
					:videoCount="videoCount"
					:meetingHandler="meetingHandler"
					:videoType="'gallery'"
					:key="`${localUserMedia?.id}-local`"
					:localMediaBlurred="localMediaBlurred" />

				<Video
					v-for="(video, index) in displayVideos"
					:video="video"
					:videoCount="videoCount"
					:meetingHandler="meetingHandler"
					:videoType="'gallery'"
					:key="`${video.id}-${index}`"
					:testVideo="testing" />
			</div>
			<div
				v-else
				v-show="!sliding"
				class="video-grid"
				:type="'user'"
				:class="{ 'flex-column': videoCount == 1 && !meetingHandler.localScreenSharing }">
				<Video
					v-if="currentPage == 0 || (currentPage > 0 && currentPage == slide - 1)"
					v-show="
						currentPage == 0 ||
						(!meetingHandler.sidePanelOpen && !meetingHandler.videoMuted && localUserMedia.isStarted)
					"
					:floating="currentPage > 0"
					:video="localUserMedia"
					:videoCount="videoCount"
					:meetingHandler="meetingHandler"
					:videoType="'gallery'"
					:key="`${localUserMedia?.id}-local`"
					:localMediaBlurred="localMediaBlurred" />

				<Video
					v-for="(video, index) in displayVideos"
					:video="video"
					:videoCount="videoCount"
					:meetingHandler="meetingHandler"
					:videoType="'gallery'"
					:key="`${video.id}-${index}`"
					:testVideo="testing" />

				<div
					class="solo-mobile-invite"
					v-if="!meetingHandler.localScreenSharing && meetingHandler.activeRemoteAttendees?.length == 0">
					<h4>You're the only one here.</h4>
					<p class="share-text">Share the link to start a meeting</p>
					<div class="share-meeting">
						<button 
							type="submit" 
							class="btn share-invite" 
							id="button-copy-meeting" 
							@click="copyLink()">
							Copy Meeting Link <i class="fas fa-copy pl-2"></i>
						</button>
					</div>
					<div class="share-meeting">
						<button
							type="button"
							class="btn share-invite"
							id="button-share-meeting"
							@click="useEventBus().emitEvent('toggle-share-modal')">
							Share Invite
							<img src="../assets/img/share_invite.svg" alt="Share Invite" />
						</button>
					</div>
				</div>
			</div>
		</Slide>

		<template #addons>
			<Navigation />
			<Pagination />
		</template>
	</Carousel>

	<!-- <div
		v-else-if="!meetingHandler.remoteScreenSharing && !useHelpers().isMobileBrowser()"
		class="video-grid"
		:type="'user'"
		:class="{ 'flex-column': videoCount == 1 && !meetingHandler.localScreenSharing }">
		<Video
			v-if="meetingHandler.localScreenSharing"
			v-for="video in meetingHandler.visibleDisplayMedias"
			:video="video"
			:videoCount="videoCount"
			:meetingHandler="meetingHandler"
			:videoType="'screen'"
			:key="video.id" />

		<Video
			v-if="true"
			:video="props.meetingHandler.localUserMedia"
			:videoCount="videoCount"
			:meetingHandler="meetingHandler"
			:videoType="'gallery'"
			:key="props.meetingHandler.localUserMedia?.id" />

		<Video
			v-for="video in displayVideos"
			:video="video"
			:videoCount="videoCount"
			:meetingHandler="meetingHandler"
			:videoType="'gallery'"
			:key="video.id" />

		<div class="solo-mobile-invite" v-if="!meetingHandler.localScreenSharing && displayVideos.length == 0">
			<h4>You're the only one here.</h4>
			<p class="share-text">Share the link to start a meeting</p>
			<div class="share-meeting">
				<button type="submit" class="btn share-invite" @click="copyLink()">
					Copy Meeting Link <i class="fas fa-copy pl-2"></i>
				</button>
				<div
					v-show="linkCopied"
					class="rounded-pill text-white link-copied"
					style="white-space: nowrap; margin-right: 4px">
					Link copied!
				</div>
			</div>
			<div class="share-meeting">
				<button type="button" class="btn share-invite" @click="useEventBus().emitEvent('toggle-share-modal')">
					Share Invite
					<img src="../assets/img/share_invite.svg" alt="Share Invite" />
				</button>
			</div>
		</div>
	</div> -->

	<!-- Floating Local Video - Mobile Pager -->

	<!-- <Video
		v-show="currentPage > 0"
		:floating="true"
		:video="props.meetingHandler.localUserMedia"
		:videoCount="videoCount"
		:meetingHandler="meetingHandler"
		:videoType="'gallery'"
		:key="`${props.meetingHandler.localUserMedia?.id}-floating`" /> -->
</template>

<script lang="ts" setup>
	import { ref, type PropType, onMounted, getCurrentInstance } from "vue";
	import TestMedia from "@/classes/TestMedia";
	import { RemoteMedia, type Media, type MediaCollection, UserMedia } from "@liveswitch/sdk";
	import Video from "@/components/Video.vue";
	import { computed } from "@vue/reactivity";
	import useHelpers from "@/composables/useHelpers";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import useEventBus from "@/composables/useEventBus";
	import "vue3-carousel/dist/carousel.css";
	import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
		localUserMedia: {
			type: Object as PropType<UserMedia>,
			required: true,
		},
		localMediaBlurred: {
			type: Boolean,
		},
		screenShare: {
			type: Boolean,
		},
	});

	const testing = ref(true);
	const testVideos = ref<TestMedia[]>([]);
	const orientation = ref(screen.orientation || useHelpers().getScreenOrientation());
	const maxDesktopVideos = props.meetingHandler.useLargeGrid ? 9999 : 9;
	const instance = getCurrentInstance();
	const currentPage = ref(0);
	const mobileLocalVideoFloating = ref(false);
	const carousel = ref(null);
	const sliding = ref(false);
	let maxMobileVideos = parseInt(import.meta.env.VITE_MAX_MOBILE_USERS);
	let speakerVideos: Media[] = [];

	const showMobileLocalVideo = computed(() => {
		let show = true;

		if (useHelpers().isMobileBrowser() && window.screen.width < 1000) {
			if (props.meetingHandler.remoteScreenSharing && orientation.value.type.indexOf("landscape") != -1) {
				return false;
			}
		}

		return show;
	});

	const maxVideos = computed(() => {
		let mobileVideos = maxMobileVideos;

		if (props.meetingHandler.remoteScreenSharing) {
			if (orientation.value.type.indexOf("landscape") != -1 && window.screen.width < 1000) {
				mobileVideos = 0;
			} else {
				mobileVideos = maxMobileVideos;
			}
		}

		return useHelpers().isMobileBrowser() ? mobileVideos : maxDesktopVideos;
	});

	const linkCopied = ref(false);

	if (useHelpers().isMobileBrowser() && testVideos.value.length > 8) {
		testVideos.value = testVideos.value.slice(0, 8);
	}

	const allVideos = computed(() => {
		let videos: Media[] = [];
		const filtered = props.meetingHandler.visibleUserMedias.filter(
			(x) => x.attendee?.id != props.meetingHandler.localAttendeeId
		);

		for (var i = 0; i < filtered.length; i++) {
			const video = filtered[i];
			videos.push(video);
		}

		return videos;
	});

	const videoCount = computed(() => {
		let count = displayVideos.value.length + 1;

		if (props.meetingHandler.localScreenSharing && props.meetingHandler.localScreenShareVisible) {
			count += 1;
		}

		return count;
	});

	const maxVisibleUserMedia = computed(() => {
		if (useHelpers().isMobileBrowser()) {
			return maxMobileVideos;
		} else if (useHelpers().isSafari()) {
			const max = Number.parseInt(import.meta.env.VITE_MAX_SAFARI_USERS);
			return max;
		} else {
			return props.meetingHandler.maxVisibleUserMedias ?? 49;
		}
	});

	const mobileVideos = computed(() => {
		let limit: number = maxVideos.value - 1;
		limit = Math.max(limit, 0);
		let filtered = allVideos.value.slice(0, limit);
		return filtered;
	});

	const mobilePages = computed(() => {
		if (!useHelpers().isMobileBrowser()) {
			return 1;
		}

		const lastPage = props.meetingHandler.activeRemoteAttendees?.length / maxVideos.value > 0 ? 1 : 0;
		//let pages = lastPage + Math.floor(props.meetingHandler.attendees?.totalCount / maxVideos.value);
		let pages = Math.ceil(props.meetingHandler.attendees?.totalCount / maxVideos.value);
		//let pages = lastPage + Math.floor(getTestVideos(3).length / maxVideos.value);

		if (maxMobileVideos == 1) {
			pages -= 1;
		}

		pages = Math.max(1, pages);

		if (isNaN(pages)) {
			pages = 1;
		}

		return pages;
	});

	// const audios = computed(() => {
	// 	const currentVideos = displayVideos.value;
	// 	const currentAudios = props.meetingHandler.audibleMedias.filter((audio) => {
	// 		return (
	// 			currentVideos.filter((video) => {
	// 				return video.attendee?.id == audio.attendee?.id;
	// 			}).length == 0
	// 		);
	// 	});

	// 	return currentAudios;
	// });

	const thumbnails = computed(() => {
		let videos: Media[] = allVideos.value;

		// Remove media of attendees that left the call

		for (var i = 0; i < speakerVideos.length; i++) {
			let video = speakerVideos[i];
			let existing = props.meetingHandler.attendees.find((x) => x.id == video.attendee?.id);

			if (!existing) {
				speakerVideos.splice(i, 1);
				i--;
			}
		}

		for (var i = 0; i < videos.length; i++) {
			let video = videos[i];
			let existing = speakerVideos.find((x) => x.id == video.id);

			if (existing) {
				if (existing.audioTrack?.isSpeaking) {
					let videoIndex = speakerVideos.indexOf(existing);

					if (videoIndex > 3) {
						speakerVideos.splice(videoIndex, 1);
						speakerVideos.splice(0, 0, existing);
					}
				}
			} else {
				existing = video;

				if (existing.audioTrack?.isSpeaking) {
					speakerVideos.splice(0, 0, existing);
				} else {
					speakerVideos.push(existing);
				}
			}
		}

		return speakerVideos;
	});

	const displayVideos = computed(() => {
		if (props.meetingHandler.remoteScreenSharing && !useHelpers().isMobileBrowser()) {
			return thumbnails.value;
		}

		//let limit: number = 1;
		let limit: number = maxVideos.value - 1; // To accomodate the local user media being displayed separately
		let videos: Media[] | undefined = [];
		limit = Math.max(limit, 0);

		if (useHelpers().isMobileBrowser() && currentPage.value > 0) {
			limit += 1;
		}

		if (props.meetingHandler.localScreenSharing) {
			limit -= 1;
		}

		if (useHelpers().isMobileBrowser()) {
			//return mobileVideos.value;
		}

		// let videos: Media[] = allVideos.value;
		// const activeSpeakerThreshold = parseInt(import.meta.env.VITE_ACTIVE_SPEAKER_THRESHOLD);

		// // Remove media of attendees that left the call

		// if (props.meetingHandler.activeRemoteAttendees?.length > activeSpeakerThreshold) {
		// 	for (var i = 0; i < speakerVideos.length; i++) {
		// 		let video = speakerVideos[i];
		// 		let existing = props.meetingHandler.attendees.find((x) => x.id == video.attendee?.id);

		// 		if (!existing || existing.status !== "ACTIVE") {
		// 			speakerVideos.splice(i, 1);
		// 			i--;
		// 		}
		// 	}

		// 	// The max number of active speakers will the top row of users.

		// 	let maxActiveSpeakers = Math.floor(Math.sqrt(props.meetingHandler.activeRemoteAttendees?.length)) - 1;

		// 	for (var i = 0; i < videos.length; i++) {
		// 		let video = videos[i];
		// 		let existing = speakerVideos.find((x) => x.id == video.id);

		// 		if (existing) {
		// 			if (existing.audioTrack?.isSpeaking) {
		// 				let videoIndex = speakerVideos.indexOf(existing);

		// 				if (videoIndex > maxActiveSpeakers) {
		// 					speakerVideos.splice(videoIndex, 1);
		// 					speakerVideos.splice(0, 0, existing);
		// 				}
		// 			}
		// 		} else {
		// 			existing = video;

		// 			if (existing.audioTrack?.isSpeaking) {
		// 				speakerVideos.splice(0, 0, existing);
		// 			} else {
		// 				speakerVideos.push(existing);
		// 			}
		// 		}
		// 	}
		// } else {
		// 	speakerVideos = videos;
		// }

		// let finalVideos: Media[] = [];

		// for (var i = 0; i < speakerVideos.length; i++) {
		// 	finalVideos.push(speakerVideos[i]);
		// }

		// if (finalVideos.length < maxVisibleUserMedia.value - 1) {
		// 	const blankAttendees = props.meetingHandler.activeRemoteAttendees?.filter((attendee) => {
		// 		return (
		// 			props.meetingHandler.visibleUserMedias?.filter((media) => {
		// 				return media.attendee?.id === attendee.id;
		// 			}).length == 0
		// 		);
		// 	});

		// 	if (blankAttendees) {
		// 		for (var i = 0; i < blankAttendees.length; i++) {
		// 			const attendee = blankAttendees[i];
		// 			const blankMedia: any = {
		// 				attendeeId: attendee.id,
		// 				attendee: {
		// 					id: attendee.id,
		// 				},
		// 				id: attendee.id,
		// 				type: "blank",
		// 				stream: null,
		// 				isUser: true,
		// 				isDisplay: false,
		// 			};

		// 			finalVideos?.push(blankMedia);
		// 		}
		// 	}
		// }

		// if (finalVideos.length >= maxVisibleUserMedia.value) {
		// 	finalVideos = finalVideos.slice(0, maxVisibleUserMedia.value - 1);
		// }

		// //finalVideos = getTestVideos(16);

		// return finalVideos;
		//return speakerVideos;
		let mobileStartOffset = currentPage.value > 0 ? -1 : 0;
		const start = currentPage.value * maxVideos.value + mobileStartOffset;

		 //videos = getTestVideos(10);

		// if (videos.length > maxVideos.value) {
		// 	videos = videos.slice(start, start + maxVideos.value);
		// }

		// return videos;

		let filtered: Media[];

		if (props.meetingHandler?.showThumbnailView || useHelpers().isMobileBrowser()) {
			filtered = props.meetingHandler.visibleUserMedias
				.filter((x) => x.attendee?.id != props.meetingHandler.localAttendeeId)
				.slice(start, start + limit);
		} else {
			filtered = props.meetingHandler.visibleUserMedias.filter(
				(x) => x.attendee?.id != props.meetingHandler.localAttendeeId
			);
		}


		for (var i = 0; i < filtered.length; i++) {
			videos?.push(filtered[i]);
		}

		let pageLimit = 0;
		let mediaMismatch = props.meetingHandler.attendees?.length !== props.meetingHandler.visibleUserMedias?.length;
		const lastPage = currentPage.value != 0 && currentPage.value + 1 == mobilePages.value;

		if (useHelpers().isMobileBrowser()) {
			pageLimit = maxMobileVideos;
		} else {
			pageLimit = maxVisibleUserMedia.value;
			mobileStartOffset = 0;
		}

		const blankAttendees = props.meetingHandler.activeRemoteAttendees
			?.slice(start, start + limit)
			.filter(attendee => attendee?.avatarUrl?.indexOf("LiveSwitch Bot") == -1)
			.filter((attendee) => {
				return (
					videos?.filter((media) => {
						return media.attendee?.id === attendee.id;
					}).length == 0
				);
			});

		mediaMismatch = blankAttendees?.length > 0;

		if (videos.length < pageLimit && mediaMismatch) {
			// TODO Check logic here
			// const blankAttendees = props.meetingHandler.activeRemoteAttendees?.filter((attendee) => {
			// 	return (
			// 		props.meetingHandler.visibleUserMedias?.filter((media) => {
			// 			return media.attendee.id === attendee.id;
			// 		}).length == 0
			// 	);
			// });

			if (blankAttendees.length > 0) {
				for (var i = 0; i < blankAttendees.length; i++) {
					const attendee = blankAttendees[i];
					const blankMedia: any = {
						attendeeId: attendee.id,
						attendee: {
							id: attendee.id,
							displayName: attendee.displayName,
							avatarUrl: attendee.avatarUrl,
						},
						id: attendee.id,
						type: "blank",
						stream: null,
						isUser: true,
						isDisplay: false,
					};

					videos?.push(blankMedia);
				}
			}
		}

		if (videos.length + mobileStartOffset >= maxVisibleUserMedia.value && maxVisibleUserMedia.value > 1) {
			videos = videos.slice(0, maxVisibleUserMedia.value - 1);
		}

		// final filter
		videos = videos.filter(x => x.attendee?.displayName != 'LiveSwitch Bot');

		//videos = getTestVideos(3);

		return videos;
	});

	const videoGridStyle = computed(() => {
		const style = {
			"mobile-grid": useHelpers().isMobileBrowser(),
			"flex-column":
				props.meetingHandler.visibleUserMedias.length <= 2 && !props.meetingHandler.remoteScreenSharing,
			"flex-row": props.meetingHandler.useLargeGrid || props.meetingHandler.visibleUserMedias.length > 2,
		};

		return style;
	});

	const meetingLink = computed(() => {
		return window.location.toString().split("#")[0] + "#";
	});

	onMounted(() => {
		init();
	});

	async function touchStart(e: any) {
		const touch = e.touches[0];
		const target = document.elementFromPoint(touch.pageX, touch.pageY);
		if (target && target.classList.contains("btn")) {
			(target as HTMLElement).click();
			e.preventDefault();
		}
	}

	async function slideStart(data: any) {
		console.log(data);
		sliding.value = true;

		let mobileStartOffset = currentPage.value > 0 ? -1 : 0;
		let mobileLimitOffset = currentPage.value == 0 ? -1 : 0;
		let start = currentPage.value * maxVideos.value + mobileStartOffset;

		if (maxMobileVideos == 1) {
			start = Math.max(start, 1);
		}

		let limit = maxVideos.value + mobileLimitOffset;
		const videosToDisable = props.meetingHandler.visibleUserMedias
			.filter((x) => x.attendee?.id != props.meetingHandler.localAttendeeId)
			.slice(start, start + limit);

		console.debug(`Disabling Mobile Videos: Start=${start}, Limit=${limit}, VideoCount=${videosToDisable.length}`);

		for (var i = 0; i < videosToDisable.length; i++) {
			const video = videosToDisable[i];

			if (video instanceof RemoteMedia) {
				await (video as RemoteMedia).videoTrack?.setPriority("off");
				console.debug(
					`Setting video track priority to 'off': MediaId=${video.id}, Attendee=${video.attendee?.displayName}`
				);
			}
		}

		currentPage.value = data.slidingToIndex;
		mobileLocalVideoFloating.value =
			data.slidingToIndex != 0 && !props.meetingHandler.videoDisabled && !props.meetingHandler.videoMuted;
		mobileStartOffset = currentPage.value > 0 ? -1 : 0;
		mobileLimitOffset = currentPage.value == 0 ? -1 : 0;
		start = currentPage.value * maxVideos.value + mobileStartOffset;
		limit = maxVideos.value + mobileLimitOffset;

		if (maxMobileVideos == 1) {
			start = Math.min(start, 0);
		}

		const videosToEnable = props.meetingHandler.visibleUserMedias
			.filter((x) => x.attendee?.id != props.meetingHandler.localAttendeeId)
			.slice(start, start + limit);

		console.debug(`Enabling Mobile Videos: Start=${start}, Limit=${limit}, VideoCount=${videosToEnable.length}`);

		for (var i = 0; i < videosToEnable.length; i++) {
			const video = videosToEnable[i];

			if (video instanceof RemoteMedia) {
				await (video as RemoteMedia).videoTrack?.setPriority("high");
				console.debug(
					`Setting video track priority to 'high': MediaId=${video.id}, Attendee=${video.attendee?.displayName}`
				);
			}
		}
	}

	function slideEnd() {
		sliding.value = false;
	}

	function init() {
		useEventBus().onEvent("orientation-change", async (newOrientation: ScreenOrientation) => {
			orientation.value = newOrientation;
			await instance?.proxy?.$forceUpdate();
		});

		useEventBus().onEvent("media-removed", () => {
			const videoCount = props.meetingHandler.visibleUserMedias?.length;
			const remainingPages = Math.ceil(videoCount / maxMobileVideos);

			if (currentPage.value > remainingPages - 1) {
				if (videoCount && carousel.value) {
					if (remainingPages - 1 == 0) {
						carousel.value.slideTo(0);
					} else {
						carousel.value.prev();
					}
				}
			}
		});

		// TODO use meeting object as source
		testVideos.value = [];
		for (let i = 0; i < 6; i++) {
			const video = new TestMedia(`${i}`, `User ${i}`);
			testVideos.value.push(video);
		}
	}

	const copyLink = async () => {
		'use strict';
		const copyMeetingButton = document.getElementById('button-copy-meeting');
		try {
			copyMeetingButton.innerText = 'Loading...';
			await props.meetingHandler.copyMeetingLink();
			copyMeetingButton.innerText = 'Copied successfully!';
			setTimeout(() => {
				copyMeetingButton.innerHTML = 'Copy Meeting Link <i class="fas fa-copy pl-2"></i>';
			}, 2000);
		} catch (error) {
			// Handle any errors that occurred during the copy process
			console.error('Error copying content:', error);
			copyMeetingButton.innerHTML = 'Copy Meeting Link <i class="fas fa-copy pl-2"></i>';
		}
	}

	function getTestVideos(limit: number) {
		let testVids: Media[] = [];
		let videos: Media[] = [];
		let video: Media | undefined = props.meetingHandler.localUserMedia;
		for (var i = 0; i < limit; i++) {
			testVids?.push(video);
		}
		if (props.meetingHandler?.showThumbnailView || useHelpers().isMobileBrowser()) {
			videos = testVids.slice(0, limit);
		} else {
			videos = testVids;
		}

		return videos;
	}
</script>

<style scoped>
	.video-grid-container {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: row;
	}

	.remote-screen {
		width: 80%;
	}

	.mobile-grid .remote-screen {
		width: 100%;
	}

	.video-grid {
		display: flex;
		width: 100%;
		height: 100%;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		flex-direction: row;
	}

	.thumbnail-container {
		display: flex !important;
		flex-direction: row;
		align-items: center;
		height: 100%;
		width: 20%;
		max-height: 100%;
		overflow-y: scroll;
	}

	.screen-share {
		height: 50% !important;
	}

	.solo-mobile-invite {
		display: none;
		margin-top: 24px;
		text-align: center;
		color: white;
		max-width: 100%;
	}

	.solo-mobile-invite .share-text {
		font-family: "Inter_Light";
	}

	.solo-mobile-invite .meeting-link {
		border-right: 1px solid #343b4a;
		height: 100%;
		display: flex;
		align-items: center;
		max-width: 85%;
		padding: 12px;
		font-size: 13px;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.solo-mobile-invite .meeting-link .fa-lock {
		opacity: 0.5;
		margin-right: 8px;
	}

	.solo-mobile-invite .meeting-link .meeting-link-url {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.solo-mobile-invite .copy-meeting-link {
		display: flex;
		flex: 0;
		justify-content: center;
		font-size: 18px;
		padding: 12px 16px;
		cursor: pointer;
	}

	.solo-mobile-invite .share-meeting {
		margin-top: 16px;
		font-family: "Inter_Light";
	}

	.solo-mobile-invite .share-meeting .share-invite {
		background-color: #346ee0;
		border-radius: 10px;
		padding: 8px 16px;
		color: white;
		font-size: 14px;
		line-height: 2;
	}

	@media (min-width: 320px) and (max-width: 767px) {
		.video-container {
		}

		.video-grid {
			/* flex-direction: column; */
		}

		.solo-mobile-invite {
			display: flex !important;
			flex-direction: column;
		}

		.solo-mobile-invite .link-copied {
			font-size: 9px;
		}
	}

	@media (min-width: 1600px) and (max-height: 1200px) and (orientation: landscape) {
		.mobile-grid {
			flex-wrap: nowrap;
		}

		.mobile-grid .video[type="user"],
		.mobile-grid canvas {
			width: auto !important;
			height: auto !important;
			min-height: 50%;
			max-height: 100% !important;
			min-width: 25%;
			max-width: 50%;
		}

		.mobile-grid.flex-row .video[type="user"],
		.mobile-grid.flex-row canvas {
			height: 100% !important;
		}

		.video-container-mobile-screen .video-grid[type="screen"]{
			height: 70%;
		}

		.video-container-mobile-screen .video-grid[type="user"] {
			height: 30%;
		}
	}

	@media (min-width: 1200px) and (max-height: 2000px) and (orientation: portrait) {
		.video-container-mobile-screen .video-grid[type="screen"] {
			height: 40%;
		}

		.video-container-mobile-screen .video-grid[type="user"] {
			height: 60%;
		}
	}

	.mobile-grid {
		height: 50%;
	}

	@media (orientation: landscape) and (max-height: 420px) {
		.video-grid[type="screen"] {
			height: 100%;
		}

		.mobile-grid[type="user"] {
			display: none;
		}

		.mobile-grid[type="screen"] {
			height: 100%;
		}

		.solo-mobile-invite {
			display: block;
		}
	}

	@media (min-height: 500px) {
		.solo-mobile-invite {
			display: none;
		}
	}

	@media (orientation: portrait) {
		.video-grid.mobile-grid {
			flex-direction: column !important;
		}
	}
</style>
