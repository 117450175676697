import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../views/HomeView.vue";
import SignInView from "../views/SignInView.vue";
import SignUpView from "../views/SignUpView.vue";
import EmbeddedSignUpView from "../views/EmbeddedSignUpView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/",
			name: "home",
			component: HomeView,
			beforeEnter: (to, from) => {
				if (to.query.callended) {
					window.location.href = import.meta.env.VITE_USER_SITE_URL;
					return false;
				}
				return true;
			},
		},
		{
			path: "/lobby",
			name: "lobby",
			component: HomeView,
			beforeEnter: (to, from) => {
				if (to.query.callended) {
					window.location.href = import.meta.env.VITE_USER_SITE_URL;
					return false;
				}
				return true;
			},
		},
		{
			path: "/login",
			name: "signIn",
			component: SignInView,
		},
		{
			path: "/register",
			name: "signUp",
			component: SignUpView,
		},
		{
			path: "/embeddedRegister",
			name: "embeddedSignUp",
			component: EmbeddedSignUpView,
		},
		{
			path: "/resetPassword",
			name: "resetPassword",
			component: ResetPasswordView,
		},
		{
			path: "/meetingsso",
			name: "meetingsso",
			component: HomeView,
			beforeEnter: (to, from) => {
				window.close();
				return false;
			},
		},
	],
});

export default router;
