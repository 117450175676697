﻿<template>
	<header>
		<NavBar
			:meeting-handler="meetingHandler"
			:lobby-handler="lobbyHandler"
			:meeting-subject="meetingSubject"
			:bot-enabled="botEnabled" />
	</header>

	<div class="d-flex h-100" :class="{ 'mt-2': useHelpers().isMobileBrowser() }" v-if="pageState === 'lobby'">
		<Lobby
			@join="join"
			:join-progress="inProgress"
			:channel-id="channelId"
			:lobby-handler="lobbyHandler"
			:meeting-handler="meetingHandler" />
	</div>
	<div class="meeting container-fluid" v-else-if="pageState === 'meeting'">
		<MyWorldLogin
			v-model:show="meetingHandler.showMyWorldLoginDialog"
			v-model:joined="joined"
			v-model:inMeeting="inMeetingComponent"
			@update:display-name="updateDisplayName"
			@update:external-key="updateExternalKey" />

		<div class="d-none">
			<Audio
				:meeting-handler="meetingHandler"
				:audio="media"
				v-for="media in meetingHandler.audibleMedias"
				:key="media.id" />
		</div>

		<div
			class="row meeting-content"
			:class="
				useHelpers().isMobileChromeIos() && !meetingHandler.localAttendee?.avatarUrl?.includes('portrait')
					? 'no-margins'
					: ''
			"
			:style="contentHeightStyle">
			<div class="video-grid-container" :class="videoGridSize">
				<!-- <div class="video-container" :class="videoContainerSize" v-if="!meetingHandler.remoteScreenSharing">
					<VideoGrid :meeting-handler="meetingHandler" />
					<Thumbnails :meeting-handler="meetingHandler" v-if="meetingHandler.showThumbnailView" />
				</div> -->
				<div class="video-container" :class="videoContainerSize" v-if="!useHelpers().isMobileBrowser()">
					<!--v-if="meetingHandler.remoteScreenSharing && !useHelpers().isMobileBrowser()">-->
					<VideoGrid
						:meeting-handler="meetingHandler"
						:screenShare="true"
						:local-user-media="userMedia"
						:local-media-blurred="localMediaBlurred" />
					<!-- <Thumbnails :meeting-handler="meetingHandler" /> -->
				</div>

				<div
					class="video-container-mobile-screen flex-column"
					:class="videoContainerSize"
					v-if="useHelpers().isMobileBrowser()">
					<VideoGrid
						:meeting-handler="meetingHandler"
						:screenShare="true"
						:local-user-media="userMedia"
						:local-media-blurred="localMediaBlurred" />
				</div>

				<div
					class="world-container"
					:class="{ 'world-container-full': meetingHandler.sidePanelOpen }"
					v-show="myWorldOpen">
					<MyWorld :meeting-handler="meetingHandler" />
				</div>
			</div>
			<div class="side-panel-container" :class="sidePaneSize">
				<SidePanel :meeting-handler="meetingHandler" />
			</div>
			<WaitingRoom :meeting-handler="meetingHandler" :lobby-handler="lobbyHandler" @ignore-chime="ignoreChime" />
		</div>
		<Bot :meeting-handler="meetingHandler" />

		<Settings :meeting-handler="meetingHandler" />
		<RemoteMyWorldVue :meeting-handler="meetingHandler" />
		<VideoControls
			:meeting-handler="meetingHandler"
			:meeting-subject="meetingSubject"
			:bot-enabled="botEnabled"
			:localMediaBlurred="localMediaBlurred" />
		<div class="chat-notification-container" :class="{ 'side-panel-open': meetingHandler.sidePanelOpen }">
			<ChatNotification
				v-for="message in privateMessages"
				:key="message.channelId"
				@handle-message="handlePrivateMessage"
				:message="message.message"
				:channel-id="message.channelId" />
		</div>
	</div>
	<CallRating :meeting-handler="meetingHandler" v-else-if="pageState === 'completed'" />
	<ReportIssue :meeting-handler="meetingHandler" />
	<SendMeetingInvite :meeting-handler="meetingHandler"></SendMeetingInvite>
	<DebugInfo :meeting-handler="meetingHandler" :meeting="currentMeeting" v-if="pageState === 'meeting'"></DebugInfo>
	<SSOHandler />
</template>

<script lang="ts" setup>
	import DebugInfo from "@/components/DebugInfo.vue";
	import NavBar from "@/components/NavBar.vue";
	import VideoGrid from "@/components/VideoGrid.vue";
	import SidePanel from "@/components/SidePanel.vue";
	import Lobby from "@/components/Lobby.vue";
	import MyWorld from "@/components/MyWorld.vue";
	import Bot from "@/components/Bot.vue";
	import VideoControls from "@/components/VideoControls.vue";
	import Thumbnails from "@/components/Thumbnails.vue";
	import Settings from "@/components/Settings.vue";
	import RemoteMyWorldVue from "@/components/modals/RemoteMyWorld.vue";
	import CallRating from "@/components/CallRating.vue";
	import MyWorldLogin from "@/components/modals/MyWorldLogin.vue";
	import SSOHandler from "@/components/SSOHandler.vue";
	import SendMeetingInvite from "./modals/SendMeetingInvite.vue";
	import WaitingRoom from "@/components/WaitingRoom.vue";
	import Audio from "@/components/Audio.vue";
	import { ApiClient, Attendee, Identity, Meeting, MeetingState, ChatMessage } from "@liveswitch/sdk";
	import type {
		AttendeeListStateChangeEvent,
		AttendeeRole,
		ChatStateChangeEvent,
		JoinOptions,
		ManagerStateChangeEvent,
		UserMedia,
	} from "@liveswitch/sdk";
	import { onBeforeMount, computed, ref, reactive, onMounted, inject, watch } from "vue";
	import type { JoinConfiguration } from "@/classes/EventContracts";
	import useEventBus from "@/composables/useEventBus";
	import MeetingHandler from "@/classes/MeetingHandler";
	import LobbyHandler from "@/classes/LobbyHandler";
	import type { IChannelService } from "@/classes/ChannelService";
	import useHelpers from "@/composables/useHelpers";
	import {
		activeChatChannelId,
		attendeeJoined,
		setupChat,
		activeAttendeeId,
		setupNewPMMessageCallback,
	} from "@/composables/useChat";
	import { setupRecording } from "@/composables/useRecording";
	import Swal from "sweetalert2/dist/sweetalert2.js";
	import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";
	import { authenticationServiceKey, channelServiceKey, InjectionKeyAppInsights } from "@/composables/injectKeys";
	import useErrorHelpers from "@/composables/useErrorHelper";
	import { persistenAttendeeEnabled } from "@/composables/useLocalStorage";
	import UserActionHandler from "@/classes/UserActionHandler";
	import type { IAuthenticationService } from "@/classes/AuthenticationService";
	import ReportIssue from "./modals/ReportIssue.vue";
	import ChatNotification from "@/components/ChatNotification.vue";
	import type CanvasUserMedia from "@/classes/CanvasUserMedia";
	import { blurEnabled } from "@/composables/useLocalStorage";

	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const authenticationService = inject(authenticationServiceKey) as IAuthenticationService;
	authenticationService.appInsights = appInsights;
	const channelService = inject(channelServiceKey) as IChannelService;
	const inProgress = ref(false);
	const myWorldOpen = ref(false);
	const meetingHandler = reactive(new MeetingHandler()) as MeetingHandler;
	const lobbyHandler = reactive(new LobbyHandler()) as LobbyHandler;
	const meetingSubject = ref("");
	const pageState = ref<"lobby" | "meeting" | "completed">("lobby");
	const joined = ref(false);
	const inMeetingComponent = ref(true);
	const currentMeeting = ref<Meeting>();
	const currentMeetingState = ref<MeetingState>();
	const previousMeetingState = ref<MeetingState>();
	const reconnectingTimestamp = ref(0);
	const abortController: AbortController = new AbortController();
	const botEnabled = ref(false);
	const userMedia = ref<UserMedia>();
	const localMediaBlurred = ref(false);
	let channelId = "";
	let reconnecting = false;
	let reconnectTimeout = 0;
	let lastVideoPausedEventReceivedAt = 0;
	const privateMessages = ref([]);
	const meetingConfig = ref<JoinOptions>();
	const joinConfig = ref<JoinConfiguration>();

	const videoGridSize = computed(() => {
		return {
			"col-7": meetingHandler && meetingHandler.sidePanelOpen && useHelpers().isIpad(),
			"col-9":
				meetingHandler &&
				meetingHandler.sidePanelOpen &&
				!useHelpers().isMobileBrowser() &&
				!useHelpers().isIpad(),
			"col-12": !meetingHandler || (!meetingHandler.sidePanelOpen && !useHelpers().isMobileBrowser()),
			"col-0": meetingHandler.sidePanelOpen && useHelpers().isMobileBrowser(),
		};
	});

	const videoContainerSize = computed(() => {
		return {
			"col-0": myWorldOpen.value,
		};
	});

	const sidePaneSize = computed(() => {
		return {
			"col-0": !meetingHandler.sidePanelOpen && !useHelpers().isMobileBrowser(),
			"col-3": meetingHandler.sidePanelOpen && !useHelpers().isMobileBrowser() && !useHelpers().isIpad(),
			"col-5": meetingHandler.sidePanelOpen && useHelpers().isIpad(),
			"col-12": meetingHandler.sidePanelOpen && useHelpers().isMobileBrowser(),
		};
	});

	const contentHeightStyle = computed(() => {
		let style = "";
		if (useHelpers().isMobileBrowser()) {
			if (meetingHandler.localAttendee?.avatarUrl?.includes("portrait")) {
				style = "height: calc(100% - 74px)";
			}
			if (useHelpers().isMobileChromeIos()) {
				if (meetingHandler.localAttendee?.avatarUrl?.includes("portrait")) {
					style = "height: calc(100% - 134px)";
				} else {
					style = "height: calc(100% - 34px)";
				}
			}
		}
		return style;
	});

	const eventBus = useEventBus();
	onBeforeMount(() => {
		const params = useHelpers().getParams();
		if (useHelpers().isAuthenticatedUser() && params.has("referral")) {
			pageState.value = "completed";
			return;
		}
	});

	onMounted(() => {
		const params = useHelpers().getParams();

		eventBus.onEvent("toggle-local-my-world", (open: boolean | undefined) => {
			if (open == undefined) {
				myWorldOpen.value = !myWorldOpen.value;
			} else {
				myWorldOpen.value = open;
			}

			if (myWorldOpen.value) {
				appInsights.startTrackEvent("MyWorldViewLocal");
			} else {
				appInsights.stopTrackEvent("MyWorldViewLocal", useHelpers().getLoggingProperties());
			}
		});

		eventBus.onEvent("leave-call", async () => {
			params.set("callended", "true");
			await lobbyHandler.leave();
			window.history.replaceState({}, "", `${location.pathname}?${params}`);
			pageState.value = "completed";
			Swal.close();
		});

		eventBus.onEvent("local-video-ready", async (canvasMedia: CanvasUserMedia) => {
			await currentMeeting.value?.setLocalUserMedia(canvasMedia);
			canvasMedia.isStarted = true;
		});

		meetingHandler.appInsights = appInsights;
		lobbyHandler.appInsights = appInsights;
	});

	async function join(config: JoinConfiguration, rejoin: boolean = false) {
		if (inProgress.value) {
			console.log("Join meeting is already in progress.");
			return;
		}

		joinConfig.value = config;
		const joinStart = performance.now();

		botEnabled.value = config.botEnabled;
		localMediaBlurred.value = config.videoBlurred;
		inProgress.value = true;
		appInsights.startTrackEvent("JoinMeeting");

		if (config.passCode && window.location.href.indexOf("passcode=") === -1) {
			window.history.replaceState(
				{},
				document.title,
				window.location.href.split("#")[0] + "&passcode=" + config.passCode + "#"
			);
		}

		channelId = config.channelId;
		meetingSubject.value = config.subject;
		console.log(meetingSubject.value);
		localStorage.setItem("Username", config.userName);

		const identity = config.identity;
		meetingHandler.identity = config.identity;
		let meetingId: string = "";

		try {
			const template = await channelService.getChannelInviteTemplateAsync();

			if (template) {
				localStorage.setItem("InviteTemplate", template);
			}

			const newMeeting: Meeting = reactive(new Meeting({ identity: identity })) as Meeting;
			currentMeeting.value = newMeeting;

			newMeeting.stateChanged.bind(async (evt) => {
				console.debug(`Meeting State: Id=${evt.meeting.id}, State=${evt.meeting.state}`);
				currentMeetingState.value = evt.state;
				previousMeetingState.value = evt.previousState;

				if (evt.state === "reconnecting") {
					console.info("Attempting to reconnect to the meeting");
					reconnecting = true;
					reconnectingTimestamp.value = new Date().getTime();

					void newMeeting.log({
						eventName: "reconnectAttempt",
					});
				} else if (evt.state === "joined") {
					if (reconnecting && previousMeetingState.value === "reconnecting") {
						void newMeeting.log({
							eventName: "reconnectSuccess",
						});
					}

					reconnecting = false;
				}
			});

			// Destroy meeting after root hotreload

			if (globalThis.__meeting && (useHelpers().isLocal() || rejoin)) {
				const leavePromise = globalThis.__meeting.leave();

				if (!rejoin) {
					console.info("Vue hot reload");
					await leavePromise;
				}
			}

			globalThis.__meeting = newMeeting;

			if (rejoin) {
				appInsights.stopTrackEvent({
					name: "RejoinMeeting",
					properties: useHelpers().getLoggingProperties("MeetingRejoin", "MeetingRejoin", {
						meetingId: newMeeting.id,
					}),
				});
			}

			if (useHelpers().isMobileBrowser()) {
				//newMeeting.maxVisibleUserMedias = 4;
				//console.debug(`Setting Mobile MaxVisibleUserMedias=${newMeeting.maxVisibleUserMedias}`);
			} else if (useHelpers().isSafari()) {
				const max = Number.parseInt(import.meta.env.VITE_MAX_SAFARI_USERS);
				newMeeting.maxVisibleUserMedias = max;
				console.debug(`Setting Safari MaxVisibleUserMedias=${max}`);
			}
			const params = useHelpers().getParams();
			const userActionHandler = new UserActionHandler(
				newMeeting,
				meetingHandler,
				params.get("blurtime") ? parseFloat(params.get("blurtime") as string) * 1000 : undefined,
				params.get("unblurtime") ? parseFloat(params.get("unblurtime") as string) * 1000 : undefined
			);
			let audioInterval: number | undefined = 1000;

			if (params.get("audiolevelinterval")) {
				try {
					const parsed = parseInt(params.get("audiolevelinterval") as string);

					if (isNaN(parsed) || parsed == 0) {
						audioInterval = undefined;
					} else if (parsed != 1000) {
						audioInterval = parsed;
					}
				} catch (err) {
					console.error(`Unable to parse audioLevelInterval: ${params.get("audiolevelinterval")}`, err);
				}
			}

			meetingConfig.value = {
				displayName: config.userName,
				persistentAttendee: persistenAttendeeEnabled.value,
				roomKey: channelId,
				passcode: config.passCode ?? "",
				attendeePageSize: 1000,
				audioLevelInterval: audioInterval,
				remotePixelFeedback: import.meta.env.VITE_REMOTE_PIXEL_FEEDBACK === "true",
				localHealthOptions: {
					enabled: true,
				},
				localUserOptions: {
					degradationPreference: "maintain-resolution",
					webRtcDegradationPreferenceEnabled: true,
				},
				remoteHealthOptions: {
					enabled: true,
					healthyCheckThreshold: 10,
					packetLossThreshold: 0.1,
					unhealthyCheckThreshold: 5,
				},
				useAttendeeList: true,
				useCamera: true,
				useChat: true,
				useMicrophone: true,
				useScreenShare: true,
				useRemoteMedia: true,
			};

			newMeeting.attendees.added.bind(async (e) => {
				const newAttendee = e.element as Attendee;
				attendeeJoined(newAttendee);
				newAttendee.handRaised.bind(async (evt) => userActionHandler.handRaised(evt));
				newAttendee.handLowered.bind(async (evt) => userActionHandler.handLowered(evt));
				newAttendee.videoPaused.bind(async (evt) => {
					const incomingCallPauseEventRaisedInterval = 2000;
					const currentTime = Date.now();
					userActionHandler.videoPaused(evt);
					// this block handles incoming calls -- the sdk will constantly raise paused/resumed events
					if (currentTime < lastVideoPausedEventReceivedAt + incomingCallPauseEventRaisedInterval) {
						//await evt.attendee?.muteVideo();
					}
					lastVideoPausedEventReceivedAt = currentTime;
				});
				newAttendee.videoResumed.bind(async (evt) => {
					userActionHandler.videoResumed(evt);
					playVideos();
				});
			});

			newMeeting.left.bind(async (e) => {
				e.meeting.localUserMedia?.stop();
				e.meeting.localDisplayMedia?.stop();

				if (e.meeting.hasFailed && pageState.value === "meeting") {
					if (reconnecting) {
						config.audioMuted = e.meeting.localUserMedia.audioTrack.isMuted;
						config.videoMuted = e.meeting.localUserMedia.videoTrack.isMuted;
						void newMeeting.log({
							eventName: "reconnectError",
						});
					}

					setTimeout(async () => {
						let rejoinTask: Promise<void>;

						reconnectTimeout = setTimeout(async () => {
							if (meetingHandler.meeting?.state !== "joined") {
								abortController.abort("Max reconnect time reached");
								await meetingHandler.endCall("ReconnectionFailed");
								await newMeeting.leave();
								eventBus.loadingComplete();
								eventBus.navAlert(
									"We were unable to reconnect you to the meeting. Please check your internet connection and try again.",
									5000
								);
							}
						}, 20000);

						eventBus.emitEvent("loading", "Reconnecting to meeting...");
						console.info(`Rejoining meeting after connection failure...`);

						try {
							if (!config.userMedia.isStarted) {
								await config.userMedia.start();
							}

							rejoinTask = new Promise((resolve, reject) => {
								abortController.signal.addEventListener("abort", () => {
									reject("ReconnectTimeout");
								});

								resolve(rejoinMeeting(config, newMeeting));
							});

							await rejoinTask;
							console.info(
								`Rejoined meeting ${meetingHandler.meeting.id} in room ${meetingHandler.meeting.roomId} as attendee ${meetingHandler.meeting.localAttendee.id} after connection failure.`
							);
						} catch (error) {
							console.error("Could not rejoin meeting after connection failure.", error);
						} finally {
							inProgress.value = false;
							eventBus.emitEvent("loading-complete");
						}
					}, 0);
				}
			});

			meetingHandler.setMeeting(newMeeting);
			meetingHandler.setUserActionHandler(userActionHandler);

			if (meetingConfig.value.useChat) {
				meetingHandler.meeting.chat.stateChanged.bind(onChatStateChange);
			}

			if (meetingConfig.value.useAttendeeList) {
				meetingHandler.meeting.attendees.stateChanged.bind(onAttendeesStateChange);
			}

			if (meetingConfig.value.useCamera || meetingConfig.value.useMicrophone) {
				meetingHandler.meeting.originUserManager.stateChanged.bind(onOriginUserManagerStateChange);
			}

			if (meetingConfig.value.useScreenShare) {
				meetingHandler.meeting.originDisplayManager.stateChanged.bind(onOriginDisplayManagerStateChange);
			}

			if (meetingConfig.value.useRemoteMedia) {
				meetingHandler.meeting.edgeManager.stateChanged.bind(onEdgeManagerStateChange);
			}

			eventBus.emitEvent("loading", "Connecting to meeting...");
			appInsights.startTrackEvent("JoinMediaServerMeeting");
			newMeeting.log({
				eventName: "joinAttempt",
			});
			await newMeeting.join(meetingConfig.value, abortController.signal);

			appInsights.stopTrackEvent(
				"JoinMediaServerMeeting",
				useHelpers().getLoggingProperties("JoinMediaServerMeeting", "JoinMediaServerMeeting", {
					meetingId: newMeeting.id,
					deviceId: identity.deviceId,
				})
			);

			void newMeeting.log({
				eventName: "connectionSuccess",
				eventDuration: performance.now() - joinStart,
			});

			clearTimeout(reconnectTimeout);

			appInsights.trackEvent({
				name: "JoinedMeeting",
				properties: useHelpers().getLoggingProperties("JoinedMeeting", "JoinedMeeting", {
					deviceId: identity.deviceId,
				}),
			});

			appInsights.stopTrackEvent(
				"JoinMeeting",
				useHelpers().getLoggingProperties("JoinMeeting", "JoinMeeting", {
					meetingId: newMeeting.id,
					deviceId: identity.deviceId,
				})
			);
			meetingId = newMeeting.id;

			void newMeeting.log({
				eventName: reconnecting ? "rejoinSuccess" : "joinSuccess",
				eventDebug: { roomKey: config.channelId },
				eventDuration: performance.now() - joinStart,
			});
		} catch (error: any) {
			if (rejoin) {
				void currentMeeting.value?.log({
					eventName: "rejoinError",
					eventDebug: {
						error: error.message,
						roomKey: config.channelId,
					},
					eventDuration: performance.now() - joinStart,
				});
				throw error;
			}
			let message = "";
			let level = SeverityLevel.Critical;

			if (useErrorHelpers().isWrongPasscodeError(error)) {
				Swal.fire({
					title: "Error",
					text: "The passcode is incorrect.",
					confirmButtonText: "Close",
				});

				pageState.value = "lobby";
				eventBus.emitEvent("loading-complete");
				inProgress.value = false;
				return;
			}

			if (useErrorHelpers().isMediaError(error)) {
				message = useErrorHelpers().getMediaErrorMessage(error);
				level = SeverityLevel.Warning;
			} else {
				message = "An unexpected error occurred. Unable to join the meeting.";
			}

			appInsights.trackException(
				{
					exception: error,
					id: "JoinMeetingFailed",
					severityLevel: level,
				},
				useHelpers().getLoggingProperties("JoinMeetingFailed", message, {
					rejoining: reconnecting,
				})
			);

			void currentMeeting.value?.log({
				eventName: reconnecting ? "rejoinError" : "joinError",
				eventDebug: {
					error: error.message,
					roomKey: config.channelId,
				},
				eventDuration: performance.now() - joinStart,
			});

			console.error(`Unable to Join Meeting: Message=${message}`, error);

			Swal.fire({
				title: "Error",
				text: message,
				confirmButtonText: "Close",
			});

			pageState.value = "lobby";
			eventBus.emitEvent("loading-complete");
			inProgress.value = false;
		} finally {
		}

		eventBus.onEvent("signed-in-mid-meeting", async () => {
			const channel = JSON.parse(localStorage.getItem("Channel"));
			const userAccountId = localStorage.getItem("UserAccountId");

			// if this channels owner (useraccountid) is the same as yours then force them to be a host
			if (channel.UserAccountId == userAccountId) {
				// get a new identity
				const validationResponse = await authenticationService.validateAuthentication();
				if (validationResponse.Token) {
					useEventBus().emitEvent("loading", "Logging in...");
					try {
						const identity = new Identity({
							type: "externalToken",
							apiKey: validationResponse.ApiKey,
							identityServiceUrl: validationResponse.TokenUrl,
							externalToken: validationResponse.Token,
						});
						await identity.token();
						meetingHandler.isFreeAccount = validationResponse.FreeAccount;

						const meeting = new Meeting({ identity });
						await meeting.setLocalUserMedia(null);
						await meeting.setLocalDisplayMedia(null);
						// join this meeting
						await meeting.join({
							roomKey: channelId,
							passcode: config.passCode,
							displayName: meetingHandler.ninjaDisplayName,
							localHealthOptions: {
								enabled: true,
							},
							localUserOptions: {
								degradationPreference: "maintain-resolution",
								webRtcDegradationPreferenceEnabled: true,
							},
							useAttendeeList: true,
							useCamera: false,
							useChat: false,
							useMicrophone: false,
							useScreenShare: false,
							useRemoteMedia: false,
						} as JoinOptions);
						const localAttendee = meeting.attendees.get(meetingHandler.localAttendee.id);
						// set the current attendee to a host
						await localAttendee?.setRole("HOST");
						// leave
						meeting.leave();

						const channel = await channelService?.getChannelAsync(channelId);
						localStorage.setItem("Channel", JSON.stringify(channel));

						const apiClient = new ApiClient({ identity: identity });
						await authenticationService.createLobbyRoomIfNeeded(
							apiClient,
							channelId + "-LOBBY",
							config.passCode
						);

						//now I need to join the lobby
						if (channel.IsLobbyEnabled) {
							await lobbyHandler.joinLobby({
								admittedCallback: () => {
									//empty callback
								},
								channelKey: channelId,
								channelPasscode: config.passCode,
								deniedCallback: () => {
									// empty callback
								},
								identity: identity,
								isHost: true,
								userName: meetingHandler.localAttendee.displayName,
								meetingHandler: meetingHandler,
								webhookTimer: 30 * 1000,
							});
						}

						eventBus.emitEvent("host-signed-in-mid-meeting");
					} catch (err) {
						console.info(`failed to silently join as host ${err.message}`);
						appInsights.trackException(
							{
								exception: err,
								id: "SilentHostJoinFailed",
								severityLevel: SeverityLevel.Critical,
							},
							useHelpers().getLoggingProperties("SilentHostJoinFailed", err.message)
						);
					} finally {
						eventBus.emitEvent("loading-complete");
					}
				}
			}
		});
	}

	function isMeetingReadyForApp() {
		if (meetingConfig.value == null) return false;
		if (meetingHandler.meeting == null) return false;
		if (meetingConfig.value.useAttendeeList && !meetingHandler.meeting.attendees?.isStarted) return false;
		if (
			(meetingConfig.value.useCamera || meetingConfig.value.useMicrophone) &&
			!meetingHandler.meeting.originUserManager?.isStarted
		)
			return false;
		if (meetingConfig.value.useChat && !meetingHandler.meeting.chat?.isStarted) return false;
		if (meetingConfig.value.useScreenShare && !meetingHandler.meeting.originDisplayManager?.isStarted) return false;
		if (meetingConfig.value.useRemoteMedia && !meetingHandler.meeting.edgeManager?.isStarted) return false;
		return true;
	}

	async function completeJoinFlowIfReady() {
		if (joinConfig.value == null) return;
		if (!isMeetingReadyForApp()) return;
		console.info("All services are ready - Completing join flow");
		joined.value = true;
		eventBus.emitEvent("joined", meetingHandler);

		meetingHandler.botSocketUrl = joinConfig.value.botSocketUrl;
		meetingHandler.authService = authenticationService;

		meetingHandler.setMeetingInfo(
			joinConfig.value.userName,
			joinConfig.value.permissionsGranted,
			joinConfig.value.freeAccount
		);
		meetingHandler.features = joinConfig.value.features;
		setupRecording(meetingHandler);

		if (useHelpers().isMobileBrowser()) {
			setTimeout(() => {
				window.scrollTo(0, 1);
			}, 250);
		}

		if (joinConfig.value.videoMuted) {
			await meetingHandler.meeting.localAttendee.muteVideo();
		}
		if (joinConfig.value.audioMuted) {
			await meetingHandler.meeting.localAttendee.muteAudio();
		}

		addVisibilityChangeListener(meetingHandler.meeting.localAttendee);
		userMedia.value = joinConfig.value.userMedia;

		if (!blurEnabled.value) {
			await meetingHandler.meeting.setLocalUserMedia(joinConfig.value.userMedia);
		}

		await updateLocalMedia(joinConfig.value, meetingHandler.meeting);

		if (joinConfig.value.audioOutputDeviceId) {
			try {
				await meetingHandler.meeting.setAudioOutputDevice(joinConfig.value.audioOutputDeviceId);
			} catch (err: any) {
				console.error("Unable to set audio output device", err);
			}
		}

		if (useHelpers().isMobileBrowser()) {
			setTimeout(() => {
				window.scrollTo(0, 1);
			}, 250);
		}

		pageState.value = "meeting";
		eventBus.emitEvent("loading-complete");
		inProgress.value = false;
	}

	async function onChatStateChange(event: ChatStateChangeEvent) {
		console.info(`meeting chat state changed from ${event.previousState} to ${event.state}`);
		if (event.state == "started") {
			// ChatState
			await setupChat(meetingHandler.meeting);
			await completeJoinFlowIfReady();
		}
	}

	async function onAttendeesStateChange(event: AttendeeListStateChangeEvent) {
		console.info(`meeting attendee state changed from ${event.previousState} to ${event.state}`);
		if (event.state == "started") {
			// AttendeeListState
			await completeJoinFlowIfReady();
		}
	}
	async function onEdgeManagerStateChange(event: ManagerStateChangeEvent) {
		console.info(`meeting edge manager state changed from ${event.previousState} to ${event.state}`);
		if (event.state == "started") {
			// ManagerState
			await completeJoinFlowIfReady();
		}
	}
	async function onOriginDisplayManagerStateChange(event: ManagerStateChangeEvent) {
		console.info(`meeting origin display manager state changed from ${event.previousState} to ${event.state}`);
		if (event.state == "started") {
			// ManagerState
			await completeJoinFlowIfReady();
		}
	}
	async function onOriginUserManagerStateChange(event: ManagerStateChangeEvent) {
		console.info(`meeting origin user manager state changed from ${event.previousState} to ${event.state}`);
		if (event.state == "started") {
			// ManagerState
			await completeJoinFlowIfReady();
		}
	}

	function ignoreChime(ignoreChime: boolean) {
		meetingHandler.ignoreNextChime = ignoreChime;
	}

	async function updateLocalMedia(config: JoinConfiguration, meeting: Meeting) {
		if (meeting.state === "joined") {
			console.debug("Meeting has been joined successfully. Updating local media");
		} else {
			console.debug(`Meeting has not been joined successfully. Not starting local media: State=${meeting.state}`);
			return;
		}

		if (config.permissionsGranted) {
			await setMediaConstraints(meeting, config);
		}

		const autoMute = getAutoMute(meeting);
		const role: AttendeeRole = meeting.localAttendee.role;

		if (config.audioMuted || autoMute) {
			if (!reconnecting) {
				await meeting.localAttendee.muteAudio();
			}

			if (autoMute && !reconnecting) {
				useEventBus().navAlert("You have been automatically muted due to the size of the meeting", 5000);
			}
		}

		if (config.videoMuted) {
			await meeting.localAttendee.muteVideo();
		}

		if (role === "HOST" || role == "MODERATOR") {
			if (meeting.isAudioMutedOnJoin) {
				await meeting.localAttendee.enableAudioUnmute();
				if (!config.audioMuted) {
					await meeting.localAttendee.unmuteAudio();
				}
			}
			if (meeting.isVideoMutedOnJoin) {
				await meeting.localAttendee.enableVideoUnmute();
				if (!config.videoMuted) {
					await meeting.localAttendee.unmuteVideo();
				}
			}
		}

		console.debug(`Finished starting local media: Started=${meeting.localUserMedia.isStarted}`);
	}

	function getAutoMute(meeting: Meeting) {
		let maxUsers = 0;
		let autoMute = false;

		try {
			maxUsers = parseInt(import.meta.env.VITE_MAX_UNMUTED_USERS);
			autoMute = meeting.attendees.count > maxUsers;
		} catch (ex: any) {
			appInsights.trackException(
				{
					exception: ex,
					id: "ParseMaxUsersFailed",
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties("ParseMaxUsersFailed", ex.message)
			);
		}

		return autoMute;
	}

	async function setMediaConstraints(meeting: Meeting, config: JoinConfiguration) {
		if (config.audioConstraints) {
			config.audioConstraints.echoCancellation = true;
			config.audioConstraints.noiseSuppression = true;
			config.audioConstraints.autoGainControl = true;
		}

		const tasks = [];

		if (config.audioConstraints) {
			tasks.push(meeting.localUserMedia.audioTrack.setAutoGainControl(config.audioConstraints?.autoGainControl));
			tasks.push(
				meeting.localUserMedia.audioTrack.setEchoCancellation(config.audioConstraints?.echoCancellation)
			);
			tasks.push(
				meeting.localUserMedia.audioTrack.setNoiseSuppression(config.audioConstraints?.noiseSuppression)
			);
		}

		if (config.videoConstraints) {
			config.videoConstraints.facingMode = config.facingMode;
		}

		await Promise.all(tasks);
	}

	async function updateDisplayName(userName: string) {
		if (meetingHandler.localAttendee.displayName !== userName) {
			await meetingHandler.localAttendee.setDisplayName(userName);
		}
	}

	async function updateExternalKey(externalKey: string) {
		if (!meetingHandler.localAttendee.avatarUrl.includes(externalKey)) {
			await meetingHandler.setExternalKey(externalKey);
		}
	}

	async function rejoinMeeting(config: JoinConfiguration, meeting: Meeting) {
		if (meeting.state !== "joined" && meeting.state !== "joining") {
			console.warn("Failed to reconnect to the meeting. Rejoining the meeting");
			meeting.log({
				eventName: "rejoinAttempt",
			});
			appInsights.startTrackEvent("RejoinMeeting");
			await join(config, true);
		}
	}

	function playVideos() {
		const videos = document.querySelectorAll("video");
		videos.forEach((video) => {
			try {
				video.play();
			} catch (err) {
				console.error("Error playing video");
			}
		});
	}

	function addVisibilityChangeListener(attendee: Attendee) {
		if (!useHelpers().isMobileBrowser() && !useHelpers().isIpad()) return;

		let wasVideoMuted = attendee?.isVideoMuted;
		document.addEventListener("visibilitychange", async () => {
			if (document.visibilityState === "visible") {
				playVideos();
				if (!wasVideoMuted && attendee?.isVideoMuted) {
					await attendee?.unmuteVideo();
				}
			} else {
				wasVideoMuted = attendee?.isVideoMuted;
				await attendee?.muteVideo();
			}
		});
	}

	watch(
		() => meetingHandler.sidePanelOpen,
		(isOpen, wasOpen) => {
			if (!useHelpers().isMobileBrowser()) {
				const swalContainer = document.querySelector(".swal2-container");
				if (swalContainer) {
					if (!wasOpen && isOpen) {
						if (meetingHandler.showingWaitingRoomNotification) {
							swalContainer.classList.add("side-open");
							swalContainer.classList.remove("side-closed");
						}
					} else {
						if (meetingHandler.showingWaitingRoomNotification) {
							swalContainer.classList.remove("side-open");
							swalContainer.classList.add("side-closed");
						}
					}
				}
			}
		}
	);

	const handlePrivateMessage = (channelId: string, openMessage: Boolean) => {
		const index = privateMessages.value.findIndex((x) => x.channelId == channelId);
		if (openMessage) {
			//open the sidepanel
			activeAttendeeId.value = privateMessages.value[index].message.attendee.id;
			if (!meetingHandler.chatOpen) {
				meetingHandler.toggleChat();
			}
		}
		privateMessages.value.splice(index, 1);
	};

	setupNewPMMessageCallback(async (channelId: string, message: ChatMessage) => {
		// nothing happens on default chat channel
		if (channelId === "default") {
			return;
		}
		// nothing happens if the panel is open and you're already in this pm
		if (channelId !== "default" && activeChatChannelId.value === channelId && meetingHandler.chatOpen) {
			return;
		}

		if (privateMessages.value.findIndex((x) => x.channelId == channelId) == -1) {
			privateMessages.value.push({
				channelId: channelId,
				message: message,
			});

			if (useHelpers().isMobileBrowser()) {
				window.setTimeout(() => {
					const index = privateMessages.value.findIndex((x) => x.channelId == channelId);
					if (index !== -1) {
						privateMessages.value.splice(index, 1);
					}
				}, 5000);
			}
		}
	});
</script>

<style>
	.col-0 {
		/* display: none !important; */
		height: 0% !important;
		width: 0% !important;
		transition: width 0.35s;
	}

	.video-container {
		height: 100%;
		overflow: hidden;
		display: flex;
		width: 100%;
		flex-direction: row;
		/*flex-wrap: wrap;*/
		justify-content: center;
	}

	.video-grid-container {
		max-height: 100%;
		display: flex;
		transition: all 0.35s;
	}

	.video-grid-container.col-9 {
		padding-right: 0;
	}

	.side-panel-container {
		height: 100%;
		padding-right: 0 !important;
		transition: width 0.35s;
	}

	::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		border-radius: 4px;
		-webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
	}

	::-webkit-scrollbar {
		width: 6px;
	}

	.ff-orpheuspro {
		font-family: "Inter_Medium";
	}

	.world-container {
		margin: auto;
		color: black;
		width: 100%;
		max-height: 100%;
		overflow: auto;
	}

	.world-container-full {
		width: 100% !important;
	}

	.world-container .fa-times:hover {
		background-color: rgb(255 255 255 / 30%);
	}

	.auth-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
	}

	.auth-container .form-group {
		display: flex;
		flex-direction: column;
		width: 75%;
	}

	.video-container-mobile-screen {
		width: 100%;
	}

	@media (min-width: 320px) and (max-width: 767px), (orientation: landscape) and (max-height: 420px) {
		.side-panel-container {
			padding: 0 !important;
		}

		.world-container {
			width: 100%;
		}

		.video-grid-container {
			padding: 0 !important;
		}
	}

	@media (orientation: landscape) and (max-height: 319px) {
		.video-grid-container.col-12 {
			padding-left: 10% !important;
			padding-right: 10% !important;
		}
	}

	@media (orientation: landscape) and (min-height: 320px) and (max-height: 567px) {
		.video-grid-container.col-12 {
			padding: 0 !important;
		}
	}

	@media (min-width: 768px) and (min-height: 568px) and (max-height: 768px) {
		.video-grid-container.col-12 {
			padding-left: 12% !important;
			padding-right: 12% !important;
		}

		.video-grid-container.col-9 {
			padding-left: 5%;
			padding-right: 5%;
		}
	}

	@media (min-width: 1921px) and (min-height: 1081px) {
		.video-grid-container.col-12 {
			padding-left: 15%;
			padding-right: 15%;
		}

		.video-grid-container.col-9 {
			width: 80%;
			padding-left: 8%;
			padding-right: 8%;
		}

		.side-panel-container.col-3 {
			width: 20%;
		}
	}

	@media (min-width: 1000px) and (min-height: 1600px) {
		.world-container {
			width: 95%;
		}
	}

	@media (min-width: 1200px) {
		.world-container {
			width: 75%;
		}
	}

	.world-container::-webkit-scrollbar {
		width: 12px;
	}

	/* @media (max-width: 1680px) and (min-height: 800px) {
		.video-grid-container.col-12 {
			padding-left: 10%;
			padding-right: 10%;
		}

		.video-grid-container.col-9 {
			padding-left: 2%;
			padding-right: 2%;
		}
	} */

	body.swal2-toast-shown .swal2-container.swal2-top-end.side-open {
		right: 25%;
		transition: right 0.35s;
	}

	body.swal2-toast-shown .swal2-container.swal2-top-end.side-closed {
		right: 0;
		transition: right 0.35s;
	}

	.meeting-content.no-margins .solo-mobile-invite {
		margin-top: 0;
	}

	.meeting-content.no-margins .solo-mobile-invite .share-text {
		margin-bottom: 0;
	}

	.meeting-content.no-margins .solo-mobile-invite .share-meeting {
		margin-top: 5px;
	}

	.chat-notification-container {
		position: fixed;
		display: flex;
		max-width: 250px;
		bottom: 115px;
		overflow: visible;
		flex-direction: column;
		justify-content: flex-end;
		right: 0; /* this will change based on if the panel is open or not */
		z-index: 100;
		margin-right: 15px;
		height: 0;
	}

	.chat-notification-container.side-panel-open {
		right: 25%;
	}

	@media (max-width: 768px) {
		.chat-notification-container {
			top: 55px;
			flex-direction: column-reverse;
			justify-content: flex-end;
			width: 100%;
			max-width: 100%;
			align-items: center;
			margin-right: 0px;
		}

		.chat-notification-container.side-panel-open {
			right: 0;
		}
	}
</style>
