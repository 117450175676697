<template>
	<transition name="fade">
		<div
			class="custom-modal modal show fade invite-modal"
			tabindex="-1"
			aria-hidden="true"
			v-show="show"
			@click="tryClose">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header justify-content-start">
						<h5 class="modal-title invite-modal-title">Send a Meeting Invite</h5>

						<div class="d-flex justify-content-end mb-2">
							<i class="fal fa-times close" @click="close"></i>
						</div>
					</div>
					<div class="modal-body">
						<div class="mb-3">
							<input type="email" class="form-control input-invite" :value="title" readonly />
						</div>
						<div class="d-flex mb-3 align-items-center">
							<Multiselect
								id="multiselect"
								ref="multiSelect"
								class="input-invite"
								v-model="emails"
								mode="tags"
								placeholder="Enter an email address"
								:autocomplete="'off'"
								:close-on-select="useHelpers().isMobileBrowser()"
								:clearOnBlur="false"
								:searchable="true"
								:create-option="true"
								:inputType="'email'"
								:noOptionsText="''"
								:noResultsText="''"
								:options="members"
								@option="emailAdded" />

							<button
								type="button"
								class="btn-google-contacts btn d-flex"
								:class="{ fade: !connectGoogleContacts }"
								@click="login"
								v-if="connectGoogleContacts">
								<div class="icon d-flex">
									<img src="../../assets/img/btn_google_light_normal_ios.svg" alt="Google" />
								</div>
								<div class="text d-flex">Connect Google Contacts</div>
							</button>
						</div>
						<div class="mb-3">
							<div
								class="form-control invite-body"
								id="invite-body"
								v-html="inviteBodyHtml"
								:ref="inviteBodyText"></div>
						</div>
					</div>
					<div class="modal-footer justify-content-end">
						<button
							type="button"
							class="btn btn-primary rounded"
							data-bs-dismiss="modal"
							@click="sendInvite">
							Send Invite
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script lang="ts" setup>
	import type { ChannelDetails } from "@/classes/ChannelService";
	import { inject, onMounted, ref, type ComponentPublicInstance } from "vue";
	import useEventBus from "../../composables/useEventBus";
	import Multiselect from "@vueform/multiselect";
	import {
		MeetingInviteService,
		type IMeetingInviteService,
		type MeetingInviteModel,
	} from "@/classes/MeetingInviteService";
	import type { RoomSipNumber } from "@liveswitch/sdk/dist/api/models";
	import useLocalStorage from "@/composables/useLocalStorage";
	import type { ApplicationInsights } from "@microsoft/applicationinsights-web";
	import { authenticationServiceKey, InjectionKeyAppInsights } from "@/composables/injectKeys";
	import useHelpers from "@/composables/useHelpers";
	import { googleSdkLoaded } from "vue3-google-login";
	import type { AuthenticationValidationResponse, IAuthenticationService } from "@/classes/AuthenticationService";
	import Swal from "sweetalert2";

	const authenticationService = inject(authenticationServiceKey) as IAuthenticationService;
	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const multiSelect = ref<Multiselect>();
	const emails = ref([]);
	const title = ref("");
	const inviteBodyHtml = ref("");
	const inviteBodyText = ref("");
	const tenantId = ref("");
	const show = ref(false);
	const eventBus = useEventBus();
	const members = ref<Object[]>([]);
	const connectGoogleContacts = ref(false);
	const googleHint = ref("");

	defineEmits(["invite", "update:show"]);

	const login = () => {
		googleSdkLoaded((google) => {
			google.accounts.oauth2
				.initCodeClient({
					client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
					scope: import.meta.env.VITE_GOOGLE_CONTACT_SCOPE,
					ux_mode: "popup",
					hint: googleHint.value,
					callback: async (response) => {
						console.log("Handle the response", response);
						const approved = await authenticationService.grantGoogleContactAccess(response);

						if (!approved) {
							Swal.fire({
								title: "Error",
								text: "Failed to grant access to your Google contacts",
								confirmButtonText: "Close",
							});
						} else {
							connectGoogleContacts.value = false;
							useEventBus().navAlert("Your Google Contacts are now connected.", 5000);
							await initializeTenantMembers();
						}
					},
				})
				.requestCode();
		});
	};

	onMounted(async () => {
		eventBus.onEvent("joined", async () => {
			await initializeTenantMembers();
		});

		eventBus.onEvent("full-auth-validated", async (authResponse: AuthenticationValidationResponse) => {
			connectGoogleContacts.value =
				useHelpers().isAuthenticatedUser() &&
				authResponse.GoogleAccount &&
				!authResponse.GoogleGrantedScopes?.includes(import.meta.env.VITE_GOOGLE_CONTACT_SCOPE);
			googleHint.value = authResponse.Email;
		});

		eventBus.onEvent("signed-in-mid-meeting", async () => {
			await initializeTenantMembers();
		});

		eventBus.onEvent("toggle-invite", (newValue: boolean | undefined) => {
			if (newValue != undefined) {
				show.value = newValue;
			} else {
				show.value = !show.value;
			}

			if (show.value) {
				populateInviteData();
				appInsights.trackMetric(
					{
						name: "ToggleMeetingInvite",
						average: 1,
					},
					useHelpers().getLoggingProperties()
				);
			}
		});
	});

	async function initializeTenantMembers() {
		const tenantId = localStorage.getItem("TenantId");
		const inviteService: IMeetingInviteService = new MeetingInviteService();
		const tenantMembers = await inviteService.getMembersAsync(tenantId);

		if (tenantMembers && tenantMembers.length > 0) {
			members.value = [];

			tenantMembers.forEach((member, index) => {
				members.value?.push({
					label: member.UserEmail,
					value: member.UserEmail,
				});
			});
		}
	}

	function tryClose(e: MouseEvent) {
		if ((e.target as HTMLDivElement)?.classList.contains("fade")) {
			close();
		}
	}

	function close() {
		eventBus.emitEvent("toggle-invite", false);
	}

	function populateInviteData() {
		let channel: ChannelDetails = JSON.parse(localStorage.getItem("Channel") ?? "");

		if (channel) {
			setTimeout(() => {
				if (multiSelect.value) {
					const input = document.getElementById("multiselect") as HTMLInputElement;

					input.autocomplete = "off";
					input.maxLength = 50;
					input.onblur = () => {
						if (!input.value || input.value == "") {
							return;
						}

						if (!useHelpers().isValidEmail(input.value)) {
							eventBus.navAlert("Please enter a valid email address.", 3000);
							return;
						}

						multiSelect.value.select({ label: input.value, value: input.value });
					};
				}
			}, 500);

			let passcode = ""; // TODO: Get from meeting
			var dialinText = "";

			const dialInNumbers: RoomSipNumber[] = useLocalStorage().getDialInNumbers(channel.ChannelKey);

			for (var i = 0; i < dialInNumbers.length; i++) {
				if (i > 1) {
					dialinText += "<br/>";
				}

				const dialInNumber = dialInNumbers[i];

				let dialInPasscode = "";
				let phoneNumber = "";
				let rawNumber = "";

				if (dialInNumber) {
					phoneNumber = dialInNumber.phoneNumber?.match(/\d+/)[0];
					rawNumber = phoneNumber;
					phoneNumber = `${phoneNumber.substring(0, 1)}-${phoneNumber.substring(
						1,
						4
					)}-${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7, 11)}`;
				}

				if (dialInNumber.numberPasscode) {
					dialInPasscode = `${dialInNumber.numberPasscode}#`;
				}

				var oneClickDialInUrl = `${import.meta.env.VITE_USER_SITE_URL}/Dial/${rawNumber},${dialInPasscode}`;
				var oneClickDialInDisplay = `${rawNumber},${dialInPasscode}`;
				var oneClickDialInLink = `<a href="${oneClickDialInUrl}" target="_blank">${oneClickDialInDisplay}</a>`;

				dialinText += `US One Click Dial-in Link: ${oneClickDialInLink}<br/>`;
				dialinText += `Phone Number: ${rawNumber.length == 11 ? phoneNumber : rawNumber}<br/>`;
				dialinText += `Passcode: ${dialInPasscode}`;

				// TODO: Host passcode
			}

			var channelPasscode = "";

			if (passcode) {
				channelPasscode = `<br/>Passcode: ${passcode}`;
			}

			var inviteTitle = channel.MeetSubject ? channel.MeetSubject : channel.ChannelName;
			title.value = inviteTitle ?? "";
			var template = localStorage.getItem("InviteTemplate");
			let channelUrl = window.location.toString().split("#")[0] + "#";
			let username = localStorage.getItem("Username");
			let body = template
				?.replaceAll("{0}", username ?? "")
				.replaceAll("{1}", channelUrl)
				.replaceAll("{1:Plain}", channelUrl.replaceAll(":", "<span>:</span>").replaceAll(".", "<span>.</span>"))
				.replaceAll("{2}", channelPasscode)
				.replaceAll("{3}", dialinText)
				.replaceAll("{4}", title.value);
			inviteBodyHtml.value = body ?? "";
		}
	}

	function emailAdded(email: string) {
		if (!useHelpers().isValidEmail(email)) {
			eventBus.navAlert("Please enter a valid email address.", 3000);
			var error = {
				name: "SOValidationError",
				message: "Invalid email address",
				throw: true,
			} as Error;
			throw error;
		}
	}

	async function sendInvite() {
		if (emails.value.length == 0) {
			eventBus.navAlert("Enter at least one valid email address", 3000);
			return;
		}

		useEventBus().emitEvent("loading", "Sending invite...");

		const channelJson = localStorage.getItem("Channel");

		if (channelJson) {
			let channel: ChannelDetails = JSON.parse(channelJson);
			const dialInNumbers: RoomSipNumber[] = useLocalStorage().getDialInNumbers(channel.ChannelKey);

			let model: MeetingInviteModel = {
				channelId: channel.ChannelId ?? "",
				channelKey: channel.ChannelKey ?? "",
				inviteUrl: window.location.toString().split("#")[0] + "#",
				inviterName: localStorage.getItem("Username") ?? "",
				receiverEmails: emails.value,
				messageHTML: inviteBodyHtml.value,
				messageText: document.getElementById("invite-body").innerText,
				channelPasscode: "",
				invitedUserAccountIds: [],
				serviceNodeId: "",
			};

			if (dialInNumbers && dialInNumbers.length > 0) {
				model.inboundNumber = dialInNumbers[0].PhoneNumber;
			}

			let service: IMeetingInviteService = new MeetingInviteService();
			let response: any = await service.sendAsync(model);
			useEventBus().emitEvent("loading-complete");

			if (response && response.status) {
				emails.value = [];
				close();
				eventBus.navAlert("Meeting invite sent successfully", 5000);
				appInsights.trackMetric(
					{
						name: "MeetingInviteSent",
						average: 1,
					},
					useHelpers().getLoggingProperties()
				);
			} else {
				eventBus.navAlert("An error occurred while sending the meeting invite", 5000);
				appInsights.trackMetric(
					{
						name: "MeetingInviteError",
						average: 1,
					},
					useHelpers().getLoggingProperties()
				);
			}
		}
	}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
	.invite-modal {
		color: black;
		font-family: "Inter_Regular";
	}

	.invite-modal-title {
		font-family: "Inter_Regular";
		font-size: 24px;
		font-weight: bold;
		color: #323b4b;
		width: 100%;
	}

	.invite-label {
		font-size: 14px;
		font-weight: 600;
		color: #4e5d78;
		margin-bottom: 4px;
	}

	.invite-body {
		color: #8a94a6;
		max-height: 350px;
		overflow-y: auto;
		width: 100%;
		padding: 12px;
		border-radius: 4px;
		box-shadow: 1px 4px 4px 2px rgb(0 0 0 / 5%);
		border: solid 1px #f8f8f8 !important;
		font-family: Inter_Regular;
		font-size: 16px;
		font-weight: 500;
		color: #8a94a6;
		word-wrap: break-word;
		word-break: break-word;
	}

	.input-invite {
		height: 45px;
		flex-grow: 0;
		margin: 8px 10px 20px 2px;
		border-radius: 4px;
		box-shadow: 1px 4px 4px 2px rgb(0 0 0 / 5%);
		border: solid 1px var(--white);
		color: #323b4b;
		background-color: #fff !important;
	}

	.multiselect.input-invite {
		border: 1px solid #ced4da;
		margin-top: 0;
		margin-bottom: 0;
	}

	.btn-google-contacts {
		padding: 0 !important;
		height: 44px;
		color: white !important;
		border-radius: 0 !important;
		border: 1px solid #4285f4 !important;
		flex: 1 0 auto;
		font-size: 13px;
	}

	.btn-google-contacts img {
		height: 44px;
	}

	.btn-google-contacts:hover {
		background-color: unset !important;
	}

	.btn-google-contacts .text {
		background: #4285f4;
		height: 100%;
		justify-content: center;
		align-items: center;
		padding: 0 8px;
	}

	.modal-header,
	.modal-footer {
		border: none;
	}

	.multiselect-no-results,
	.multiselect-options,
	.multiselect-no-options {
		/*display: none !important;*/
	}

	.multiselect-tag {
		background-color: #323b4b;
		font-weight: normal;
		font-family: "Inter_Medium";
	}

	.multiselect.is-active {
		border-color: #86b7fe;
		box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
	}
</style>
