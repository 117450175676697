﻿import APIServiceClient from "@/api/api-service";
import moment from "moment-timezone";
import { ApiClient, BadRequestError, Identity } from "@liveswitch/sdk";
import type { Room, RoomSipNumber } from "@liveswitch/sdk/dist/api/models";
import type { AxiosResponse } from "axios";
import { useCookies } from "vue3-cookies";
import { getCookie, removeCookie } from "typescript-cookie";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";
import useHelpers from "@/composables/useHelpers";

export interface IAuthenticationService {
	isAutomatedTest(): boolean;
	validateAuthentication(): Promise<AuthenticationValidationResponse>;
	clearAuthenticationCookies(): void;
	getFingerprintPromise(): Promise<any>;
	getFingerprint(): Promise<object>;
	signIn(signInRequest?: SignInRequest, ssoResponse?: ApiSignInResponse): Promise<SignInResponse>;
	doResetPasswordAsync(platformAccountId: string, verificationCode: string, newPassword: string): Promise<ResponseMessage>;
	signInSSO(): ApiSignInResponse;
	resetPasswordAsync(email: string): Promise<ResetPasswordResponse>;
	getApiEndpoint(): string;
	signUp(signUpRequest: SignUpRequest): Promise<SignUpResponse>;
	clearDeadCookies(): Promise<void>;
	associateTenantUser(userName: string, dislayName: string): Promise<Identity>;
	grantGoogleContactAccess(authCodeResponse: any): Promise<boolean>;
	appInsights: ApplicationInsights;
	createLobbyRoomIfNeeded(apiClient: ApiClient, lobbyKey: string, passCode: string): Promise<void>;
}

// TODO: Ensure SPs are updated in QA and Prod
export class AuthenticationService implements IAuthenticationService {
	private _appInsights: ApplicationInsights = null;
	private mediaServerProperties?: MediaServerProperties;
	private apiServiceClient: APIServiceClient = new APIServiceClient(this.getApiEndpoint());

	private readonly fingerprintPromise = FingerprintJS.load();
	private fingerprint?: object;

	set appInsights(appInsights: ApplicationInsights) {
		this._appInsights = appInsights;
	}

	public isAutomatedTest(): boolean {
		const automated = import.meta.env.VITE_IS_AUTOMATED_TEST;
		return automated;
	}

	public getApiEndpoint(): string {
		//const endpoint = this.apiEndpoints[window.location.host];
		const endpoint = import.meta.env.VITE_API_URL;
		console.log(`Auth Endpoint: ${endpoint}`);

		if (endpoint) {
			return endpoint;
		}

		throw `API endpoint not found for ${window.location.host}.`;
	}

	public getCaptchaKey(): string {
		const captchaKey = import.meta.env.VITE_CAPTCHA_KEY;
		if (!captchaKey) {
			throw `CaptchaKey not found for ${window.location.host}.`;
		}
		return captchaKey;
	}

	public async clearDeadCookies(): Promise<void> {
		const response = await this.apiServiceClient.executeRequest<any>("api/account/cookies/clear/false", "GET", undefined, undefined, undefined, true);
		console.debug(response);
	}

	public async resetPasswordAsync(email: string): Promise<ResetPasswordResponse> {
		const request = {
			url: window.location.toString(),
			model: {
				userName: email,
				platform: window.navigator.platform,
				userAgent: window.navigator.userAgent,
				originUrl: window.location.origin,
			}
		};

		const response = await this.apiServiceClient.executeRequest<ResetPasswordResponse>(
			"api/Account/ResetPassword",
			"POST",
			request,
			undefined,
			undefined,
			true
		);

		return new ResetPasswordResponse(response.data, response.status === 200);
	}

	public async doResetPasswordAsync(platformAccountId: string, verificationCode: string, newPassword: string): Promise<ResponseMessage> {
		const request = {
			ValidationTokenSalt: platformAccountId,
			ValidationCode: verificationCode,
			NewPassword: newPassword
		};

		const response = await this.apiServiceClient.executeRequest<ResponseMessage>(
			"api/Account/SetPasswordByPlatformId",
			"POST",
			request,
			undefined,
			undefined,
			true
		);
		return new ResponseMessage(response.data, response.status === 200);
	}

	public async signUp(signUpRequest: SignUpRequest): Promise<SignUpResponse> {
		const apiSignUpResponse = await this.apiSignUp(signUpRequest);
		if (!apiSignUpResponse.isValid()) {
			return new SignUpResponse(apiSignUpResponse);
		}

		const signInRequest = new SignInRequest(
			"USER",
			signUpRequest.UserName,
			signUpRequest.Password,
			signUpRequest.Platform,
			signUpRequest.SharedFingerprint,
			false,
			undefined,
			undefined,
			signUpRequest.InviteToken,
			signUpRequest.Timezone
		);
		const signInResponse = await this.signIn(signInRequest);
		return new SignUpResponse(signInResponse);
	}

	public signInSSO(): ApiSignInResponse {
		let apiSignInResponse: ApiSignInResponse;
		const base64Response: string | undefined = getCookie("X-Sign-In-Response");

		if (base64Response) {
			const serializedResponse = atob(base64Response);
			apiSignInResponse = new ApiSignInResponse(JSON.parse(serializedResponse));

			removeCookie("X-Sign-In-Request", {
				domain: import.meta.env.VITE_COOKIE_DOMAIN
			});
			removeCookie("X-Sign-In-Response", {
				domain: import.meta.env.VITE_COOKIE_DOMAIN
			});

			localStorage.setItem("UserAccountId", <string>apiSignInResponse.UserAccountId);
			localStorage.setItem("Username", <string>apiSignInResponse.DisplayName);
			localStorage.setItem("TenantId", <string>apiSignInResponse.SelectedTenantId);
		} else {
			window.location.href = window.location.origin + window.location.pathname;

			// Won't happen due to reload, but it makes inspections happy.
			apiSignInResponse = new ApiSignInResponse();
			apiSignInResponse.Error = true;
			apiSignInResponse.Message = { Reason: "X-Sign-In-Response cookie not found." };
		}

		return apiSignInResponse;
	}

	public async signIn(signInRequest?: SignInRequest, ssoResponse?: ApiSignInResponse): Promise<SignInResponse> {
		let apiSignInResponse: ApiSignInResponse = new ApiSignInResponse();

		if (signInRequest) {
			const apiSignInRequest = new ApiSignInRequest(signInRequest);
			apiSignInResponse = await this.apiSignIn(apiSignInRequest);
		} else if (ssoResponse) {
			apiSignInResponse = ssoResponse;
			console.info("Processing SSO response");
		}

		if (!apiSignInResponse.isValid() || signInRequest?.AccountType == "ANONYMOUS") {
			localStorage.removeItem("TenantId");
			return new SignInResponse(apiSignInResponse);
		}

		localStorage.setItem("UserAccountId", <string>apiSignInResponse.UserAccountId);

		if (apiSignInResponse.SelectedTenantId) {
			localStorage.setItem("TenantId", <string>apiSignInResponse.SelectedTenantId);
		}

		const mediaServerSignInRequest = new MediaServerSignInRequest(
			apiSignInResponse.UserName,
			apiSignInResponse.DisplayName,
			apiSignInResponse.DeviceIdentifier,
			apiSignInResponse.DeviceFingerprint
		);

		const mediaServerSignInResponse = await this.mediaServerSignIn(mediaServerSignInRequest);

		if (!mediaServerSignInResponse.isValid()) {
			return new SignInResponse(mediaServerSignInResponse);
		}

		// this needs to update for -lobby
		const apiCreatePermanentRoomResponse = await this.apiCreatePermanentRoom();
		if (!apiCreatePermanentRoomResponse.isValid()) {
			return new SignInResponse(apiCreatePermanentRoomResponse);
		}

		const mediaServerProperties = await this.getMediaServerProperties();

		let identity;
		try {
			identity = new Identity({
				type: "externalToken",
				apiKey: mediaServerProperties.ApiKey,
				identityServiceUrl: mediaServerProperties.TokenUrl,
				externalToken: mediaServerSignInResponse.Token
			});
			await identity.token();
		} catch (err) {
			if (err instanceof BadRequestError && JSON.stringify(err).indexOf("UserAlreadyTakenException") != -1) {
				// Attempts to associate the user with a tenant if a user account with an email already exists

				identity = await this.associateTenantUser(apiSignInResponse.UserName, apiSignInResponse.DisplayName);
			} else {
				throw err;
			}
		}

		const roomKey = apiCreatePermanentRoomResponse.Channel?.ChannelKey;

		if (!roomKey) {
			// Should never happen.
			throw "Room key not found.";
		}

		try {
			const mediaServerCreateRoomResponse = await this.mediaServerCreateRoom(identity, roomKey);
			if (!mediaServerCreateRoomResponse.isValid()) {
				return new SignInResponse(mediaServerCreateRoomResponse);
			}

			if (mediaServerCreateRoomResponse.room) {
				try {
					const apiClient = new ApiClient({ identity: identity });
					const rooms = await apiClient.listRooms({
						key: roomKey,
					});
					if (rooms != null && rooms.values.length) {
						const roomId = rooms.values[0].id;
						if (roomId) {
							//enable storage
							try {
								await apiClient.enableStorageByRoomId(roomId);
							} catch (error: any) {
								console.error(`Error enabling storage roomId: ${roomId}`, error);
								this._appInsights.trackException(
									{
										exception: error,
										id: "StorageFailedToEnable",
										severityLevel: SeverityLevel.Critical,
									},
									useHelpers().getLoggingProperties("StorageFailedToEnable", error.Message)
								);
							}
							//enable sip
							try {
								await apiClient.enableSipByRoomId(roomId);
							} catch (error: any) {
								console.error(`Error enabling Sip roomId: ${roomId}`, error);
								this._appInsights.trackException(
									{
										exception: error,
										id: "SipFailedToEnable",
										severityLevel: SeverityLevel.Critical,
									},
									useHelpers().getLoggingProperties("SipFailedToEnable", error.Message)
								);
							}
						}
					}
				} catch (exception: any) {
					console.error(`Error getting room that user owns: ${roomKey} ${exception.Message}`, exception);
					this._appInsights.trackException(
						{
							exception: exception,
							id: "FailedToGetRoom",
							severityLevel: SeverityLevel.Critical,
						},
						useHelpers().getLoggingProperties("FailedToGetRoom", exception.Message)
					);
				}
			}

			const numbers = mediaServerCreateRoomResponse.room?.numbers;

			if (numbers && numbers.length > 0) {
				const number: RoomSipNumber = numbers[0];
			}
		} catch (err: any) {
			console.error(`Error creating LS2 room: Key=${roomKey}`, err);
			throw err;
		}

		const signInResponse = new SignInResponse();
		signInResponse.CurrentChannelOwner = apiSignInResponse.CurrentChannelOwner;
		signInResponse.Error = apiSignInResponse.Error;
		signInResponse.Message = apiSignInResponse.Message;
		return signInResponse;
	}

	public async associateTenantUser(userName: string, dislayName: string): Promise<Identity> {
		const mediaServerProperties = await this.getMediaServerProperties();
		const anonymousIdentity = new Identity({
			type: "anonymous",
			apiKey: mediaServerProperties.ApiKey,
			identityServiceUrl: mediaServerProperties.TokenUrl,
			displayName: dislayName
		});

		const apiClient = new ApiClient({ identity: anonymousIdentity });
		const existingUser = await apiClient.getUserAccountByUserName(userName);

		try {
			await apiClient.createTenantUserAccount({
				userAccountId: existingUser.value.id as string,
				roleType: "MEMBER",
				roleStatus: "ACTIVE",
				tenantId: mediaServerProperties.TenantId
			});
		} catch (tenantErr: any) {
			if (tenantErr.message.indexOf('Cannot insert duplicate key row') == -1) {
				throw tenantErr
			}
		}

		let mediaServerSignInRequest = {
			DisplayName: dislayName,
			UserAccountId: existingUser.value.id,
			UserName: userName
		} as MediaServerSignInRequest;
		const mediaServerSignInResponse = await this.mediaServerSignIn(mediaServerSignInRequest);

		return new Identity({
			type: "externalToken",
			apiKey: mediaServerProperties.ApiKey,
			identityServiceUrl: mediaServerProperties.TokenUrl,
			externalToken: mediaServerSignInResponse.Token
		});
	}

	private async apiSignUp(request: SignUpRequest): Promise<ApiSignUpResponse> {
		const response = await this.apiServiceClient.executeRequest<ApiSignUpResponse>(
			"api/Account/SignUp",
			"POST",
			request
		);
		response.data = new ApiSignUpResponse(response.data);
		this.checkForErrors(response);
		return response.data;
	}

	private async apiSignIn(request: ApiSignInRequest): Promise<ApiSignInResponse> {
		const response = await this.apiServiceClient.executeRequest<ApiSignUpResponse>(
			"api/Account/SignIn",
			"POST",
			request,
			undefined,
			undefined,
			true
		);

		response.data = new ApiSignInResponse(response.data);
		this.checkForErrors(response);

		if (response.data.isValid()) {
			if (request.AccountType != "ANONYMOUS") {
				if ((response.data as ApiSignInResponse).DisplayName) {
					localStorage.setItem("Username", <string>(response.data as ApiSignInResponse).DisplayName);
				}

				if ((response.data as ApiSignInResponse).UserAccountId) {
					localStorage.setItem("UserAccountId", <string>(response.data as ApiSignInResponse).UserAccountId);
				}
			}
		}

		return response.data;
	}

	public async getMediaServerProperties(): Promise<MediaServerProperties> {
		if (this.mediaServerProperties) {
			return this.mediaServerProperties;
		}

		const response = await this.apiServiceClient.executeRequest<MediaServerProperties>(
			"api/Account/GetMediaServerProperties",
			"GET",
			undefined,
			undefined,
			undefined,
			true
		);

		if (response.data?.TokenUrl && response.data?.ApiKey) {
			this.mediaServerProperties = new MediaServerProperties(response.data.TokenUrl, response.data.ApiKey, response.data.TenantId);
			return this.mediaServerProperties;
		}

		throw "Failed to retrieve media server properties.";
	}

	public async mediaServerSignIn(request: MediaServerSignInRequest): Promise<MediaServerSignInResponse> {
		const response = await this.apiServiceClient.executeRequest<MediaServerSignInResponse>(
			"api/Account/SignIn/MediaServer",
			"POST",
			request,
			{
				Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
			},
			undefined,
			true
		);

		response.data = new MediaServerSignInResponse(response.data);
		this.checkForErrors(response);
		return response.data;
	}

	private async apiCreatePermanentRoom() {
		const response = await this.apiServiceClient.executeRequest<ApiCreatePermanentRoomResponse>(
			"api/Account/CreatePermanentChannel",
			"POST",
			undefined,
			{
				Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
			},
			undefined,
			true
		);

		response.data = new ApiCreatePermanentRoomResponse(response.data);
		this.checkForErrors(response);
		return response.data;
	}

	public async createLobbyRoomIfNeeded(apiClient: ApiClient, lobbyKey: string, passCode: string): Promise<void> {
		const roomResponse = await apiClient.getRoomInfoByKey(lobbyKey);
		const lobbyRoom = roomResponse.value;
		if (!roomResponse.errors && !lobbyRoom) {
			await this.createRoom(apiClient, lobbyKey, passCode);
		}
	}

	private async mediaServerCreateRoom(identity: Identity, roomKey: string): Promise<MediaServerCreateRoomResponse> {
		const apiClient = new ApiClient({ identity: identity });
		const lobbyRoomKey = roomKey + "-LOBBY";
		const getRoomResponse = await apiClient.getRoomInfoByKey(roomKey);
		if (!getRoomResponse.errors && getRoomResponse.value) {
			const getLobbyResponse = await apiClient.getRoomInfoByKey(lobbyRoomKey);
			if (!getLobbyResponse.errors && !getLobbyResponse.value) {
				await this.createRoom(apiClient, lobbyRoomKey);
			}
			return new MediaServerCreateRoomResponse(getRoomResponse.value);
		}
		const response = await this.createRoom(apiClient, roomKey);
		await this.createRoom(apiClient, lobbyRoomKey);
		return response;
	}

	private async createRoom(apiClient: ApiClient, roomKey: string, passCode = "") {
		const addRoomResponse = await apiClient.createRoom({
			key: roomKey,
			filterModeDisplay: "REPLACE",
			filterModeUser: "FIRST",
			maxAudioUser: 3,
			maxVideoUser: 49,
			meetingType: "CONFERENCE",
			planType: "2TIER",
			roomType: "PERMANENT",
			roomLobbyType: "NONE",
			name: "",
			passcode: passCode,
		});

		const response = new MediaServerCreateRoomResponse(addRoomResponse.value);
		if (addRoomResponse.errors) {
			const message = `SDK request to create room failed: HTTP Status ${JSON.stringify(addRoomResponse.errors)}`;
			response.RequestSucceeded = false;
			response.Message = message as any;
			if (!response.isValid()) {
				console.warn(message);
			}
		}
		return response;
	}

	private checkForErrors<T extends AuthenticationResponse>(response: AxiosResponse<T>): void {
		if (response.status != 200) {
			const message = `POST to ${response.config.url} failed: HTTP Status ${response.status}`;
			response.data.Message = message as any;
			response.data.RequestSucceeded = false;
			if (!response.data.isValid()) {
				console.warn(message);
			}
		}
		if (response.data?.Error && !response.data.isValid()) {
			console.warn(`POST to ${response.config.url} returned error: ${JSON.stringify(response.data.Message)}`);
		}
	}

	public getFingerprintPromise() {
		return this.fingerprintPromise;
	}

	public async getFingerprint(): Promise<object> {
		if (!this.fingerprint) {
			const fingerprintResult = await this.fingerprintPromise;
			this.fingerprint = await fingerprintResult.get();
		}
		return this.fingerprint;
	}

	public getTimeZoneName(): string {
		// TODO: CHECK PLEASE
		return moment.tz(new Date().toString("yyyy-mm-dd"), moment.tz.guess())._z.name;
	}

	public async validateAuthentication(): Promise<AuthenticationValidationResponse> {
		const rawResponse = await this.apiServiceClient.executeRequest<AuthenticationValidationResponse>(
			"api/Account/ValidateAuthentication",
			"GET",
			undefined,
			{
				Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
			},
			undefined,
			true
		);

		const response = new AuthenticationValidationResponse(rawResponse.data, rawResponse.status === 200);

		if (response.UserAccountId) {
			localStorage.setItem("UserAccountId", response.UserAccountId);
		}

		if (response.Error) {
			this.clearAuthenticationCookies();
		}

		return response;
	}

	public clearAuthenticationCookies() {
		removeCookie("Authorization", {
			domain: import.meta.env.VITE_COOKIE_DOMAIN
		});
		removeCookie("RefreshToken", {
			domain: import.meta.env.VITE_COOKIE_DOMAIN
		});
	}

	public async grantGoogleContactAccess(authCodeResponse: any): Promise<boolean> {
		const rawResponse = await this.apiServiceClient.executeRequest<AuthenticationValidationResponse>(
			"api/auth/google",
			"POST",
			authCodeResponse,
			{
				Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
			},
			undefined,
			true
		);

		if (rawResponse.status == 200) {
			console.log("granted google access");
			return true;
		} else {
			console.error("failed to grant google access", rawResponse);
			return false;
		}
	}
}

export abstract class AuthenticationResponse {
	public Message?: { Status?: string; Reason: string };
	public Error?: boolean = false;
	public ErrorLocation?: string[];
	public ErrorSeverity?: bigint;
	public RequestSucceeded: boolean = true;

	protected constructor(data?: any) {
		this.Message = data?.Message;
		this.Error = data?.Error || false;
		this.ErrorLocation = data?.ErrorLocation;
		this.ErrorSeverity = data?.ErrorSeverity;
		if (data?.RequestSucceeded !== undefined) {
			this.RequestSucceeded = data.RequestSucceeded;
		}
	}

	public isValid(): boolean {
		return this.RequestSucceeded && !this.Error;
	}
}

export class SignUpRequest {
	public readonly AccountType?: string; // TODO: Make this an enum.
	public readonly DisplayName?: string;
	public readonly CompanyName?: string;
	public readonly UserName?: string;
	public readonly Password?: string;
	public readonly Platform?: string;
	public readonly SharedFingerprint?: object;
	public readonly Timezone?: string;
	public readonly Captcha?: string;
	public readonly CurrentChannelKey?: string;
	public readonly InviteToken?: string;
	public readonly ReferralCode?: string;

	constructor(
		AccountType?: string,
		DisplayName?: string,
		CompanyName?: string,
		UserName?: string,
		Password?: string,
		Platform?: string,
		SharedFingerprint?: object,
		Timezone?: string,
		Captcha?: string,
		CurrentChannelKey?: string,
		InviteToken?: string,
		ReferralCode?: string
	) {
		this.AccountType = AccountType;
		this.DisplayName = DisplayName;
		this.CompanyName = CompanyName;
		this.UserName = UserName;
		this.Password = Password;
		this.Platform = Platform;
		this.SharedFingerprint = SharedFingerprint;
		this.Timezone = Timezone;
		this.Captcha = Captcha;
		this.CurrentChannelKey = CurrentChannelKey;
		this.InviteToken = InviteToken;
		this.ReferralCode = ReferralCode;
	}
}

export class SignUpResponse extends AuthenticationResponse {
	public UserAccountId?: string;

	constructor(data?: any) {
		super(data);
		this.UserAccountId = data?.UserAccountId;
	}
}

export class ApiSignUpResponse extends AuthenticationResponse {
	public PlatformAccountId?: string;
	public UserAccountId?: string;
	public DeviceIdentifier?: string;
	public DeviceFingerprint?: string;

	constructor(data?: any) {
		super(data);
		this.PlatformAccountId = data?.PlatformAccountId;
		this.UserAccountId = data?.UserAccountId;
		this.DeviceIdentifier = data?.DeviceIdentifer;
		this.DeviceFingerprint = data?.DeviceFingerprint;
	}

	isValid() {
		return super.isValid() && this.UserAccountId != undefined;
	}
}

export class SignInRequest {
	public readonly AccountType?: string;
	public readonly UserName?: string;
	public readonly Password?: string;
	public readonly Platform?: string;
	public readonly SharedFingerprint?: object;
	public readonly OneTimeLogin?: boolean;
	public readonly Captcha?: string;
	public readonly CurrentChannelKey?: string;
	public readonly InviteToken?: string;
	public readonly Timezone?: string;

	constructor(
		AccountType?: string,
		UserName?: string,
		Password?: string,
		Platform?: string,
		SharedFingerprint?: object,
		OneTimeLogin?: boolean,
		Captcha?: string,
		CurrentChannelKey?: string,
		InviteToken?: string,
		Timezone?: string
	) {
		this.AccountType = AccountType;
		this.UserName = UserName;
		this.Password = Password;
		this.Platform = Platform;
		this.SharedFingerprint = SharedFingerprint;
		this.OneTimeLogin = OneTimeLogin;
		this.Captcha = Captcha;
		this.CurrentChannelKey = CurrentChannelKey;
		this.InviteToken = InviteToken;
		this.Timezone = Timezone;
	}
}

export class RefreshTokenRequest extends SignInRequest {
	public readonly RefreshToken?: string;
}

export class SignInResponse extends SignUpResponse {
	public CurrentChannelOwner: boolean = false;
}

export class ApiSignInRequest {
	public readonly UserName?: string;
	public readonly Password?: string;
	public readonly Platform?: string;
	public readonly SharedFingerprint?: object;
	public readonly AccountType?: string = "USER";
	public readonly OneTimeLogin?: boolean = false;
	public readonly Captcha?: string;
	public readonly CurrentChannelKey?: string;
	public readonly InviteToken?: string;
	public readonly Timezone?: string;

	constructor(request: SignUpRequest | SignInRequest) {
		this.AccountType = request.AccountType;
		this.UserName = request.UserName;
		this.Password = request.Password;
		this.Platform = request.Platform;
		this.SharedFingerprint = request.SharedFingerprint;
		this.Captcha = request.Captcha;
		this.CurrentChannelKey = request.CurrentChannelKey;
		this.OneTimeLogin = (request as any).OneTimeLogin;
		this.InviteToken = (request as any).InviteToken;
		this.Timezone = (request as any).Timezone;
	}
}

export class ApiSignInResponse extends AuthenticationResponse {
	public SignInResult?: object;
	public UserAccountId?: string;
	public UserName?: string;
	public DisplayName?: string;
	public DeviceIdentifier?: string;
	public DeviceFingerprint?: string;
	public CurrentChannelOwner: boolean;
	public SelectedTenantId?: string;

	constructor(data?: any) {
		super(data);
		this.SignInResult = data?.SignInResult;
		this.UserAccountId = data?.UserAccountId;
		this.UserName = data?.UserName;
		this.DisplayName = data?.DisplayName;
		this.DeviceIdentifier = data?.DeviceIdentifier;
		this.DeviceFingerprint = data?.DeviceFingerprint;
		this.CurrentChannelOwner = data?.CurrentChannelOwner;
		this.SelectedTenantId = data?.SelectedTenantId;
	}
}

export class MediaServerProperties {
	public readonly TokenUrl: string;
	public readonly ApiKey: string;
	public readonly TenantId: string;

	constructor(tokenUrl: string, apiKey: string, tenantId: string) {
		this.TokenUrl = tokenUrl;
		this.ApiKey = apiKey;
		this.TenantId = tenantId;
	}
}

export class MediaServerSignInRequest {
	public UserAccountId?: string;
	public readonly UserName?: string;
	public readonly DisplayName?: string;
	public readonly DeviceIdentifier?: string;
	public readonly DeviceFingerprint?: string;

	constructor(UserName?: string, DisplayName?: string, DeviceIdentifier?: string, DeviceFingerprint?: string) {
		this.UserName = UserName;
		this.DisplayName = DisplayName;
		this.DeviceIdentifier = DeviceIdentifier;
		this.DeviceFingerprint = DeviceFingerprint;
	}
}

export class MediaServerSignInResponse extends AuthenticationResponse {
	public readonly Token: string;

	constructor(data?: any) {
		super(data);
		this.Token = data?.Token;
	}

	isValid() {
		return this.Token != undefined;
	}
}

export class ApiCreatePermanentRoomResponse extends AuthenticationResponse {
	public readonly Channel?: ChannelDto;

	constructor(data?: any) {
		super(data);
		this.Channel = data?.Channel;
	}

	isValid() {
		return this.Channel?.ChannelKey != undefined;
	}
}

export class MediaServerCreateRoomResponse extends AuthenticationResponse {
	public readonly room?: Room;

	constructor(room?: Room) {
		super();
		this.room = room;
	}

	isValid() {
		return this.room != undefined;
	}
}

export class ChannelDto {
	public readonly ChannelKey?: string;
}

export class ResetPasswordResponse {
	public readonly Error: boolean;
	public readonly ErrorSeverity: number;
	public readonly Message: object;
	public readonly Success: boolean;

	constructor(data: any, success: boolean) {
		this.Error = data.error;
		this.ErrorSeverity = data.errorSeverity;
		this.Message = data.Message;
		this.Success = success;
	}
}

export class ResponseMessage {
	public readonly Status: boolean;
	public readonly IsFatal: boolean;
	public readonly Reason: string;
	public readonly Total: number;
	public readonly Guid: string;
	public readonly Result: any;

	constructor(data: any, success?: boolean) {
		this.Status = data.Status && success !== false;
		this.IsFatal = data.IsFatal;
		this.Reason = data.Reason;
		this.Total = data.Total;
		this.Guid = data.Guid;
		this.Result = data.Result;
	}
}

export class AuthenticationValidationResponse {
	public readonly Error: boolean;
	public readonly Message?: object;
	public readonly TokenUrl: string;
	public readonly ApiKey: string;
	public readonly Token?: string;
	public readonly UserAccountId?: string;
	public readonly DisplayName?: string;
	public readonly Email?: string;
	public readonly GoogleGrantedScopes?: string;
	public readonly FreeAccount: boolean = true;
	public readonly Features: object;
	public readonly GoogleAccount: boolean = false;
	public readonly BotSocketUrl?: string;
	public readonly BotEnabled?: boolean = false;

	constructor(data: any, success?: boolean) {
		this.Error = data.Error || success === false;
		this.Message = data.Message;
		this.TokenUrl = data.TokenUrl;
		this.ApiKey = data.ApiKey;
		this.Token = data.Token;
		this.UserAccountId = data.UserAccountId;
		this.DisplayName = data.DisplayName;
		this.Email = data.Email;
		this.GoogleGrantedScopes = data.GoogleGrantedScopes;
		this.FreeAccount = data.FreeAccount;
		this.Features = data.Features;
		this.GoogleAccount = data.GoogleAccount;
		this.BotSocketUrl = data.BotSocketUrl;
		this.BotEnabled = useHelpers().isLocal() ? true : data.BotEnabled;
	}
}