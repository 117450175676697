﻿import { Meeting } from "@liveswitch/sdk";
import { reactive } from "vue";
import type { LobbyConfiguration } from "./EventContracts";
import type Message from "@liveswitch/sdk/chat/Message";
import Swal from "sweetalert2/dist/sweetalert2.js";
import useErrorHelpers from "@/composables/useErrorHelper";
import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";
import useHelpers from "@/composables/useHelpers";
import { type ILobbyWebhookService, LobbyWebhookService } from "./LobbyWebhookService";
export default class LobbyHandler {
	private _config: LobbyConfiguration;
	private _lobbyConst = "-LOBBY";
	private _meeting: Meeting;
	private _appInsights: ApplicationInsights = null;
	private _webhookService: ILobbyWebhookService = new LobbyWebhookService();

	constructor() { }

	get isHost(): boolean {
		return this._config?.isHost;
	}

	get channelKey(): string {
		return this._config?.channelKey;
	}

	get channelPasscode(): string {
		return this._config?.channelPasscode;
	}

	get lobbyChannelKey(): string {
		return this._config?.channelKey + this._lobbyConst;
	}

	get meeting(): Meeting {
		return this._meeting;
	}

	set appInsights(appInsights: ApplicationInsights) {
		this._appInsights = appInsights;
	}

	get lobbyUserCount(): number {
		return this._meeting?.attendees?.filter((a) => a.id != this._meeting?.localAttendee?.id).length;
	}

	public async joinLobby(config: LobbyConfiguration) {
		this._config = config;

		// join the lobby

		try {
			this._meeting = reactive(new Meeting({ identity: config.identity })) as Meeting;
			await this._meeting.setLocalDisplayMedia(null);
			await this._meeting.setLocalUserMedia(null);

			this._meeting.stateChanged.bind(async (e) => {
				console.log(`Lobby meeting state: ${e.meeting.state}`);
			});

			await this._meeting.join({
				displayName: this._config.userName,
				roomKey: this.lobbyChannelKey,
				passcode: this._config.channelPasscode,
				persistentAttendee: true,
				attendeePageSize: 1000,
				onProgress: async (e) => {
					if (this.isHost && e.progress == 1) {
						this._config.admittedCallback();
					}
				},
				useAttendeeList: true,
				useCamera: false,
				useChat: true,
				useMicrophone: false,
				useScreenShare: false,
				useRemoteMedia: false,
			});

			// only bind these for hosts
			if (this.isHost) {
				this._meeting.attendees.added.bind(this.handleAttendeeAdded.bind(this));
				this._meeting.attendees.removed.bind(async (e) => {
					if (!this._config.meetingHandler.waitingRoomOpen) {
						return;
					}
					if (this._meeting.attendees?.filter((a) => a.id != this._meeting.localAttendee?.id).length === 0) {
						this._config.meetingHandler.waitingRoomOpen = false;
					}
				});
			} else {
				this._meeting.attendees.added.bind(this.handleOtherAttendeeAdded.bind(this));
				if (this._config.meetingHandler.canSendWebhook) {
					const isHostInLobby = this._meeting.attendees.findIndex(x => x.role == "HOST") != -1;
					if (!isHostInLobby) {
						window.setTimeout(async () => {
							const isHostInLobbyNow = this._meeting.attendees.findIndex(x => x.role == "HOST") != -1;
							console.log(`host is ${isHostInLobbyNow} in lobby`);
							if (!isHostInLobbyNow) {
								// now we send a webhook to notify the host they have someone in their lobby
								const response = await this._webhookService.sendAsync({
									ChannelKey: this.channelKey,
									AtteneeName: this._meeting.localAttendee.displayName
								});
								if (!response.error) {
									// webhook sent
									console.log("webhook sent");
								} else {
									console.error("An error occurred while sending the lobby webhook");
								}
							}
						}, this._config.webhookTimer); // make configurable
					}
				}
			}
			this._meeting.chat?.defaultChannel.messages.added.bind(async (e) => {
				const message = e.element as Message;
				try {
					const parsed = JSON.parse(message.text);
					if (parsed.id == this._meeting.localAttendee.id) {
						if (parsed.admitted) {
							this._config.admittedCallback();
						} else {
							this._config.deniedCallback();
						}
						await this._meeting.leave();
					}
				} catch (error) {
					console.error(`There was an error parsing accept/deny message from host. ${error.message}`);
				}
			});
		} catch (error) {
			const message = "An unexpected error occurred. Unable to join the waiting room.";
			if (useErrorHelpers().isWrongPasscodeError(error)) {
				Swal.fire({
					title: "Error",
					text: "The passcode is incorrect.",
					confirmButtonText: "Close",
				});

				this._config.errorCallback();
				return;
			}
			this._appInsights.trackException(
				{
					exception: error,
					id: "JoinLobbyFailed",
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties("JoinLobbyFailed", error.Message)
			);
			console.error(`Unable to Join Lobby: Message=${error.Message}`, error);

			Swal.fire({
				title: "Error",
				text: message,
				confirmButtonText: "Close",
			});
			this._config.errorCallback();
		}
	}

	public async leave(): Promise<void> {
		await this._meeting?.leave();
	}

	private async handleAttendeeAdded(e) {
		this.playChime();
		if (this._meeting.attendees.filter((a) => a.id != this._meeting.localAttendee.id).length) {
			await this.attendeeWaitingToast();
		}
	}

	private async handleOtherAttendeeAdded(e: any) {
		if (e.element.role == "HOST") {
			// cancel timer
			console.log("host has entered the lobby - stop timer");
		}
		console.log(e.element.role);
	}

	private playChime() {
		// if someone joins the lobby and you're the only one in the meeting
		if (
			this._meeting.attendees?.filter((a) => a.id != this._meeting.localAttendee?.id).length === 1 &&
			this._config.meetingHandler.attendees?.filter((a) => a.id != this._config.meetingHandler.localAttendee?.id).length === 0
		) {
			this._config.meetingHandler.playChime(true);
		}
	}

	private async attendeeWaitingToast() {
		if (this._config.meetingHandler.waitingRoomOpen) {
			return;
		}

		// if someone joins display the modal
		Swal.fire({
			position: "top-end",
			text: "You have guests in your waiting room.",
			confirmButtonText: "Review",
			showConfirmButton: true,
			toast: true,
			customClass: {
				container: this._config.meetingHandler.sidePanelOpen ? "side-open" : "side-closed",
			},
		}).then(() => {
			// open the list
			this._config.meetingHandler.waitingRoomOpen = true;
			this._config.meetingHandler.showingWaitingRoomNotification = false;
		});
		this._config.meetingHandler.showingWaitingRoomNotification = true;
	}
}
